<template>
  <div :id="id">
    <b-table
      :id="id"
      class="tabela-valorem"
      bordered
      :current-page="paginaAtual"
      :per-page="semPaginacao || backendPaginado ? null : quantidadePorPagina"
      responsive
      :items="dados"
      :fields="colunas"
      :foot-clone="rodape"
    >
      <template v-for="slot in slots" :slot="slot" slot-scope="dados">
        <div :key="slot">
          <slot v-bind="dados" :name="slot"></slot>
        </div>
      </template>
      <template #cell(seletor)="dados">
        <input-checkbox
          :id="`input-selecionar-${id}-${dados.index}`"
          :disabled="!verificarPermissaoSelecao(dados.item)"
          v-model="dados.item.selecionado"
          @onChange="
            () => {
              selecionarIndividual(dados.item);
            }
          "
        />
      </template>
      <template #head(seletor)>
        <input-checkbox
          :id="`input-selecionar-todos-${id}`"
          v-if="!semSelecionarTodos"
          :value="todosSelecionado"
          @onChange="
            () => {
              selecionarTodos();
            }
          "
        />
      </template>
    </b-table>
    <div class="d-flex align-items-center justify-content-end" v-if="!semPaginacao">
      <div class="d-flex align-items-center">
        <span>Por pagina</span>
        <input-select
          :id="`input-por-pagina-${id}`"
          no-label
          :options="opcoesPaginas"
          v-model="quantidadePorPagina"
          class="paginas-tabela-valorem ml-2"
        ></input-select>
      </div>
      <b-pagination
        :id="`input-paginas-${id}`"
        pills
        class="paginacao-tabela-valorem ml-4 mt-0"
        v-model="paginaAtual"
        :total-rows="backendPaginado ? quantidadeItens : totalLinhas"
        :per-page="quantidadePorPagina"
        :aria-controls="backendPaginado ? null : id"
        hide-goto-end-buttons
        page-class="pagina"
        next-class="botoes"
        prev-class="botoes"
        @change="mudarPagina"
      >
        <template #prev-text>
          <feather type="chevron-left" size="20" />
        </template>
        <template #next-text>
          <feather type="chevron-right" size="20" />
        </template>
      </b-pagination>
    </div>
  </div>
</template>
<script>
import { InputCheckbox, InputSelect } from "../inputs";
import cloneDeep from "lodash.clonedeep";
export default {
  components: {
    InputCheckbox,
    InputSelect,
  },
  props: {
    id: {
      type: String,
      default: "tabela",
    },
    value: {
      default: [],
    },
    colunas: {
      default: [],
    },
    semPaginacao: {
      type: Boolean,
      default: false,
    },
    rodape: {
      type: Boolean,
      default: false,
    },
    backendPaginado: {
      type: Boolean,
      default: false,
    },
    porPagina: {
      type: Number,
      default: 10,
    },
    quantidadeItens: {
      type: Number,
      default: 0,
    },
    semSelecionarTodos: {
      type: Boolean,
      default: false,
    },
    metodoValidacaoSelecionar: {
      type: Function,
      default: () => {
        return true;
      },
    },
    selecionarSomenteUm: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      dados: [],
      todosSelecionado: false,
      opcoesPaginas: [10, 25, 50, 100],
      quantidadePorPagina: null,
      paginaAtual: 1,
    };
  },
  methods: {
    selecionarIndividual(item) {
      if (this.selecionarSomenteUm) {
        this.dados = this.dados.map((el) => {
          el.selecionado = false;
          return el;
        });

        item.selecionado = true;
      }
      let todos = this.dados.every((el) => {
        return el.selecionado;
      });

      this.todosSelecionado = todos;

      this.$emit("input", this.dados);
    },
    selecionarTodos() {
      this.todosSelecionado = !this.todosSelecionado;
      this.dados = this.dados.map((el) => {
        el.selecionado = this.todosSelecionado;
        return el;
      });
      this.$emit("input", this.dados);
    },
    mudarPagina(pagina) {
      this.$emit("paginar", pagina - 1, this.quantidadePorPagina);
    },
    limpar() {
      this.paginaAtual = 1;
      this.dados = [];
    },
    verificarPermissaoSelecao(item) {
      return this.metodoValidacaoSelecionar(item);
    },
    limparSelecao() {
      this.todosSelecionado = false;
      this.dados = this.dados.map((el) => {
        el.selecionado = this.todosSelecionado;
        return el;
      });
      this.$emit("input", this.dados);
    },
    atualizarValor: function (valor) {
      this.dados = cloneDeep(valor).map((el) => {
        if (el.selecionado === undefined) el.selecionado = false;

        return el;
      });
    },
  },
  watch: {
    value: {
      handler() {
        this.dados = cloneDeep(this.value).map((el) => {
          if (el.selecionado === undefined) el.selecionado = false;

          return el;
        });
      },
      immediate: true,
      deep: true,
    },
    porPagina: {
      handler() {
        this.quantidadePorPagina = this.porPagina;
      },
      immediate: true,
    },
    quantidadePorPagina() {
      this.$emit("paginar", 0, this.quantidadePorPagina);
    },
  },
  computed: {
    slots: function () {
      let slots = [];
      slots.push(...Object.keys(this.$slots));
      slots.push(...Object.keys(this.$scopedSlots));
      return slots;
    },
    totalLinhas: function () {
      return this.dados.length;
    },
  },
};
</script>
<style lang="scss"></style>
