
import Usuarios from "./views/usuarios/pt-br";
import Clientes from "./views/clientes/pt-br"
import Produtos from "./views/produtos/pt-br"
import Planos from "./views/planos/pt-br"
import LinkPagamento from "./views/link_pagamento/pt-br";
import ExtratoTransacao from  "./views/extrato_transacao/pt-br";
import Dashboard from  "./views/dashboard/pt-br";
import Estabelecimentos from  "./views/estabelecimentos/pt-br";
import GrupoEconomico from  "./views/grupo_economico/pt-br";
import Assinaturas from "./views/assinaturas/pt-br";
import Enum from "./enums/pt-br";
import Solicitacoes from "./views/solicitacoes/pt-br"

export const br = {
  ENUM : Enum,
  USUARIOS: Usuarios,
  TITULO: 'Portal Base Lyncas',
  GERAL: {
    OPCAO_TODOS : 'Todos',
    SALVAR: 'Salvar',
    SUCCESSO: 'Sucesso',
    ERRO: 'Erro',
    EMAIL: 'E-mail',
    LOGIN: 'Login',
    SENHA: 'Senha',
    ACOES: 'Ações',
    EDITAR: 'Editar',
    VER: 'Ver',
    EXCLUIR: 'Apagar',
    HOMOLOG: 'Homologação',
    DUPLICAR: 'Duplicar',
    ATENCAO: 'ATENÇÃO',
    EDITAR_TODOS: 'Editar Todos',
    SIM: 'Sim',
    NAO: 'Não',
    MENSAGEM_CAMPO_VAZIO:
      'Por favor preencha todos os campos que estão em vermelho!',
    CONFIRMAR: 'Confirmar',
    MENSAGEM_CONFIRMAR_ACAO: 'Tem certeza que deseja executar esta ação?',
    UM_ARQUIVO: 'Envie apenas um arquivo.',
    PORTUGUES: 'Português',
    BUSCAR_INFO_DOCUMENTO: 'Buscar informações com este Documento',
    INGLES: 'English',
    SAIR: 'Sair',
    CADASTRAR: 'Cadastrar',
    ADICIONAR: 'Adicionar',
    CONFIRMACAO_SENHA: 'Confirmação de Senha',
    OK: 'Ok',
    SENHA_ATUAL: 'Senha Atual',
    TROCAR_SENHA: 'Trocar Senha',
    NOVA_SENHA: 'Nova Senha',
    CONFIRMAR_NOVA_SENHA: 'Confirmar Nova Senha',
    SENHA_ALTERADA: 'Senha Alterada com Sucesso',
    ESQUECEU_SENHA: 'Esqueceu sua senha?',
    EMAIL_ENVIADO: 'E-mail enviado com sucesso!',
    REDEFINIR_SENHA: 'Redefinir senha',
    VOLTAR_LOGIN: 'Voltar ao Login',
    USUARIO: 'Usuário',
    ENTRAR: 'Entrar',
    SAIBA_MAIS: 'Saiba mais',
    PAGINACAO: 'Mostrar todos',
    POR_PAGINA: 'Mostrar',
    PAGINA_PRINCIPAL_TITULO: 'Receba à vista pelo que você vendeu a prazo!',
    PAGINA_PRINCIPAL_MSG:
      'A antecipação de recebíveis é uma escolha inteligente e\neficaz porque você usa um dinheiro que já pertence à sua empresa',
    MINHA_CONTA: 'Minha Conta',
    PERFIL: 'Perfil',
    FILTRAR: 'Filtrar',
    LIMPAR: 'Limpar',
    TABELA: {
      PRIMEIRO: 'Primeiro',
      ULTIMO: 'Ultimo',
      ANTERIOR: 'Anterior',
      PROXIMO: 'Proximo'
    },
    ATIVAR: 'Ativar',
    INATIVAR: 'Inativar',
    BOTAO_INATIVAR : "Inativar",
    BOTAO_ATIVAR : "Ativar",
    CONTINUAR: 'Continuar',
    CANCELAR: 'Cancelar',
    DT_CADASTRO: 'Data de Cadastro',
    CODIGO: 'Código',
    STATUS: 'Status',
    VISUALIZAR : "Visualizar"
  },
  MENU: {
    DASHBOARD: 'Dashboard',
    USUARIOS: 'Usuários',    
    RELATORIO: 'Relatório',
    PERMISSOES: 'Permissões',    
    CONFIGURACOES: 'Configurações',
    AUDITORIA: 'Auditoria',
    FAQ: 'Perguntas Frequentes',
    PERFIL: 'Perfil',   
    GERENCIAMENTO: 'Gerenciamento',
    ANTECIPACAO: 'Antecipação',
    ASSINATURAS: 'Assinaturas',
    CLIENTES: 'Clientes',
    ESTABELECIMENTOS: 'Estabelecimentos',
    PLANOS: 'Planos',
    PRODUTOS: 'Produtos',
    MEUS_ESTABELECIMENTOS : 'Seus estabelecimentos comerciais:',
    FILTRO : 'Buscar pelo nome ou CNPJ',
    GRUPO_ECONOMICO : 'Grupo econômico'
  },
  
  AUDITORIA: {
    DATA_ALTERACAO: 'Data de Alteração',
    DATA_CRIACAO: 'Data de Criação',
    DataPrimeiroLogin: 'Data Primeiro Login',
    DataUltimoLogin: 'Data Último Login',
    TIPO_AUDITORIA: 'Tipo da Auditoria',
    ID_ENTIDADE: 'Identificador da Entidade',
    NOME_USUARIO: 'Nome do Usuário',
    ENTIDADE: 'Entidade',
    DETALHES: 'Detalhes',
    DE: 'De',
    ATE: 'Até',
    TIPO: 'Tipo',
    VALOR: 'Valor',
    CAMPO: 'Campo',
    SELECIONE: 'Selecione...',
    ADICIONADO: 'Adicionado',
    MODIFICADO: 'Modificado',
    DELETADO: 'Deletado',
    Id: 'Identificador',
    ClaimType: 'Tipo',
    ClaimValue: 'Valor',
    RoleId: 'ID do Perfil',
    VALOR_ANTIGO : "Valor antigo",
    VALOR_NOVO : "Valor novo",
    TITULO_LISTA : "Auditoria",
    FILTRO : "Filtro"
  },
  PERMISSOES: {
    NOME: 'Nome',
    ACESSOS: 'Acessos',
    CONFIRMAR_EXCLUIR: 'Tem certeza que deseja excluir o perfil {nome}?',
    ACESSO: 'Acesso',
    PERMISSAO: 'Permissões',
    PERFIL: 'Perfil',
    VISUALIZAR: 'Visualizar',
    MENSAGEM_CADASTRADO: 'Perfil cadastrado com sucesso!',
    CRIAR: 'Criar',
    EDITAR: 'Editar',
    EXCLUIR: 'Excluir',
    ACESSO_AUTENTICACAO: 'Autenticação',
    ACESSO_USUARIO: 'Usuário',
    ACESSO_PERFIL: 'Perfil',
    ACESSO_AUDITORIA: 'Auditoria',
    ACESSO_EMAIL: 'E-mail',
    ACESSO_CHEQUES: 'Cheques',
    ACESSO_CNAB: 'CNAB',
    ACESSO_OPERACAO: 'Operações',
    ACESSO_TITULOS: 'Títulos',
    ACESSO_FAVORECIDOS: 'Favorecidos',
    ACESSO_OPERACOESQPROF: 'Operações QPROF',
    ACESSO_CONFIGURACOES: 'Configurações',
    ACESSO_FAVORECIDO: 'Favorecido',
    ACESSO_PROGRAMACAOPAGAMENTO: 'Programacao de Pagamento',
    ACESSO_CONTABANCARIA: 'Conta Bancaria',
    ACESSO_RELATORIO: 'Relatório',
    ACESSO_FILTROTODOSCEDENTESPROGRAMACAO: 'Filtro todos cedentes programação'
  },
  CAMPOS_VALIDACAO: {
    INVALIDO: 'O campo {{name}} é inválido',
    REQUERIDO: 'Por favor informe o campo {name}',
    TAMANHO: 'O campo {{name}} precisa possuir {{length}} caracteres',
    TAMANHO_MINIMO:
      'O campo {{name}} precisa possuir no mínimo {{min}} caracteres',
    TERMOS_CONDICOES: 'É necessário aceitar os termos e condições',
    NAO_ENCONTRADO: 'O usuário {{name}} não foi encontrado',
    LOGIN_INVALIDO: 'Login inválido',
    CAMPO_REQUERIDO: 'Campo requerido',
    COMPRIMENTO_MINIMO: 'Comprimento mínimo do campo de {{length}} caracteres',
    COMPRIMENTO_MAXIMO: 'Comprimento máximo do campo de {{length}} caracteres',
    VALOR_MINIMO: 'Valor mínimo do campo de é {{min}}',
    VALOR_MAXIMO: 'Valor máximo do campo de é {{max}}',
    CAMPO_INVALIDO: 'Campo não é válido',
    SENHA_E_CONFIRMACAO: 'As senhas digitadas não são iguais',
    DATA_MENOR: 'A data não pode ser inferior à data de hoje',
    CPF_INVALIDO : 'CPF invalido!',
    CPF_CNPJ_INVALIDO : 'CPF/CNPJ invalido!'
  },

  PLACEHOLDER: {
    EMAIL: 'Digite seu e-mail',
    EMAIL_USER: 'Digite seu e-mail ou usuário',
    SENHA: 'Digite sua senha',
    NOME_USUARIO: 'Ex: João da Silva',
    VALOR: 'Ex: Operação',
    ID_ENTIDADE: 'Ex: XYZ',
    SELECIONE: 'Selecione',
    CPF_CNPJ: 'Digite seu CPF ou CNPJ',
    CPF: 'Digite seu CPF',
    TELEFONE_CELULAR: 'Digite seu telefone',
    FILTRAR_USUARIO: 'Pesquise pelo nome, email, CPF, empresa ou telefone',
    FILTRAR_ESTABELECIMENTO: 'Pesquise pelo código, razão social ou CNPJ',
    FILTRAR_CONTATO: 'Pesquise pelo nome, email ou telefone',
    FILTRAR_CONTA: 'Pesquise pelo favorecido, CNPJ e número da conta',
    FILTRAR_ASSINATURA: 'Pesquise pelo nome do comprador ou nome do plano',
    VINCULAR_ESTABELECIMENTO:
      'Selecione as empresas que deseja copiar esse contato'
  },
  
  CLIENTES : Clientes,
  PRODUTOS : Produtos,
  LINK_PAGAMENTO : LinkPagamento,
  EXTRATO_TRANSACAO : ExtratoTransacao,
  DASHBOARD : Dashboard,
  PLANOS : Planos,
  ESTABELECIMENTOS : Estabelecimentos,
  GRUPO_ECONOMICO : GrupoEconomico,  
  ASSINATURAS: Assinaturas,
  SOLICITACOES : Solicitacoes,

};
