<template>
    <div>
        <div class="btn-left d-flex align-items-center">
            <b-button @click="anterior" v-if="temScroll" pill variant="flat-valorem-ciano">
                <i class="fas fa-angle-left"></i>
            </b-button>
        </div>
        <div class="btn-right d-flex align-items-center">
            <b-button @click="proximo" v-if="temScroll" pill variant="flat-valorem-ciano">
                <i class="fas fa-angle-right"></i>
            </b-button>
        </div>
        <div @scroll.passive="handleScroll" :class="`body-scroll ${direcao} ${tipo}-scroll-snapping`" dir="ltr" id="body">
            <slot/>
        </div>
    </div>
</template>
<script>

export default {
    props : {
        tipo : {
            default : "obrigatorio"
        },
        direcao : {
            default : "x"
        }
    },
    data(){
        return {
            tamanhoDiv : 0,
            tamanhoScroll : 0,
            posicaoScroll : 0
        }
    },  
    mounted(){
        this.tamanhoDiv =  document.getElementById("body").offsetWidth
        this.tamanhoScroll =  document.getElementById("body").scrollWidth
    },
    computed : {
        temScroll(){
            return this.tamanhoDiv < this.tamanhoScroll
        }
    },
    methods : {
        handleScroll : function(e){
            this.posicaoScroll = e.target.scrollLeft
        },
        
        proximo : function(){
            let carouselPositions = [];
            
            document.querySelectorAll('.body-scroll .div-scroll').forEach(function(div) {
                let pontoScroll = div.offsetLeft + div.offsetWidth
                carouselPositions.push({ inicial : div.offsetLeft, final :  pontoScroll});
            })

            let offsetInicial = carouselPositions[0].inicial

            let proximaPosicao = carouselPositions.find(el => el.inicial > (this.posicaoScroll + offsetInicial + 1))
            if(proximaPosicao)
                document.getElementById("body").scrollTo(proximaPosicao.inicial , 0);
        },

        anterior : function(){
            let carouselPositions = [];
            
            document.querySelectorAll('.body-scroll .div-scroll').forEach(function(div) {
                let pontoScroll = div.offsetLeft + div.offsetWidth
                carouselPositions.push({ inicial : div.offsetLeft, final :  pontoScroll});
            })

            let offsetInicial = carouselPositions[0].inicial

            let proximaPosicao = carouselPositions.filter(el => el.inicial < (this.posicaoScroll + offsetInicial - 1))
            if(proximaPosicao.length > 0)
                document.getElementById("body").scrollTo(proximaPosicao[proximaPosicao.length - 1].inicial , 0);
        }
    }
}
</script>
<style scoped lang="scss">
    .body-scroll {
        display: flex;
        overflow: auto;
        flex: none;
        width: 100%;
        flex-flow: row nowrap;
    }

    .x.proximo-scroll-snapping {
        scroll-snap-type: x proximity;
    }
    .y.proximo-scroll-snapping {
        scroll-snap-type: y proximity;
    }
    .x.obrigatorio-scroll-snapping {
        scroll-snap-type: x mandatory;
    }
    .y.obrigatorio-scroll-snapping {
        scroll-snap-type: y mandatory;
    }

    .body-scroll > div {
        text-align: center;
        scroll-snap-align: center;
        flex: none;
    }    

    ::-webkit-scrollbar-track {
        background-color: #FFF
    }
    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }
    ::-webkit-scrollbar-thumb {
        background: #9b9b9b;
        border-radius: 10px;
        height: 2px;
    }

    .btn-left{
        position: absolute;
        left: 5px;
        top: calc((100% - 33px) / 2);
    }
    .btn-right{
        position: absolute;
        right: 5px;
        top: calc((100% - 33px) / 2);
    }
</style>