import ApiService from "@/common/api/api.service";

const AutenticacaoService = {
  logar(form) {
    return ApiService.post(
      `autenticacao/autenticar`,
      {
        ...form,
      },
      { noToast: true }
    );
  },
  validarToken() {
    return ApiService.get(`autenticacao/token-valido`);
  },
  async invalidarToken() {
    let ret = await ApiService.put(`autenticacao/invalidar-token`, {}, { noToast: true });
    return ret
  },
};

export default AutenticacaoService;
