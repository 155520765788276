import verificaAutenticacao from "@/common/middleware/verificaAutenticacao.js";
import verificarPermissao from "../common/middleware/verificarPermissao";

export default [
    {
        name: "link-pagamento",
        path: "/link_pagamento",
        meta: {
            middleware: verificaAutenticacao,
        },
        beforeEnter: (to, from, next) => {
            verificarPermissao(next, "LinkPagamento");
        },
        component: () => import("@/views/link_pagamento"),
    },
    {
        name: "link-pagamento-novo",
        path: "/link_pagamento/novo",
        meta: {
            middleware: verificaAutenticacao,
        },
        beforeEnter: (to, from, next) => {
            verificarPermissao(next, "LinkPagamento");
        },
        component: () => import("@/views/link_pagamento/formulario"),
    },
    {
        name: "link-pagamento-editar",
        path: "/link_pagamento/:id?",
        meta: {
            middleware: verificaAutenticacao,
        },
        beforeEnter: (to, from, next) => {
            verificarPermissao(next, "LinkPagamento");
        },
        props : true,
        component: () => import("@/views/link_pagamento/formulario"),
    },
]