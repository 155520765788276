export default {
    TITULO_LISTA: "Extrato transações",
    TITULO_FORMULARIO: "Detalhes",
    VISUALIZAR: "Visualizar",
    APROVAR: "Aprovar",
    REJEITAR: "Rejeitar",
    PENDENTES: "Pendentes",
    APROVADAS: "Aprovadas",
    NEGADAS: "Negadas",
    FILTRO: "Pesquise pelo cliente, NSU e código",
    TABELA: {
        CODIGO_LINK: " Código link",
        CODIGO_ASSINATURA: "Código assinatura",
        TIPO: "Tipo",
        PARCELA: "Parcela",
        ESTABELECIMENTO: "Estabelecimento",
        NOME_CLIENTE: "Nome do cliente",
        NUMERO_CARTAO: "Nº Cartão",
        NSU: "NSU",
        COD_AUTORIZACAO: "Cód. autorização",
        VALOR_TOTAL: "Valor total",
        DATA_COBRANCA: "Data da cobrança",
        DATA_PAGAMENTO: "Data do pagamento",
        SITUACAO: "Situação",
        MENSAGEM: "Mensagem",
        DESCRICAO: "Descrição",
        USUARIO: "Criador",
    },

    VALOR: "Valor total da cobrança",
    LINK: "Link",
    TIPO: "Tipo",
    CLIENTE: "Cliente",
    ESTABELECIMENTO: "Estabelecimento",

    MEIO: "Meio de pagamento",
    VALOR_TRANSACAO: "Valor da transação",
    DATA: "Data de criação",
    PARCELAS: "Parcelas",
    ADQUIRINTE: "Nome da adquirente",
    PORTADOR: "Nome do portador",
    MENSAGEM_ERRO: "Mensagem de erro",
    COD_AUTORIZACAO: "Código de autorização",
    COD_EMISSOR: "Código do emissor",
    BANDEIRA: "Bandeira do cartão",
    CARTAO: "Número do cartão",
    NSU: "NSU",

    CONSULTAR_ASSINATURA: "Consultar assinatura",

    SOLICITAR_CANCELAMENTO: "Solicitar cancelamento da cobrança",
    CANCELAR: "Solicitar cancelamento",
    HISTORICO: "Histórico do cancelamento",
    AGUARDANDO: "Pendente",
    APROVADO: "Aprovado",
    REJEITADO: "Rejeitado",
    PENDENTES_CANCELAMENTO: "Pendentes de cancelamento",

    DATA_SOLICITACAO: "Data da solicitação",
    VALOR_CANCELAMENTO: "Valor do cancelamento",
    MOTIVO_CANCELAMENTO: "Motivo do cancelamento",
    PARECER: "Resposta Valorem",
    DATA_PARECER: "Data da resposta",

    DATA_VENDA: "Data da venda",
    VALOR_VENDA: "Valor da venda",
    VALOR_DISPONIVEL: "Valor disponivel para cancelamento",
    OPCAO_CANCELAMENTO: "Opção de cancelamento",
    VALOR_COBRADO: "Valor a ser cancelado",

    TITULO_MODAL: "Solicitação de cancelamento de cobrança",
    CONFIRMAR_CANCELAMENTO: "Deseja realizar o pedido de cancelamento da cobrança?",
    TITULO_CONFIRMAR: "Confirmação do pedido",

}