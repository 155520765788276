<template>
  <div class="dropdown" :id="id" v-if="options">
    <b-form-group :state="state" :label="label" :label-sr-only="noLabel">
      <template #label>
        <slot name="label">{{ label }}</slot>
      </template>
      <b-input-group>
        <slot name="prepend"></slot>
        <b-form-input
          class="dropdown-field"
          :state="state"
          :readonly="disabled"
          :placeholder="placeholder"
          autocomplete="off"
          @focus="showOptions()"
          @blur="exit()"
          @keyup="keyMonitor"
          v-model="searchFilter"
        />
        <template #append>
          <b-input-group-text>
            <feather type="search" size="14" />
          </b-input-group-text>
          <slot name="append"></slot>
        </template>
      </b-input-group>

      <b-form-invalid-feedback :state="state" v-if="!hideFeedback">{{
        feedback
      }}</b-form-invalid-feedback>
    </b-form-group>
    <div class="dropdown-content" v-show="optionsShown">
      <div
        class="dropdown-item"
        @mousedown="selectOption(option)"
        v-for="(option, index) in filteredOptions"
        :key="index"
      >
        {{ option.text || "-" }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InputAutocomplete",
  props: {
    id: { type: String },
    hideFeedback: { type: Boolean, default: false },
    noLabel: { type: Boolean, default: false },
    label: { type: String },
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
    placeholder: {
      type: String,
      required: false,
      default: "Selecione uma opção",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxItem: {
      type: Number,
      required: false,
      default: 0,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      default: "",
    },
  },
  data() {
    return {
      selected: {},
      optionsShown: false,
      searchFilter: "",
      validForm: false,
      hasError: false,
      errorMessage: "",
    };
  },
  created() {
    this.$emit("selected", this.selected);
  },
  computed: {
    filteredOptions() {
      const filtered = [];
      const regOption = new RegExp(this.searchFilter, "ig");
      for (const option of this.options) {
        if (this.searchFilter.length < 1 || option.text.match(regOption)) {
          if (filtered.length < this.maxItem || this.maxItem == 0) filtered.push(option);
        }
      }
      return filtered;
    },
    requiredValid() {
      return !(
        this.required &&
        (this.selected.value == undefined ||
          this.selected == null ||
          this.selected.value == "")
      );
    },

    state() {
      if (!this.validForm) return null;

      return this.validacoes();
    },
    feedback() {
      if (!this.requiredValid)
        return (
          this.requiredMessage ||
          this.$t("CAMPOS_VALIDACAO.REQUERIDO").formatUnicorn({
            name: this.label,
          })
        );

      return "";
    },
  },
  methods: {
    clear() {
      this.selected = {};
      this.searchFilter = "";
      this.validForm = false;
      this.$emit("selected", this.selected);
    },
    valid(validacaoEscondida = false) {
      if (validacaoEscondida) {
        return this.validacoes();
      }
      this.validForm = true;
      return this.state;
    },
    validacoes: function () {
      if (!this.requiredValid) return false;
      if (this.hasError) return false;
      return true;
    },

    selectOption(option) {
      this.selected = option;
      this.optionsShown = false;
      this.searchFilter = this.selected.text;
      this.$emit("selected", this.selected);
      this.$emit("input", this.selected.value);
    },
    showOptions() {
      if (!this.disabled) {
        this.searchFilter = "";
        this.optionsShown = true;
      }
    },
    exit() {
      if (!this.selected.value) {
        this.selected = {};
        this.searchFilter = "";
      } else {
        this.searchFilter = this.selected.text;
      }
      this.$emit("selected", this.selected);
      this.optionsShown = false;
    },
    // Selecting when pressing Enter
    keyMonitor: function (event) {
      this.optionsShown = true;
      if (event.key === "Enter" && this.filteredOptions[0])
        this.selectOption(this.filteredOptions[0]);
    },
    valueAtualizado(value) {
      if (!value) {
        this.selected = {};
        this.searchFilter = "";
      }

      let option = this.options.find((option) => option.value == value);
      if (option) {
        this.selected = option;
        this.searchFilter = this.selected.text;
      }
    },
  },
  watch: {
    searchFilter() {
      this.$emit("filter", this.searchFilter);
    },
    value: {
      handler: function (value) {
        this.valueAtualizado(value);
      },
      deep: true,
      immediate: true,
    },
    options: {
      handler: function () {
        this.valueAtualizado(this.value);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  .dropdown-content {
    position: absolute;
    background-color: #fff;
    width: calc(100% - 20px);
    max-height: 248px;
    border: 1px solid #e7ecf5;
    box-shadow: 0px -8px 34px 0px rgba(0, 0, 0, 0.05);
    overflow: auto;
    z-index: 1;
    margin-top: -15px;
    .dropdown-item {
      color: black;
      font-size: 13px;
      line-height: 1em;
      padding: 8px;
      text-decoration: none;
      display: block;
      cursor: pointer;
      &:hover {
        background-color: #e7ecf5;
      }
    }
  }
  .dropdown:hover .dropdowncontent {
    display: block;
  }
}
</style>
