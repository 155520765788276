import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import Jwt from "@/common/utils/jwt";
import { API_URL } from "@/config/config";

axios.interceptors.request.use((config) => {
  const indexInterrogacao = config.url.indexOf("?");
  const indexUltimaBarra = config.url.lastIndexOf("/");
  if (indexInterrogacao < indexUltimaBarra && indexInterrogacao >= 0) {
    config.url = config.url.substring(0, config.url.length - 1);
  }
  return config;
});
/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  verifyData(dataFormated) {
    let camposInvalidos = [];

    let verifyDataObj = dataFormated ? dataFormated : this.data;

    for (let i = 0; i < verifyDataObj.length; i++) {
      if (verifyDataObj[i].idItem && verifyDataObj[i].idItem != "") {
        for (const key in verifyDataObj[i]) {
          if (Object.hasOwnProperty.call(verifyDataObj[i], key)) {
            for (const x in verifyDataObj[i][key]) {
              if (Object.hasOwnProperty.call(verifyDataObj[i][key], x)) {
                if (x == "valido" && verifyDataObj[i][key].valido == false) {
                  camposInvalidos.push(true);
                }
              }
            }
          }
        }
      }
    }

    return camposInvalidos.length > 0 ? false : true;
  },
  init() {
    Vue.use(VueAxios, axios);

    this.setbaseURL(API_URL);
  },
  setbaseURL(url) {
    Vue.axios.defaults.baseURL = url;
  },
  getbaseURL() {
    this.init();
    return Vue.axios.defaults.baseURL;
  },
  showErrors(err) {
    Vue.$toast.error(
      err &&
        err.response &&
        err.response.data &&
        err.response.data.errors.length > 0 &&
        err.response.data.errors[0] != "Ocorreu um erro"
        ? err.response.data.errors[0]
        : "Ocorreu um erro durante o processamento, por favor refaça a operação.",
      {
        toastClassName: "toast-erro",
        position: "top-center",
        timeout: 3000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      }
    );
  },
  showSuccess(message = "A ação foi executada com sucesso!") {
    Vue.$toast.success(message, {
      position: "top-center",
      timeout: 3000,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 1,
      showCloseButtonOnHover: false,
      hideProgressBar: false,
      closeButton: "button",
      icon: true,
      rtl: false,
    });
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    this.init();
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${Jwt.buscarToken()}`;
    this.setbaseURL(API_URL);
    Vue.axios.defaults.headers.common["Content-Language"] = "pt-BR";
  },

  query(resource, params) {
    return Vue.axios.get(resource, params);
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "", config = {}) {
    this.setHeader();
    return new Promise((resolve, reject) => {
      Vue.axios
      .get(`${resource}/${slug}`)
      .then((retorno) => {
        resolve(retorno);
      })
      .catch((err) => {
        reject(err)
        if (
          err &&
          err.response &&
          err.response.status &&
          err.response.status == 401
        )
          return;        
        
        if (!config.noToast) {
          this.showErrors(err);
        }

      });
    });
  },
  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params, config = {}, file, noMultiple, formDataUp) {
    this.setHeader();

    if (formDataUp) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .post(`${resource}`, formDataUp, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((retorno) => {
            if (!config.noToast) this.showSuccess();

            resolve(retorno);
          })
          .catch((err) => {
            reject(err);
            if (
              err &&
              err.response &&
              err.response.status &&
              err.response.status == 401
            )
              return;
            this.showErrors(err);

          });
      })
    }

    if (file) {
      let formData = new FormData();

      if (!noMultiple) {
        for (var i = 0; i < file.length; i++) {
          let fileItem = file[i];
          formData.append("arquivos", fileItem);
        }
      } else {
        formData.append("arquivo", file);
      }

      // formData.append("arquivos", file);
      return new Promise((resolve, reject) => {
        Vue.axios
          .post(`${resource}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((retorno) => {
            if (!config.noToast) this.showSuccess();

            resolve(retorno);
          })
          .catch((err) => {
            reject(err);
            if (
              err &&
              err.response &&
              err.response.status &&
              err.response.status == 401
            )
              return;
            this.showErrors(err);

          });
        });
    } else {
      return new Promise((resolve, reject) => {
          Vue.axios
          .post(`${resource}`, params, config)
          .then((retorno) => {
            if (
              !config.noToast &&
              !retorno.data.data.bordero &&
              !this.verifyData(retorno)
            )
              this.showSuccess();

            if (params.forceSuccess) this.showSuccess();
            else if (
              !config.noToast &&
              !retorno.data.data.bordero &&
              !this.verifyData(retorno)
            )
              this.showSuccess();

            resolve(retorno);
          })
          .catch((err) => {
            reject(err);
            if (
              err &&
              err.response &&
              err.response.status &&
              err.response.status == 401
            )
              return;
            if (!config.noToast) this.showErrors(err);
          });
        });
    }
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    this.setHeader();
    return new Promise((resolve, reject) => {
      Vue.axios
      .put(`${resource}/${slug}`, params)
      .then((retorno) => {
        this.showSuccess();
        resolve(retorno);
      })
      .catch((err) => {
        reject(err);
        if (
          err &&
          err.response &&
          err.response.status &&
          err.response.status == 401
        )
          return;
        this.showErrors(err);
      });
    })
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params, config = {}) {
    this.setHeader();
    return new Promise((resolve, reject) => {
      Vue.axios
      .put(`${resource}`, params)
      .then((retorno) => {
        if (!config.noToast && !this.verifyData(retorno)) this.showSuccess();
        resolve(retorno);
      })
      .catch((err) => {
        reject(err);
        if (
          err &&
          err.response &&
          err.response.status &&
          err.response.status == 401
        )
          return;
        this.showErrors(err);
      });
    });
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource, params) {
    this.setHeader();
    return new Promise((resolve, reject) => {
      Vue.axios
      .delete(`${resource}`, { data: params })
      .then((retorno) => {
        this.showSuccess();
        resolve(retorno);
      })
      .catch((err) => {
        reject(err);
        if (
          err &&
          err.response &&
          err.response.status &&
          err.response.status == 401
        )
          return;
        this.showErrors(err);
      });
    });
  },
  uploadFile(resource, file) {
    let data = new FormData();
    data.append("fileName", file.name);
    data.append("file", file);
    let config = {
      header: {
        "Content-Type": "multipart/form-data",
      },
    };

    return new Promise((resolve, reject) => {
      this.post(resource, data, config)
      .then((retorno) => {
        resolve(retorno);
      })

      .catch((err) => {
        reject(err);
        if (
          err &&
          err.response &&
          err.response.status &&
          err.response.status == 401
        )
          return;
        this.showErrors(err);
      });
    });
  },
};

export default ApiService;
