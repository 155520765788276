import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

import { br } from "./pt-br";
import { en } from "./en";

export default new VueI18n({
  locale: localStorage.getItem('idioma') || 'br', // set default locale
  fallbackLocale: localStorage.getItem('idioma') || 'br', // set default locale
  silentTranslationWarn: true,
  messages: {
    br,
    en,
  },
});
