import verificaAutenticacao from "@/common/middleware/verificaAutenticacao.js";
import verificarPermissao from "../common/middleware/verificarPermissao";

export default [
  {
      name: "estabelecimentos",
      path: "/estabelecimentos",
      meta: {
          middleware: verificaAutenticacao,
      },
      beforeEnter: (to, from, next) => {
          verificarPermissao(next, "Estabelecimento");
      },
      component: () => import("@/views/estabelecimentos/Index"),
  },
  {
      name: "estabelecimentos-novo",
      path: "/estabelecimentos/novo",
      meta: {
          middleware: verificaAutenticacao,
      },
      beforeEnter: (to, from, next) => {
          verificarPermissao(next, "Estabelecimento");
      },
      component: () => import("@/views/estabelecimentos/Formulario"),
  },
  {
      name: "estabelecimentos-editar",
      path: "/estabelecimentos/:id?",
      meta: {
          middleware: verificaAutenticacao,
      },
      beforeEnter: (to, from, next) => {
          verificarPermissao(next, "Estabelecimento");
      },
      props : true,
      component: () => import("@/views/estabelecimentos/Formulario"),
  },
]
