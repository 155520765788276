import verificaAutenticacao from "@/common/middleware/verificaAutenticacao.js";
import verificarPermissao from "../common/middleware/verificarPermissao";

export default [
    {
        name: "clientes",
        path: "/clientes",
        meta: {
            middleware: verificaAutenticacao,
        },
        beforeEnter: (to, from, next) => {
            verificarPermissao(next, "Cliente");
        },
        component: () => import("@/views/clientes"),
    },
    {
        name: "clientes-novo",
        path: "/clientes/novo",
        meta: {
            middleware: verificaAutenticacao,
        },
        beforeEnter: (to, from, next) => {
            verificarPermissao(next, "Cliente");
        },
        component: () => import("@/views/clientes/formulario"),
    },
    {
        name: "clientes-editar",
        path: "/clientes/:id?",
        meta: {
            middleware: verificaAutenticacao,
        },
        beforeEnter: (to, from, next) => {
            verificarPermissao(next, "Cliente");
        },
        props : true,
        component: () => import("@/views/clientes/formulario"),
    },
]