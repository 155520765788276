<template>
  <b-form-group
    :id="id"
    :label="ocultarLabel ? '' : label"
    :label-sr-only="noLabel"
    :label-class="{ 'text-required': required }"
  >
    <template #label>
      <slot name="label">{{ label }}</slot>
    </template>
    <b-input-group :state="state">
      <div class="text-info loader" v-if="loading">
        <b-spinner class="align-middle spinner"></b-spinner>
      </div>
      <b-form-select
        v-model="content"
        :state="state"
        @change="handleInput"
        :options="options"
        :value-field="valueField"
        :text-field="textField"
        :size="size"
      >
        <template #first v-if="firstItemLabel">
          <b-form-select-option :value="null" selected>{{
            firstItemLabel
          }}</b-form-select-option>
        </template>
      </b-form-select>
      <template #append>
        <slot name="append"></slot>
      </template>
    </b-input-group>
    <b-form-invalid-feedback :state="state">{{ feedback }}</b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
export default {
  name: "InputSelect",

  props: {
    id: { type: String },
    ocultarLabel: { type: Boolean },
    firstItemLabel: { type: String, default: "" },
    value: { type: [String, Number, Object, Array, Boolean], default: null },
    label: { type: String },
    required: { type: Boolean },
    loading: { type: Boolean },
    requiredMessage: { type: String },
    options: { type: Array, required: true },
    valueField: { type: String, default: "value" },
    textField: { type: String, default: "text" },
    size: { type: String, default: "" },
    noLabel: { type: Boolean, default: false },
  },
  data() {
    return {
      content: this.value,
      validForm: false,
      validState: false,
      hasError: false,
      errorMessage: "",
    };
  },
  computed: {
    requiredValid() {
      return !(this.required && !this.value);
    },
    state() {
      if (!this.validState || !this.validForm) return null;
      return this.validacoes();
    },
    feedback() {
      if (!this.requiredValid)
        return (
          this.requiredMessage ||
          this.$t("CAMPOS_VALIDACAO.REQUERIDO").formatUnicorn({
            name: this.label,
          })
        );

      if (this.hasError) return this.errorMessage;
      return "";
    },
  },
  methods: {
    showError(message) {
      this.validForm = true;
      this.hasError = true;
      this.errorMessage = message;
    },
    handleInput() {
      this.$emit("input", this.content);
      this.hasError = false;
    },
    validacoes() {
      if (!this.value) return false;

      if (!this.requiredValid) return false;
      if (this.hasError) return false;

      return true;
    },
    valid(validacaoEscondida = false) {
      if (validacaoEscondida) {
        return this.validacoes();
      }
      this.validForm = true;
      this.validState = true;
      return this.state;
    },
    clear() {
      this.content = "";
      this.validForm = false;
      this.validState = false;
      this.$emit("input", this.content);
    },
  },
  watch: {
    value: {
      handler(value) {
        this.content = value;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style>
.loader {
  position: absolute;
  padding: 9px;
  z-index: 1;
}

.spinner {
  width: 18px;
  height: 18px;
}
</style>
