import MinhaContaService from "@/common/services/usuario/minha-conta.service";

export const BUSCAR_ESTABELECIMENTO = "buscarEstabelecimentos";

const state = {
  estabelecimentos: [],
};

const getters = {
  estabelecimentos(state) {
    return state.estabelecimentos;
  },
};

const actions = {
  [BUSCAR_ESTABELECIMENTO](context) {
    MinhaContaService.buscarEstabelecimento().then((res) => {
      context.commit(
        `${BUSCAR_ESTABELECIMENTO}_MUTATION`,
        res.data.data.estabelecimentos
      );
    });
  },
};

const mutations = {
  [`${BUSCAR_ESTABELECIMENTO}_MUTATION`](state, payload) {
    state.estabelecimentos = payload.map((el) => {
      return {
        value: el.id,
        text: el.nome,
      };
    });
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
