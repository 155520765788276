import verificaAutenticacao from "@/common/middleware/verificaAutenticacao.js";
import verificarPermissao from "../common/middleware/verificarPermissao";

export default [
    {
        name: "planos",
        path: "/planos",
        meta: {
            middleware: verificaAutenticacao,
        },
        beforeEnter: (to, from, next) => {
            verificarPermissao(next, "Plano");
        },
        component: () => import("@/views/planos/Index"),
    },
    {
        name: "planos-novo",
        path: "/planos/novo",
        meta: {
            middleware: verificaAutenticacao,
        },
        beforeEnter: (to, from, next) => {
            verificarPermissao(next, "Plano");
        },
        component: () => import("@/views/planos/Formulario"),
    },
    {
        name: "planos-editar",
        path: "/planos/:id?",
        meta: {
            middleware: verificaAutenticacao,
        },
        beforeEnter: (to, from, next) => {
            verificarPermissao(next, "Plano");
        },
        props : true,
        component: () => import("@/views/planos/Formulario"),
    },
]