export default [
    {
      codigoMCC: 1731,
      descricaoMCC: "Eletricistas, Material Eletrico, Lustres e Luminar",
      codigoCnae: 4321500,
      descricaoCnae: "Instalaçao e manutençao eletrica"
    },
    {
      codigoMCC: 1711,
      descricaoMCC: "CONTRATANTES DE AQUECIMENTO, CANALIZAÇAO E AR CONDICIONADO",
      codigoCnae: 4322301,
      descricaoCnae: "Instalaçoes hidraulicas, sanitarias e de gas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 122900,
      descricaoCnae: "Cultivo de flores e plantas ornamentais"
    },
    {
      codigoMCC: 780,
      descricaoMCC: "Horticultura / Serviço de Jardinagem",
      codigoCnae: 121101,
      descricaoCnae: "Horticultura, exceto morango"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 142300,
      descricaoCnae: "Produçao de mudas e outras formas de propagaçao vegetal, certificadas"
    },
    {
      codigoMCC: 1520,
      descricaoMCC: "Material de Construçao, Construtoras e Empreiteiro",
      codigoCnae: 4120400,
      descricaoCnae: "Construçao de edificios"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 4683400,
      descricaoCnae: "Comercio atacadista de defensivos agricolas, adubos, fertilizantes e corretivos do solo"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 4623199,
      descricaoCnae: "Comercio atacadista de materias-primas agricolas nao especificadas anteriormente"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 210107,
      descricaoCnae: "Extraçao de madeira em florestas plantadas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 210103,
      descricaoCnae: "Cultivo de pinus"
    },
    {
      codigoMCC: 1711,
      descricaoMCC: "",
      codigoCnae: 4322303,
      descricaoCnae: "Instalaçoes de sistema de prevençao contra incendio"
    },
    {
      codigoMCC: 780,
      descricaoMCC: "Horticultura / Serviço de Jardinagem",
      codigoCnae: 8130300,
      descricaoCnae: "Atividades paisagisticas"
    },
    {
      codigoMCC: 1731,
      descricaoMCC: "Eletricistas, Material Eletrico, Lustres e Luminar",
      codigoCnae: 4329104,
      descricaoCnae: "Montagem e instalaçao de sistemas e equipamentos de iluminaçao e sinalizaçao em vias publicas, portos e aeroportos"
    },
    {
      codigoMCC: 1520,
      descricaoMCC: "Material de Construçao, Construtoras e Empreiteiro",
      codigoCnae: 4399199,
      descricaoCnae: "Serviços especializados para construçao nao especificados anteriormente"
    },
    {
      codigoMCC: 1520,
      descricaoMCC: "Material de Construçao, Construtoras e Empreiteiro",
      codigoCnae: 4110700,
      descricaoCnae: "Incorporaçao de empreendimentos imobiliarios"
    },
    {
      codigoMCC: 1740,
      descricaoMCC: "Isolamento / Forro / Cobertura - Serviços",
      codigoCnae: 810007,
      descricaoCnae: "Extraçao de argila e beneficiamento associado"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 4692300,
      descricaoCnae: "Comercio atacadista de mercadorias em geral, com predominancia de insumos agropecuarios"
    },
    {
      codigoMCC: 1711,
      descricaoMCC: "",
      codigoCnae: 4322302,
      descricaoCnae: "Instalaçao e manutençao de sistemas centrais de ar condicionado, de ventilaçao e refrigeraçao"
    },
    {
      codigoMCC: 1731,
      descricaoMCC: "Eletricistas, Material Eletrico, Lustres e Luminar",
      codigoCnae: 4221905,
      descricaoCnae: "Manutençao de estaçoes e redes de telecomunicaçoes"
    },
    {
      codigoMCC: 1740,
      descricaoMCC: "Isolamento / Forro / Cobertura - Serviços",
      codigoCnae: 810004,
      descricaoCnae: "Extraçao de calcario e dolomita e beneficiamento associado"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 161003,
      descricaoCnae: "Serviço de preparaçao de terreno, cultivo e colheita"
    },
    {
      codigoMCC: 742,
      descricaoMCC: "Veterinario",
      codigoCnae: 162801,
      descricaoCnae: "Serviço de inseminaçao artificial em animais"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 7490103,
      descricaoCnae: "Serviços de agronomia e de consultoria as atividades agricolas e pecuarias"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 111301,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 111302,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 111303,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 111399,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 112101,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 112102,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 112199,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 113000,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 114800,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 115600,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 116401,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 116402,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 116403,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 116499,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 119901,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 119902,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 119903,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 119904,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 119905,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 119906,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 119907,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 119908,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 119909,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 119999,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 121102,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 131800,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 132600,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 133401,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 133402,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 133403,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 133404,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 133405,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 133406,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 133407,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 133408,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 133409,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 133410,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 133411,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 133499,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 134200,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 135100,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 139301,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 139302,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 139303,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 139304,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 139305,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 139306,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 139399,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 141501,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 141502,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 151201,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 151202,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 151203,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 152101,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 152102,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 152103,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 153901,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 153902,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 154700,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 155501,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 155502,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 155503,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 155504,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 155505,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 159801,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 159802,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 159803,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 159804,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 159899,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 161001,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 161002,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 161099,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 162802,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 162803,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 162899,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 163600,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 170900,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 210101,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 210102,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 210104,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 210105,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 210106,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 210109,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 210199,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 220901,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 220903,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 220904,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 220905,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 220906,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 220999,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 230600,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 311601,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 311602,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 311603,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 311604,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 312401,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 312402,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 312403,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 312404,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 321301,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 321302,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 321303,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 321304,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 321305,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 321399,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 322101,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 322102,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 322103,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 322104,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 322105,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 322106,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 322107,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 322199,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 892403,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 1061901,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 1081301,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 1081302,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 1210700,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 4611700,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 763,
      descricaoMCC: "Cooperativas Agricolas",
      codigoCnae: 4623107,
      descricaoCnae: "Cooperativas Agricolas"
    },
    {
      codigoMCC: 1520,
      descricaoMCC: "Material de Construçao, Construtoras e Empreiteiro",
      codigoCnae: 1622601,
      descricaoCnae: "Material de Construçao, Construtoras e Empreiteiro"
    },
    {
      codigoMCC: 1520,
      descricaoMCC: "Material de Construçao, Construtoras e Empreiteiro",
      codigoCnae: 4211102,
      descricaoCnae: "Material de Construçao, Construtoras e Empreiteiro"
    },
    {
      codigoMCC: 1520,
      descricaoMCC: "Material de Construçao, Construtoras e Empreiteiro",
      codigoCnae: 4222701,
      descricaoCnae: "Material de Construçao, Construtoras e Empreiteiro"
    },
    {
      codigoMCC: 1520,
      descricaoMCC: "Material de Construçao, Construtoras e Empreiteiro",
      codigoCnae: 4222702,
      descricaoCnae: "Material de Construçao, Construtoras e Empreiteiro"
    },
    {
      codigoMCC: 1520,
      descricaoMCC: "Material de Construçao, Construtoras e Empreiteiro",
      codigoCnae: 4223500,
      descricaoCnae: "Material de Construçao, Construtoras e Empreiteiro"
    },
    {
      codigoMCC: 1520,
      descricaoMCC: "Material de Construçao, Construtoras e Empreiteiro",
      codigoCnae: 4291000,
      descricaoCnae: "Material de Construçao, Construtoras e Empreiteiro"
    },
    {
      codigoMCC: 1520,
      descricaoMCC: "Material de Construçao, Construtoras e Empreiteiro",
      codigoCnae: 4292802,
      descricaoCnae: "Material de Construçao, Construtoras e Empreiteiro"
    },
    {
      codigoMCC: 1520,
      descricaoMCC: "Material de Construçao, Construtoras e Empreiteiro",
      codigoCnae: 4311801,
      descricaoCnae: "Material de Construçao, Construtoras e Empreiteiro"
    },
    {
      codigoMCC: 1520,
      descricaoMCC: "Material de Construçao, Construtoras e Empreiteiro",
      codigoCnae: 4399101,
      descricaoCnae: "Material de Construçao, Construtoras e Empreiteiro"
    },
    {
      codigoMCC: 1520,
      descricaoMCC: "Material de Construçao, Construtoras e Empreiteiro",
      codigoCnae: 9102302,
      descricaoCnae: "Material de Construçao, Construtoras e Empreiteiro"
    },
    {
      codigoMCC: 1740,
      descricaoMCC: "Isolamento / Forro / Cobertura - Serviços",
      codigoCnae: 810001,
      descricaoCnae: "Isolamento / Forro / Cobertura - Serviços"
    },
    {
      codigoMCC: 1740,
      descricaoMCC: "Isolamento / Forro / Cobertura - Serviços",
      codigoCnae: 810002,
      descricaoCnae: "Isolamento / Forro / Cobertura - Serviços"
    },
    {
      codigoMCC: 1740,
      descricaoMCC: "Isolamento / Forro / Cobertura - Serviços",
      codigoCnae: 810003,
      descricaoCnae: "Isolamento / Forro / Cobertura - Serviços"
    },
    {
      codigoMCC: 1740,
      descricaoMCC: "Isolamento / Forro / Cobertura - Serviços",
      codigoCnae: 810005,
      descricaoCnae: "Isolamento / Forro / Cobertura - Serviços"
    },
    {
      codigoMCC: 1740,
      descricaoMCC: "Isolamento / Forro / Cobertura - Serviços",
      codigoCnae: 810006,
      descricaoCnae: "Isolamento / Forro / Cobertura - Serviços"
    },
    {
      codigoMCC: 1740,
      descricaoMCC: "Isolamento / Forro / Cobertura - Serviços",
      codigoCnae: 810008,
      descricaoCnae: "Isolamento / Forro / Cobertura - Serviços"
    },
    {
      codigoMCC: 1740,
      descricaoMCC: "Isolamento / Forro / Cobertura - Serviços",
      codigoCnae: 810009,
      descricaoCnae: "Isolamento / Forro / Cobertura - Serviços"
    },
    {
      codigoMCC: 4215,
      descricaoMCC: "Courier",
      codigoCnae: 5250805,
      descricaoCnae: "Operador de transporte multimodal - OTM"
    },
    {
      codigoMCC: 2741,
      descricaoMCC: "Diversos - Publicaçao e Impressos",
      codigoCnae: 5811500,
      descricaoCnae: "Ediçao de livros"
    },
    {
      codigoMCC: 2741,
      descricaoMCC: "Diversos - Publicaçao e Impressos",
      codigoCnae: 5821200,
      descricaoCnae: "Ediçao integrada a impressao de livros"
    },
    {
      codigoMCC: 4215,
      descricaoMCC: "Courier",
      codigoCnae: 5250804,
      descricaoCnae: "Organizaçao logistica do transporte de carga"
    },
    {
      codigoMCC: 4214,
      descricaoMCC: "Mudanças",
      codigoCnae: 4930202,
      descricaoCnae: "Transporte rodoviario de carga, exceto produtos perigosos e mudanças, intermunicipal, interestadual e internacional"
    },
    {
      codigoMCC: 2842,
      descricaoMCC: "Serviços de Limpeza / Polimento",
      codigoCnae: 4649408,
      descricaoCnae: "Comercio atacadista de produtos de higiene, limpeza e conservaçao domiciliar"
    },
    {
      codigoMCC: 4816,
      descricaoMCC: "Lan House, Comercio de Eletronicos e Informatica",
      codigoCnae: 6311900,
      descricaoCnae: "Tratamento de dados, provedores de serviços de aplicaçao e serviços de hospedagem na internet"
    },
    {
      codigoMCC: 4812,
      descricaoMCC: "EQUIPAMENTO DE TELECOMUNICAÇOES E VENDAS DE TELEFONE",
      codigoCnae: 4752100,
      descricaoCnae: "Comercio varejista especializado de equipamentos de telefonia e comunicaçao"
    },
    {
      codigoMCC: 1799,
      descricaoMCC: "Marcenaria, Serralheria, Vidraceiro e Reformas",
      codigoCnae: 4330499,
      descricaoCnae: "Outras obras de acabamento da construçao"
    },
    {
      codigoMCC: 1740,
      descricaoMCC: "Isolamento / Forro / Cobertura - Serviços",
      codigoCnae: 4399103,
      descricaoCnae: "Obras de alvenaria"
    },
    {
      codigoMCC: 4722,
      descricaoMCC: "Operadores / Agencias de Viagem",
      codigoCnae: 7912100,
      descricaoCnae: "Operadores turisticos"
    },
    {
      codigoMCC: 4722,
      descricaoMCC: "Operadores / Agencias de Viagem",
      codigoCnae: 7911200,
      descricaoCnae: "Agencias de viagens"
    },
    {
      codigoMCC: 4722,
      descricaoMCC: "Operadores / Agencias de Viagem",
      codigoCnae: 7990200,
      descricaoCnae: "Serviços de reservas e outros serviços de turismo nao especificados anteriormente"
    },
    {
      codigoMCC: 4814,
      descricaoMCC: "Fax, Recarga Celular, Chamadas Locais e Longa Dist",
      codigoCnae: 6190699,
      descricaoCnae: "Outras atividades de telecomunicaçoes nao especificadas anteriormente"
    },
    {
      codigoMCC: 4131,
      descricaoMCC: "Linhas de Onibus, Transporte de Passageiros e Esco",
      codigoCnae: 4929901,
      descricaoCnae: "Transporte rodoviario coletivo de passageiros, sob regime de fretamento, municipal"
    },
    {
      codigoMCC: 1740,
      descricaoMCC: "Isolamento / Forro / Cobertura - Serviços",
      codigoCnae: 4330405,
      descricaoCnae: "Aplicaçao de revestimentos e de resinas em interiores e exteriores"
    },
    {
      codigoMCC: 4131,
      descricaoMCC: "Linhas de Onibus, Transporte de Passageiros e Esco",
      codigoCnae: 4929902,
      descricaoCnae: "Transporte rodoviario coletivo de passageiros, sob regime de fretamento, intermunicipal, interestadual e internacional"
    },
    {
      codigoMCC: 4214,
      descricaoMCC: "Mudanças",
      codigoCnae: 4930201,
      descricaoCnae: "Transporte rodoviario de carga, exceto produtos perigosos e mudanças, municipal."
    },
    {
      codigoMCC: 4215,
      descricaoMCC: "Courier",
      codigoCnae: 5320202,
      descricaoCnae: "Serviços de entrega rapida"
    },
    {
      codigoMCC: 4121,
      descricaoMCC: "Taxi / Limousines",
      codigoCnae: 4923001,
      descricaoCnae: "Serviço de taxi"
    },
    {
      codigoMCC: 1761,
      descricaoMCC: "Ferros / Metais / Coberturas - Fornecedores",
      codigoCnae: 4330404,
      descricaoCnae: "Serviços de pintura de edificios em geral"
    },
    {
      codigoMCC: 1799,
      descricaoMCC: "Marcenaria, Serralheria, Vidraceiro e Reformas",
      codigoCnae: 4299599,
      descricaoCnae: "Outras obras de engenharia civil nao especificadas anteriormente"
    },
    {
      codigoMCC: 4816,
      descricaoMCC: "Lan House, Comercio de Eletronicos e Informatica",
      codigoCnae: 6319400,
      descricaoCnae: "Portais, provedores de conteudo e outros serviços de informaçao na internet"
    },
    {
      codigoMCC: 2741,
      descricaoMCC: "Diversos - Publicaçao e Impressos",
      codigoCnae: 5812301,
      descricaoCnae: "Ediçao de jornais diarios"
    },
    {
      codigoMCC: 2741,
      descricaoMCC: "Diversos - Publicaçao e Impressos",
      codigoCnae: 5813100,
      descricaoCnae: "Ediçao de revistas"
    },
    {
      codigoMCC: 2741,
      descricaoMCC: "Diversos - Publicaçao e Impressos",
      codigoCnae: 1813001,
      descricaoCnae: "Impressao de material para uso publicitario"
    },
    {
      codigoMCC: 2791,
      descricaoMCC: "Datilografia, Digitaçao e Serviços Relacionados",
      codigoCnae: 1821100,
      descricaoCnae: "Serviços de pre-impressao"
    },
    {
      codigoMCC: 2791,
      descricaoMCC: "Datilografia, Digitaçao e Serviços Relacionados",
      codigoCnae: 3299003,
      descricaoCnae: "Fabricaçao de letras, letreiros e placas de qualquer material, exceto luminosos"
    },
    {
      codigoMCC: 1750,
      descricaoMCC: "Carpintaria - Serviços",
      codigoCnae: 4330402,
      descricaoCnae: "Instalaçao de portas, janelas, tetos, divisorias e armarios embutidos de qualquer material"
    },
    {
      codigoMCC: 4789,
      descricaoMCC: "Cooperativas de Taxi e Serviços de Transporte nao",
      codigoCnae: 5229099,
      descricaoCnae: "Outras atividades auxiliares dos transportes terrestres nao especificadas anteriormente"
    },
    {
      codigoMCC: 4215,
      descricaoMCC: "Courier",
      codigoCnae: 5250803,
      descricaoCnae: "Agenciamento de cargas, exceto para o transporte maritimo"
    },
    {
      codigoMCC: 4215,
      descricaoMCC: "Courier",
      codigoCnae: 5320201,
      descricaoCnae: "Serviços de malote nao realizados pelo Correio Nacional"
    },
    {
      codigoMCC: 2741,
      descricaoMCC: "Diversos - Publicaçao e Impressos",
      codigoCnae: 1811302,
      descricaoCnae: "Impressao de livros, revistas e outras publicaçoes periodicas"
    },
    {
      codigoMCC: 2741,
      descricaoMCC: "Diversos - Publicaçao e Impressos",
      codigoCnae: 5829800,
      descricaoCnae: "Ediçao integrada a impressao de cadastros, listas e de outros produtos graficos"
    },
    {
      codigoMCC: 2741,
      descricaoMCC: "Diversos - Publicaçao e Impressos",
      codigoCnae: 1811301,
      descricaoCnae: "Impressao de jornais"
    },
    {
      codigoMCC: 2741,
      descricaoMCC: "Diversos - Publicaçao e Impressos",
      codigoCnae: 1813099,
      descricaoCnae: "Impressao de material para outros usos"
    },
    {
      codigoMCC: 4225,
      descricaoMCC: "Armazenamento - Produtos Agricolas, Mercadorias Re",
      codigoCnae: 5211799,
      descricaoCnae: "Depositos de mercadorias para terceiros, exceto armazens gerais e guarda-moveis"
    },
    {
      codigoMCC: 4131,
      descricaoMCC: "Linhas de Onibus, Transporte de Passageiros e Esco",
      codigoCnae: 4922101,
      descricaoCnae: "Transporte rodoviario coletivo de passageiros, com itinerario fixo, intermunicipal, exceto em regiao metropolitana"
    },
    {
      codigoMCC: 4131,
      descricaoMCC: "Linhas de Onibus, Transporte de Passageiros e Esco",
      codigoCnae: 4921302,
      descricaoCnae: "Transporte rodoviario coletivo de passageiros, com itinerario fixo, intermunicipal em regiao metropolitana"
    },
    {
      codigoMCC: 4131,
      descricaoMCC: "Linhas de Onibus, Transporte de Passageiros e Esco",
      codigoCnae: 4921301,
      descricaoCnae: "Transporte rodoviario coletivo de passageiros, com itinerario fixo, municipal"
    },
    {
      codigoMCC: 4131,
      descricaoMCC: "Linhas de Onibus, Transporte de Passageiros e Esco",
      codigoCnae: 4922102,
      descricaoCnae: "Transporte rodoviario coletivo de passageiros, com itinerario fixo, interestadual"
    },
    {
      codigoMCC: 1740,
      descricaoMCC: "Isolamento / Forro / Cobertura - Serviços",
      codigoCnae: 4330403,
      descricaoCnae: "Obras de acabamento em gesso e estuque"
    },
    {
      codigoMCC: 4121,
      descricaoMCC: "Taxi / Limousines",
      codigoCnae: 4923002,
      descricaoCnae: "Serviço de transporte de passageiros - locaçao de automoveis com motorista"
    },
    {
      codigoMCC: 4814,
      descricaoMCC: "Fax, Recarga Celular, Chamadas Locais e Longa Dist",
      codigoCnae: 6120501,
      descricaoCnae: "Telefonia movel celular"
    },
    {
      codigoMCC: 4899,
      descricaoMCC: "TV a Cabo e outros tipos de TV Paga",
      codigoCnae: 6010100,
      descricaoCnae: "Atividades de radio"
    },
    {
      codigoMCC: 2741,
      descricaoMCC: "Diversos - Publicaçao e Impressos",
      codigoCnae: 5819100,
      descricaoCnae: "Ediçao de cadastros, listas e de outros produtos graficos"
    },
    {
      codigoMCC: 4899,
      descricaoMCC: "TV a Cabo e outros tipos de TV Paga",
      codigoCnae: 6021700,
      descricaoCnae: "Atividades de televisao aberta"
    },
    {
      codigoMCC: 4816,
      descricaoMCC: "Lan House, Comercio de Eletronicos e Informatica",
      codigoCnae: 8299707,
      descricaoCnae: "Salas de acesso a internet"
    },
    {
      codigoMCC: 4816,
      descricaoMCC: "Lan House, Comercio de Eletronicos e Informatica",
      codigoCnae: 6190601,
      descricaoCnae: "Provedores de acesso as redes de comunicaçoes"
    },
    {
      codigoMCC: 1799,
      descricaoMCC: "Marcenaria, Serralheria, Vidraceiro e Reformas",
      codigoCnae: 4329199,
      descricaoCnae: "Outras obras de instalaçoes em construçoes nao especificadas anteriormente"
    },
    {
      codigoMCC: 4214,
      descricaoMCC: "Mudanças",
      codigoCnae: 4930204,
      descricaoCnae: "Transporte rodoviario de mudanças"
    },
    {
      codigoMCC: 4411,
      descricaoMCC: "Cruzeiros e Cia Maritima",
      codigoCnae: 5232000,
      descricaoCnae: "Atividades de agenciamento maritimo"
    },
    {
      codigoMCC: 4814,
      descricaoMCC: "Fax, Recarga Celular, Chamadas Locais e Longa Dist",
      codigoCnae: 6110803,
      descricaoCnae: "Serviços de comunicaçao multimidia - SCM"
    },
    {
      codigoMCC: 1740,
      descricaoMCC: "Isolamento / Forro / Cobertura - Serviços",
      codigoCnae: 899199,
      descricaoCnae: "Extraçao de outros minerais nao-metalicos nao especificados anteriormente"
    },
    {
      codigoMCC: 4225,
      descricaoMCC: "Armazenamento - Produtos Agricolas, Mercadorias Re",
      codigoCnae: 5211701,
      descricaoCnae: "Armazens gerais - emissao de warrant"
    },
    {
      codigoMCC: 4214,
      descricaoMCC: "Mudanças",
      codigoCnae: 5212500,
      descricaoCnae: "Carga e descarga"
    },
    {
      codigoMCC: 1799,
      descricaoMCC: "Marcenaria, Serralheria, Vidraceiro e Reformas",
      codigoCnae: 4330401,
      descricaoCnae: "Impermeabilizaçao em obras de engenharia civil"
    },
    {
      codigoMCC: 4814,
      descricaoMCC: "Fax, Recarga Celular, Chamadas Locais e Longa Dist",
      codigoCnae: 6110801,
      descricaoCnae: "Serviços de telefonia fixa comutada - STFC"
    },
    {
      codigoMCC: 4814,
      descricaoMCC: "Fax, Recarga Celular, Chamadas Locais e Longa Dist",
      codigoCnae: 6190602,
      descricaoCnae: "Provedores de voz sobre protocolo internet - VOIP"
    },
    {
      codigoMCC: 4131,
      descricaoMCC: "Linhas de Onibus, Transporte de Passageiros e Esco",
      codigoCnae: 4929903,
      descricaoCnae: "Organizaçao de excursoes em veiculos rodoviarios proprios, municipal"
    },
    {
      codigoMCC: 4225,
      descricaoMCC: "Armazenamento - Produtos Agricolas, Mercadorias Re",
      codigoCnae: 5211702,
      descricaoCnae: "Guarda-moveis"
    },
    {
      codigoMCC: 4511,
      descricaoMCC: "Companhias Aereas e Taxi Aereo",
      codigoCnae: 5112901,
      descricaoCnae: "Serviço de taxi aereo e locaçao de aeronaves com tripulaçao"
    },
    {
      codigoMCC: 1740,
      descricaoMCC: "Isolamento / Forro / Cobertura - Serviços",
      codigoCnae: 990403,
      descricaoCnae: "Atividades de apoio a extraçao de minerais nao-metalicos"
    },
    {
      codigoMCC: 1799,
      descricaoMCC: "Marcenaria, Serralheria, Vidraceiro e Reformas",
      codigoCnae: 4399102,
      descricaoCnae: "Montagem e desmontagem de andaimes e outras estruturas temporarias"
    },
    {
      codigoMCC: 1771,
      descricaoMCC: "Concreto - Serviços",
      codigoCnae: 4213800,
      descricaoCnae: "Obras de urbanizaçao - ruas, praças e calçadas"
    },
    {
      codigoMCC: 1799,
      descricaoMCC: "Marcenaria, Serralheria, Vidraceiro e Reformas",
      codigoCnae: 4399105,
      descricaoCnae: "Perfuraçao e construçao de poços de agua"
    },
    {
      codigoMCC: 1771,
      descricaoMCC: "Concreto - Serviços",
      codigoCnae: 4211101,
      descricaoCnae: "Construçao de rodovias e ferrovias"
    },
    {
      codigoMCC: 1771,
      descricaoMCC: "Concreto - Serviços",
      codigoCnae: 2330305,
      descricaoCnae: "Preparaçao de massa de concreto e argamassa para construçao"
    },
    {
      codigoMCC: 1740,
      descricaoMCC: "Isolamento / Forro / Cobertura - Serviços",
      codigoCnae: 810010,
      descricaoCnae: "Isolamento / Forro / Cobertura - Serviços"
    },
    {
      codigoMCC: 1740,
      descricaoMCC: "Isolamento / Forro / Cobertura - Serviços",
      codigoCnae: 810099,
      descricaoCnae: "Isolamento / Forro / Cobertura - Serviços"
    },
    {
      codigoMCC: 1740,
      descricaoMCC: "Isolamento / Forro / Cobertura - Serviços",
      codigoCnae: 2391502,
      descricaoCnae: "Isolamento / Forro / Cobertura - Serviços"
    },
    {
      codigoMCC: 1740,
      descricaoMCC: "Isolamento / Forro / Cobertura - Serviços",
      codigoCnae: 4329105,
      descricaoCnae: "Isolamento / Forro / Cobertura - Serviços"
    },
    {
      codigoMCC: 1771,
      descricaoMCC: "Concreto - Serviços",
      codigoCnae: 4311802,
      descricaoCnae: "Concreto - Serviços"
    },
    {
      codigoMCC: 1771,
      descricaoMCC: "Concreto - Serviços",
      codigoCnae: 4391600,
      descricaoCnae: "Concreto - Serviços"
    },
    {
      codigoMCC: 1799,
      descricaoMCC: "Marcenaria, Serralheria, Vidraceiro e Reformas",
      codigoCnae: 2513600,
      descricaoCnae: "Marcenaria, Serralheria, Vidraceiro e Reformas"
    },
    {
      codigoMCC: 1799,
      descricaoMCC: "Marcenaria, Serralheria, Vidraceiro e Reformas",
      codigoCnae: 4299501,
      descricaoCnae: "Marcenaria, Serralheria, Vidraceiro e Reformas"
    },
    {
      codigoMCC: 2741,
      descricaoMCC: "Diversos - Publicaçao e Impressos",
      codigoCnae: 1812100,
      descricaoCnae: "Diversos - Publicaçao e Impressos"
    },
    {
      codigoMCC: 2741,
      descricaoMCC: "Diversos - Publicaçao e Impressos",
      codigoCnae: 5812302,
      descricaoCnae: "Diversos - Publicaçao e Impressos"
    },
    {
      codigoMCC: 2741,
      descricaoMCC: "Diversos - Publicaçao e Impressos",
      codigoCnae: 5822101,
      descricaoCnae: "Diversos - Publicaçao e Impressos"
    },
    {
      codigoMCC: 2741,
      descricaoMCC: "Diversos - Publicaçao e Impressos",
      codigoCnae: 5822102,
      descricaoCnae: "Diversos - Publicaçao e Impressos"
    },
    {
      codigoMCC: 2741,
      descricaoMCC: "Diversos - Publicaçao e Impressos",
      codigoCnae: 5823900,
      descricaoCnae: "Diversos - Publicaçao e Impressos"
    },
    {
      codigoMCC: 2741,
      descricaoMCC: "Diversos - Publicaçao e Impressos",
      codigoCnae: 5822100,
      descricaoCnae: "Diversos - Publicaçao e Impressos"
    },
    {
      codigoMCC: 2741,
      descricaoMCC: "Diversos - Publicaçao e Impressos",
      codigoCnae: 5812300,
      descricaoCnae: "Diversos - Publicaçao e Impressos"
    },
    {
      codigoMCC: 2791,
      descricaoMCC: "Datilografia, Digitaçao e Serviços Relacionados",
      codigoCnae: 8299703,
      descricaoCnae: "Datilografia, Digitaçao e Serviços Relacionados"
    },
    {
      codigoMCC: 2842,
      descricaoMCC: "Serviços de Limpeza / Polimento",
      codigoCnae: 2061400,
      descricaoCnae: "Serviços de Limpeza / Polimento"
    },
    {
      codigoMCC: 2842,
      descricaoMCC: "Serviços de Limpeza / Polimento",
      codigoCnae: 4649409,
      descricaoCnae: "Serviços de Limpeza / Polimento"
    },
    {
      codigoMCC: 2842,
      descricaoMCC: "Serviços de Limpeza / Polimento",
      codigoCnae: 2052500,
      descricaoCnae: "Serviços de Limpeza / Polimento"
    },
    {
      codigoMCC: 2842,
      descricaoMCC: "Serviços de Limpeza / Polimento",
      codigoCnae: 2062200,
      descricaoCnae: "Serviços de Limpeza / Polimento"
    },
    {
      codigoMCC: 4111,
      descricaoMCC: "Transportes Urbanos - Terrestres e Balsas",
      codigoCnae: 5011402,
      descricaoCnae: "Transportes Urbanos - Terrestres e Balsas"
    },
    {
      codigoMCC: 4111,
      descricaoMCC: "Transportes Urbanos - Terrestres e Balsas",
      codigoCnae: 5091202,
      descricaoCnae: "Transportes Urbanos - Terrestres e Balsas"
    },
    {
      codigoMCC: 4111,
      descricaoMCC: "Transportes Urbanos - Terrestres e Balsas",
      codigoCnae: 5099801,
      descricaoCnae: "Transportes Urbanos - Terrestres e Balsas"
    },
    {
      codigoMCC: 4111,
      descricaoMCC: "Transportes Urbanos - Terrestres e Balsas",
      codigoCnae: 5099899,
      descricaoCnae: "Transportes Urbanos - Terrestres e Balsas"
    },
    {
      codigoMCC: 4111,
      descricaoMCC: "Transportes Urbanos - Terrestres e Balsas",
      codigoCnae: 5222200,
      descricaoCnae: "Transportes Urbanos - Terrestres e Balsas"
    },
    {
      codigoMCC: 4111,
      descricaoMCC: "Transportes Urbanos - Terrestres e Balsas",
      codigoCnae: 4912402,
      descricaoCnae: "Transportes Urbanos - Terrestres e Balsas"
    },
    {
      codigoMCC: 4111,
      descricaoMCC: "Transportes Urbanos - Terrestres e Balsas",
      codigoCnae: 4912403,
      descricaoCnae: "Transportes Urbanos - Terrestres e Balsas"
    },
    {
      codigoMCC: 4112,
      descricaoMCC: "Trem de Passageiros e Cia Ferroviaria",
      codigoCnae: 4912401,
      descricaoCnae: "Trem de Passageiros e Cia Ferroviaria"
    },
    {
      codigoMCC: 4119,
      descricaoMCC: "Serviços de Ambulancia",
      codigoCnae: 8621601,
      descricaoCnae: "Serviços de Ambulancia"
    },
    {
      codigoMCC: 4119,
      descricaoMCC: "Serviços de Ambulancia",
      codigoCnae: 8621602,
      descricaoCnae: "Serviços de Ambulancia"
    },
    {
      codigoMCC: 4119,
      descricaoMCC: "Serviços de Ambulancia",
      codigoCnae: 8622400,
      descricaoCnae: "Serviços de Ambulancia"
    },
    {
      codigoMCC: 4121,
      descricaoMCC: "Taxi / Limousines",
      codigoCnae: 5229001,
      descricaoCnae: "Taxi / Limousines"
    },
    {
      codigoMCC: 4131,
      descricaoMCC: "Linhas de Onibus, Transporte de Passageiros e Esco",
      codigoCnae: 4922103,
      descricaoCnae: "Linhas de Onibus, Transporte de Passageiros e Esco"
    },
    {
      codigoMCC: 4131,
      descricaoMCC: "Linhas de Onibus, Transporte de Passageiros e Esco",
      codigoCnae: 4929904,
      descricaoCnae: "Linhas de Onibus, Transporte de Passageiros e Esco"
    },
    {
      codigoMCC: 4214,
      descricaoMCC: "Mudanças",
      codigoCnae: 4930203,
      descricaoCnae: "Mudanças"
    },
    {
      codigoMCC: 4225,
      descricaoMCC: "Armazenamento - Produtos Agricolas, Mercadorias Re",
      codigoCnae: 4632003,
      descricaoCnae: "Armazenamento - Produtos Agricolas, Mercadorias Re"
    },
    {
      codigoMCC: 4411,
      descricaoMCC: "Cruzeiros e Cia Maritima",
      codigoCnae: 5012202,
      descricaoCnae: "Cruzeiros e Cia Maritima"
    },
    {
      codigoMCC: 4457,
      descricaoMCC: "Nautica - Aluguel e Arrendamento de Navios",
      codigoCnae: 7719501,
      descricaoCnae: "Nautica - Aluguel e Arrendamento de Navios"
    },
    {
      codigoMCC: 4468,
      descricaoMCC: "Suprimentos e Serviços Nauticos",
      codigoCnae: 3317101,
      descricaoCnae: "Suprimentos e Serviços Nauticos"
    },
    {
      codigoMCC: 4468,
      descricaoMCC: "Suprimentos e Serviços Nauticos",
      codigoCnae: 3317102,
      descricaoCnae: "Suprimentos e Serviços Nauticos"
    },
    {
      codigoMCC: 4511,
      descricaoMCC: "Companhias Aereas e Taxi Aereo",
      codigoCnae: 5111100,
      descricaoCnae: "Companhias Aereas e Taxi Aereo"
    },
    {
      codigoMCC: 4511,
      descricaoMCC: "Companhias Aereas e Taxi Aereo",
      codigoCnae: 5112999,
      descricaoCnae: "Companhias Aereas e Taxi Aereo"
    },
    {
      codigoMCC: 4511,
      descricaoMCC: "Companhias Aereas e Taxi Aereo",
      codigoCnae: 5120000,
      descricaoCnae: "Companhias Aereas e Taxi Aereo"
    },
    {
      codigoMCC: 4582,
      descricaoMCC: "Aeroportos",
      codigoCnae: 5240101,
      descricaoCnae: "Aeroportos"
    },
    {
      codigoMCC: 4582,
      descricaoMCC: "Aeroportos",
      codigoCnae: 5240199,
      descricaoCnae: "Aeroportos"
    },
    {
      codigoMCC: 4784,
      descricaoMCC: "Pedagios",
      codigoCnae: 5221400,
      descricaoCnae: "Pedagios"
    },
    {
      codigoMCC: 4789,
      descricaoMCC: "Cooperativas de Taxi e Serviços de Transporte nao",
      codigoCnae: 4924800,
      descricaoCnae: "Cooperativas de Taxi e Serviços de Transporte nao"
    },
    {
      codigoMCC: 4789,
      descricaoMCC: "Cooperativas de Taxi e Serviços de Transporte nao",
      codigoCnae: 4929999,
      descricaoCnae: "Cooperativas de Taxi e Serviços de Transporte nao"
    },
    {
      codigoMCC: 4789,
      descricaoMCC: "Cooperativas de Taxi e Serviços de Transporte nao",
      codigoCnae: 4940000,
      descricaoCnae: "Cooperativas de Taxi e Serviços de Transporte nao"
    },
    {
      codigoMCC: 4789,
      descricaoMCC: "Cooperativas de Taxi e Serviços de Transporte nao",
      codigoCnae: 4950700,
      descricaoCnae: "Cooperativas de Taxi e Serviços de Transporte nao"
    },
    {
      codigoMCC: 4789,
      descricaoMCC: "Cooperativas de Taxi e Serviços de Transporte nao",
      codigoCnae: 5011401,
      descricaoCnae: "Cooperativas de Taxi e Serviços de Transporte nao"
    },
    {
      codigoMCC: 4789,
      descricaoMCC: "Cooperativas de Taxi e Serviços de Transporte nao",
      codigoCnae: 5012201,
      descricaoCnae: "Cooperativas de Taxi e Serviços de Transporte nao"
    },
    {
      codigoMCC: 4789,
      descricaoMCC: "Cooperativas de Taxi e Serviços de Transporte nao",
      codigoCnae: 5021101,
      descricaoCnae: "Cooperativas de Taxi e Serviços de Transporte nao"
    },
    {
      codigoMCC: 4789,
      descricaoMCC: "Cooperativas de Taxi e Serviços de Transporte nao",
      codigoCnae: 5021102,
      descricaoCnae: "Cooperativas de Taxi e Serviços de Transporte nao"
    },
    {
      codigoMCC: 4789,
      descricaoMCC: "Cooperativas de Taxi e Serviços de Transporte nao",
      codigoCnae: 5022001,
      descricaoCnae: "Cooperativas de Taxi e Serviços de Transporte nao"
    },
    {
      codigoMCC: 4789,
      descricaoMCC: "Cooperativas de Taxi e Serviços de Transporte nao",
      codigoCnae: 5022002,
      descricaoCnae: "Cooperativas de Taxi e Serviços de Transporte nao"
    },
    {
      codigoMCC: 4789,
      descricaoMCC: "Cooperativas de Taxi e Serviços de Transporte nao",
      codigoCnae: 5030101,
      descricaoCnae: "Cooperativas de Taxi e Serviços de Transporte nao"
    },
    {
      codigoMCC: 4789,
      descricaoMCC: "Cooperativas de Taxi e Serviços de Transporte nao",
      codigoCnae: 5030102,
      descricaoCnae: "Cooperativas de Taxi e Serviços de Transporte nao"
    },
    {
      codigoMCC: 4789,
      descricaoMCC: "Cooperativas de Taxi e Serviços de Transporte nao",
      codigoCnae: 5091201,
      descricaoCnae: "Cooperativas de Taxi e Serviços de Transporte nao"
    },
    {
      codigoMCC: 4789,
      descricaoMCC: "Cooperativas de Taxi e Serviços de Transporte nao",
      codigoCnae: 5130700,
      descricaoCnae: "Cooperativas de Taxi e Serviços de Transporte nao"
    },
    {
      codigoMCC: 4814,
      descricaoMCC: "Fax, Recarga Celular, Chamadas Locais e Longa Dist",
      codigoCnae: 6110802,
      descricaoCnae: "Fax, Recarga Celular, Chamadas Locais e Longa Dist"
    },
    {
      codigoMCC: 4814,
      descricaoMCC: "Fax, Recarga Celular, Chamadas Locais e Longa Dist",
      codigoCnae: 6110899,
      descricaoCnae: "Fax, Recarga Celular, Chamadas Locais e Longa Dist"
    },
    {
      codigoMCC: 4814,
      descricaoMCC: "Fax, Recarga Celular, Chamadas Locais e Longa Dist",
      codigoCnae: 6120502,
      descricaoCnae: "Fax, Recarga Celular, Chamadas Locais e Longa Dist"
    },
    {
      codigoMCC: 4814,
      descricaoMCC: "Fax, Recarga Celular, Chamadas Locais e Longa Dist",
      codigoCnae: 6120599,
      descricaoCnae: "Fax, Recarga Celular, Chamadas Locais e Longa Dist"
    },
    {
      codigoMCC: 4814,
      descricaoMCC: "Fax, Recarga Celular, Chamadas Locais e Longa Dist",
      codigoCnae: 6130200,
      descricaoCnae: "Fax, Recarga Celular, Chamadas Locais e Longa Dist"
    },
    {
      codigoMCC: 4899,
      descricaoMCC: "TV a Cabo e outros tipos de TV Paga",
      codigoCnae: 6022501,
      descricaoCnae: "TV a Cabo e outros tipos de TV Paga"
    },
    {
      codigoMCC: 4899,
      descricaoMCC: "TV a Cabo e outros tipos de TV Paga",
      codigoCnae: 6022502,
      descricaoCnae: "TV a Cabo e outros tipos de TV Paga"
    },
    {
      codigoMCC: 5021,
      descricaoMCC: "Moveis Comerciais e para Escritorios",
      codigoCnae: 3101200,
      descricaoCnae: "Fabricaçao de moveis com predominancia de madeira"
    },
    {
      codigoMCC: 5021,
      descricaoMCC: "Moveis Comerciais e para Escritorios",
      codigoCnae: 4649404,
      descricaoCnae: "Comercio atacadista de moveis e artigos de colchoaria"
    },
    {
      codigoMCC: 5047,
      descricaoMCC: "Produtos / Suprimentos Medicos e Hospitalares",
      codigoCnae: 3250707,
      descricaoCnae: "Fabricaçao de artigos opticos"
    },
    {
      codigoMCC: 5047,
      descricaoMCC: "Produtos / Suprimentos Medicos e Hospitalares",
      codigoCnae: 4645103,
      descricaoCnae: "Comercio atacadista de produtos odontologicos"
    },
    {
      codigoMCC: 5045,
      descricaoMCC: "Equipamentos e Perifericos / Computadores",
      codigoCnae: 4651601,
      descricaoCnae: "Comercio atacadista de equipamentos de informatica"
    },
    {
      codigoMCC: 5045,
      descricaoMCC: "Equipamentos e Perifericos / Computadores",
      codigoCnae: 4651602,
      descricaoCnae: "Comercio atacadista de suprimentos para informatica"
    },
    {
      codigoMCC: 5045,
      descricaoMCC: "Equipamentos e Perifericos / Computadores",
      codigoCnae: 2621300,
      descricaoCnae: "Fabricaçao de equipamentos de informatica"
    },
    {
      codigoMCC: 5046,
      descricaoMCC: "Comercio de Equipamentos nao Classificados",
      codigoCnae: 2869100,
      descricaoCnae: "Fabricaçao de maquinas e equipamentos para uso industrial especifico nao especificados anteriormente, peças e acessorios"
    },
    {
      codigoMCC: 5046,
      descricaoMCC: "Comercio de Equipamentos nao Classificados",
      codigoCnae: 2840200,
      descricaoCnae: "Fabricaçao de maquinas-ferramenta, peças e acessorios"
    },
    {
      codigoMCC: 5013,
      descricaoMCC: "Peças e Suprimentos para Veiculos",
      codigoCnae: 2945000,
      descricaoCnae: "Fabricaçao de material eletrico e eletronico para veiculos automotores, exceto baterias"
    },
    {
      codigoMCC: 5045,
      descricaoMCC: "Equipamentos e Perifericos / Computadores",
      codigoCnae: 2622100,
      descricaoCnae: "Fabricaçao de perifericos para equipamentos de informatica"
    },
    {
      codigoMCC: 5047,
      descricaoMCC: "Produtos / Suprimentos Medicos e Hospitalares",
      codigoCnae: 4664800,
      descricaoCnae: "Comercio atacadista de maquinas, aparelhos e equipamentos para uso odonto-medico-hospitalar; partes e peças"
    },
    {
      codigoMCC: 5046,
      descricaoMCC: "Comercio de Equipamentos nao Classificados",
      codigoCnae: 4669999,
      descricaoCnae: "Comercio atacadista de outras maquinas e equipamentos nao especificados anteriormente; partes e peças"
    },
    {
      codigoMCC: 5039,
      descricaoMCC: "Materiais de Construçao Sem Classificaçao",
      codigoCnae: 4679602,
      descricaoCnae: "Comercio atacadista de marmores e granitos"
    },
    {
      codigoMCC: 5039,
      descricaoMCC: "Materiais de Construçao Sem Classificaçao",
      codigoCnae: 4679699,
      descricaoCnae: "Comercio atacadista de materiais de construçao em geral"
    },
    {
      codigoMCC: 5047,
      descricaoMCC: "Produtos / Suprimentos Medicos e Hospitalares",
      codigoCnae: 3250705,
      descricaoCnae: "Fabricaçao de materiais para medicina e odontologia (Nao dispensada *)"
    },
    {
      codigoMCC: 5039,
      descricaoMCC: "Materiais de Construçao Sem Classificaçao",
      codigoCnae: 4679604,
      descricaoCnae: "Comercio atacadista especializado de materiais de construçao nao especificados anteriormente"
    },
    {
      codigoMCC: 5039,
      descricaoMCC: "Materiais de Construçao Sem Classificaçao",
      codigoCnae: 4679603,
      descricaoCnae: "Comercio atacadista de vidros, espelhos, vitrais e molduras"
    },
    {
      codigoMCC: 5039,
      descricaoMCC: "Materiais de Construçao Sem Classificaçao",
      codigoCnae: 2223400,
      descricaoCnae: "Fabricaçao de tubos e acessorios de material plastico para uso na construçao"
    },
    {
      codigoMCC: 5039,
      descricaoMCC: "Materiais de Construçao Sem Classificaçao",
      codigoCnae: 2319200,
      descricaoCnae: "Fabricaçao de artigos de vidro"
    },
    {
      codigoMCC: 5046,
      descricaoMCC: "Comercio de Equipamentos nao Classificados",
      codigoCnae: 2751100,
      descricaoCnae: "Fabricaçao de fogoes, refrigeradores e maquinas de lavar e secar para uso domestico, peças e acessorios"
    },
    {
      codigoMCC: 5046,
      descricaoMCC: "Comercio de Equipamentos nao Classificados",
      codigoCnae: 2823200,
      descricaoCnae: "Fabricaçao de maquinas e aparelhos de refrigeraçao e ventilaçao para uso industrial e comercial, peças e acessorios"
    },
    {
      codigoMCC: 5046,
      descricaoMCC: "Comercio de Equipamentos nao Classificados",
      codigoCnae: 4665600,
      descricaoCnae: "Comercio atacadista de maquinas e equipamentos para uso comercial; partes e peças"
    },
    {
      codigoMCC: 5013,
      descricaoMCC: "Peças e Suprimentos para Veiculos",
      codigoCnae: 4541202,
      descricaoCnae: "Comercio por atacado de peças e acessorios para motocicletas e motonetas"
    },
    {
      codigoMCC: 5013,
      descricaoMCC: "Peças e Suprimentos para Veiculos",
      codigoCnae: 4530701,
      descricaoCnae: "Comercio por atacado de peças e acessorios novos para veiculos automotores"
    },
    {
      codigoMCC: 5039,
      descricaoMCC: "Materiais de Construçao Sem Classificaçao",
      codigoCnae: 2330302,
      descricaoCnae: "Fabricaçao de artefatos de cimento para uso na construçao"
    },
    {
      codigoMCC: 4900,
      descricaoMCC: "Serviços Publicos (Agua, Luz, Gas e Telefone)",
      codigoCnae: 4221903,
      descricaoCnae: "Manutençao de redes de distribuiçao de energia eletrica"
    },
    {
      codigoMCC: 5013,
      descricaoMCC: "Peças e Suprimentos para Veiculos",
      codigoCnae: 4530702,
      descricaoCnae: "Comercio por atacado de pneumaticos e camaras-de-ar"
    },
    {
      codigoMCC: 5046,
      descricaoMCC: "Comercio de Equipamentos nao Classificados",
      codigoCnae: 2822402,
      descricaoCnae: "Fabricaçao de maquinas, equipamentos e aparelhos para transporte e elevaçao de cargas, peças e acessorios"
    },
    {
      codigoMCC: 5046,
      descricaoMCC: "Comercio de Equipamentos nao Classificados",
      codigoCnae: 2822401,
      descricaoCnae: "Fabricaçao de maquinas, equipamentos e aparelhos para transporte e elevaçao de pessoas, peças e acessorios"
    },
    {
      codigoMCC: 5039,
      descricaoMCC: "Materiais de Construçao Sem Classificaçao",
      codigoCnae: 2330301,
      descricaoCnae: "Fabricaçao de estruturas pre-moldadas de concreto armado, em serie e sob encomenda"
    },
    {
      codigoMCC: 4900,
      descricaoMCC: "Serviços Publicos (Agua, Luz, Gas e Telefone)",
      codigoCnae: 3701100,
      descricaoCnae: "Gestao de redes de esgoto"
    },
    {
      codigoMCC: 5039,
      descricaoMCC: "Materiais de Construçao Sem Classificaçao",
      codigoCnae: 2330399,
      descricaoCnae: "Fabricaçao de outros artefatos e produtos de concreto, cimento, fibrocimento, gesso e materiais semelhantes"
    },
    {
      codigoMCC: 5039,
      descricaoMCC: "Materiais de Construçao Sem Classificaçao",
      codigoCnae: 2342702,
      descricaoCnae: "Fabricaçao de artefatos de ceramica e barro cozido para uso na construçao, exceto azulejos e pisos"
    },
    {
      codigoMCC: 5047,
      descricaoMCC: "Produtos / Suprimentos Medicos e Hospitalares",
      codigoCnae: 3250704,
      descricaoCnae: "Fabricaçao de aparelhos e utensilios para correçao de defeitos fisicos e aparelhos ortopedicos em geral, exceto sob encomenda"
    },
    {
      codigoMCC: 5039,
      descricaoMCC: "Materiais de Construçao Sem Classificaçao",
      codigoCnae: 2349499,
      descricaoCnae: "Fabricaçao de produtos ceramicos nao-refratarios nao especificados anteriormente"
    },
    {
      codigoMCC: 5046,
      descricaoMCC: "Comercio de Equipamentos nao Classificados",
      codigoCnae: 2829199,
      descricaoCnae: "Fabricaçao de outras maquinas e equipamentos de uso geral nao especificados anteriormente, peças e acessorios"
    },
    {
      codigoMCC: 5046,
      descricaoMCC: "Comercio de Equipamentos nao Classificados",
      codigoCnae: 2861500,
      descricaoCnae: "Fabricaçao de maquinas para a industria metalurgica, peças e acessorios, exceto maquinas-ferramenta"
    },
    {
      codigoMCC: 5047,
      descricaoMCC: "Produtos / Suprimentos Medicos e Hospitalares",
      codigoCnae: 3250701,
      descricaoCnae: "Fabricaçao de instrumentos nao-eletronicos e utensilios para uso medico, cirurgico, odontologico e de laboratorio"
    },
    {
      codigoMCC: 5047,
      descricaoMCC: "Produtos / Suprimentos Medicos e Hospitalares",
      codigoCnae: 4645101,
      descricaoCnae: "Comercio atacadista de instrumentos e materiais para uso medico, cirurgico, hospitalar e de laboratorios"
    },
    {
      codigoMCC: 5047,
      descricaoMCC: "Produtos / Suprimentos Medicos e Hospitalares",
      codigoCnae: 4645102,
      descricaoCnae: "Comercio atacadista de proteses e artigos de ortopedia"
    },
    {
      codigoMCC: 4899,
      descricaoMCC: "TV a Cabo e outros tipos de TV Paga",
      codigoCnae: 6141800,
      descricaoCnae: "Operadoras de televisao por assinatura por cabo"
    },
    {
      codigoMCC: 4899,
      descricaoMCC: "TV a Cabo e outros tipos de TV Paga",
      codigoCnae: 6143400,
      descricaoCnae: "Operadoras de televisao por assinatura por satelite"
    },
    {
      codigoMCC: 5021,
      descricaoMCC: "Moveis Comerciais e para Escritorios",
      codigoCnae: 3103900,
      descricaoCnae: "Fabricaçao de moveis de outros materiais, exceto madeira e metal"
    },
    {
      codigoMCC: 4900,
      descricaoMCC: "Serviços Publicos (Agua, Luz, Gas e Telefone)",
      codigoCnae: 3702900,
      descricaoCnae: "Atividades relacionadas a esgoto, exceto a gestao de redes"
    },
    {
      codigoMCC: 5039,
      descricaoMCC: "Materiais de Construçao Sem Classificaçao",
      codigoCnae: 2391501,
      descricaoCnae: "Britamento de pedras, exceto associado a extraçao"
    },
    {
      codigoMCC: 5013,
      descricaoMCC: "Peças e Suprimentos para Veiculos",
      codigoCnae: 2949299,
      descricaoCnae: "Fabricaçao de outras peças e acessorios para veiculos automotores nao especificadas anteriormente"
    },
    {
      codigoMCC: 5013,
      descricaoMCC: "Peças e Suprimentos para Veiculos",
      codigoCnae: 4530706,
      descricaoCnae: "Representantes comerciais e agentes do comercio de peças e acessorios novos e usados para veiculos automotores"
    },
    {
      codigoMCC: 5039,
      descricaoMCC: "Materiais de Construçao Sem Classificaçao",
      codigoCnae: 2320600,
      descricaoCnae: "Fabricaçao de cimento"
    },
    {
      codigoMCC: 5039,
      descricaoMCC: "Materiais de Construçao Sem Classificaçao",
      codigoCnae: 4674500,
      descricaoCnae: "Comercio atacadista de cimento"
    },
    {
      codigoMCC: 5039,
      descricaoMCC: "Materiais de Construçao Sem Classificaçao",
      codigoCnae: 2311700,
      descricaoCnae: "Fabricaçao de vidro plano e de segurança"
    },
    {
      codigoMCC: 5039,
      descricaoMCC: "Materiais de Construçao Sem Classificaçao",
      codigoCnae: 2342701,
      descricaoCnae: "Fabricaçao de azulejos e pisos"
    },
    {
      codigoMCC: 4899,
      descricaoMCC: "TV a Cabo e outros tipos de TV Paga",
      codigoCnae: 6142600,
      descricaoCnae: "TV a Cabo e outros tipos de TV Paga"
    },
    {
      codigoMCC: 4900,
      descricaoMCC: "Serviços Publicos (Agua, Luz, Gas e Telefone)",
      codigoCnae: 3511501,
      descricaoCnae: "Serviços Publicos (Agua, Luz, Gas e Telefone)"
    },
    {
      codigoMCC: 4900,
      descricaoMCC: "Serviços Publicos (Agua, Luz, Gas e Telefone)",
      codigoCnae: 3511502,
      descricaoCnae: "Serviços Publicos (Agua, Luz, Gas e Telefone)"
    },
    {
      codigoMCC: 4900,
      descricaoMCC: "Serviços Publicos (Agua, Luz, Gas e Telefone)",
      codigoCnae: 3512300,
      descricaoCnae: "Serviços Publicos (Agua, Luz, Gas e Telefone)"
    },
    {
      codigoMCC: 4900,
      descricaoMCC: "Serviços Publicos (Agua, Luz, Gas e Telefone)",
      codigoCnae: 3513100,
      descricaoCnae: "Serviços Publicos (Agua, Luz, Gas e Telefone)"
    },
    {
      codigoMCC: 4900,
      descricaoMCC: "Serviços Publicos (Agua, Luz, Gas e Telefone)",
      codigoCnae: 3514000,
      descricaoCnae: "Serviços Publicos (Agua, Luz, Gas e Telefone)"
    },
    {
      codigoMCC: 4900,
      descricaoMCC: "Serviços Publicos (Agua, Luz, Gas e Telefone)",
      codigoCnae: 3520401,
      descricaoCnae: "Serviços Publicos (Agua, Luz, Gas e Telefone)"
    },
    {
      codigoMCC: 4900,
      descricaoMCC: "Serviços Publicos (Agua, Luz, Gas e Telefone)",
      codigoCnae: 3520402,
      descricaoCnae: "Serviços Publicos (Agua, Luz, Gas e Telefone)"
    },
    {
      codigoMCC: 4900,
      descricaoMCC: "Serviços Publicos (Agua, Luz, Gas e Telefone)",
      codigoCnae: 3600601,
      descricaoCnae: "Serviços Publicos (Agua, Luz, Gas e Telefone)"
    },
    {
      codigoMCC: 4900,
      descricaoMCC: "Serviços Publicos (Agua, Luz, Gas e Telefone)",
      codigoCnae: 3600602,
      descricaoCnae: "Serviços Publicos (Agua, Luz, Gas e Telefone)"
    },
    {
      codigoMCC: 4900,
      descricaoMCC: "Serviços Publicos (Agua, Luz, Gas e Telefone)",
      codigoCnae: 3811400,
      descricaoCnae: "Serviços Publicos (Agua, Luz, Gas e Telefone)"
    },
    {
      codigoMCC: 4900,
      descricaoMCC: "Serviços Publicos (Agua, Luz, Gas e Telefone)",
      codigoCnae: 3812200,
      descricaoCnae: "Serviços Publicos (Agua, Luz, Gas e Telefone)"
    },
    {
      codigoMCC: 4900,
      descricaoMCC: "Serviços Publicos (Agua, Luz, Gas e Telefone)",
      codigoCnae: 3821100,
      descricaoCnae: "Serviços Publicos (Agua, Luz, Gas e Telefone)"
    },
    {
      codigoMCC: 4900,
      descricaoMCC: "Serviços Publicos (Agua, Luz, Gas e Telefone)",
      codigoCnae: 3822000,
      descricaoCnae: "Serviços Publicos (Agua, Luz, Gas e Telefone)"
    },
    {
      codigoMCC: 4900,
      descricaoMCC: "Serviços Publicos (Agua, Luz, Gas e Telefone)",
      codigoCnae: 3900500,
      descricaoCnae: "Serviços Publicos (Agua, Luz, Gas e Telefone)"
    },
    {
      codigoMCC: 4900,
      descricaoMCC: "Serviços Publicos (Agua, Luz, Gas e Telefone)",
      codigoCnae: 8299701,
      descricaoCnae: "Serviços Publicos (Agua, Luz, Gas e Telefone)"
    },
    {
      codigoMCC: 4900,
      descricaoMCC: "Serviços Publicos (Agua, Luz, Gas e Telefone)",
      codigoCnae: 3511500,
      descricaoCnae: "Serviços Publicos (Agua, Luz, Gas e Telefone)"
    },
    {
      codigoMCC: 5013,
      descricaoMCC: "Peças e Suprimentos para Veiculos",
      codigoCnae: 2211100,
      descricaoCnae: "Peças e Suprimentos para Veiculos"
    },
    {
      codigoMCC: 5013,
      descricaoMCC: "Peças e Suprimentos para Veiculos",
      codigoCnae: 2910702,
      descricaoCnae: "Peças e Suprimentos para Veiculos"
    },
    {
      codigoMCC: 5013,
      descricaoMCC: "Peças e Suprimentos para Veiculos",
      codigoCnae: 2910703,
      descricaoCnae: "Peças e Suprimentos para Veiculos"
    },
    {
      codigoMCC: 5013,
      descricaoMCC: "Peças e Suprimentos para Veiculos",
      codigoCnae: 2920402,
      descricaoCnae: "Peças e Suprimentos para Veiculos"
    },
    {
      codigoMCC: 5013,
      descricaoMCC: "Peças e Suprimentos para Veiculos",
      codigoCnae: 2930102,
      descricaoCnae: "Peças e Suprimentos para Veiculos"
    },
    {
      codigoMCC: 5013,
      descricaoMCC: "Peças e Suprimentos para Veiculos",
      codigoCnae: 2941700,
      descricaoCnae: "Peças e Suprimentos para Veiculos"
    },
    {
      codigoMCC: 5013,
      descricaoMCC: "Peças e Suprimentos para Veiculos",
      codigoCnae: 2942500,
      descricaoCnae: "Peças e Suprimentos para Veiculos"
    },
    {
      codigoMCC: 5013,
      descricaoMCC: "Peças e Suprimentos para Veiculos",
      codigoCnae: 2943300,
      descricaoCnae: "Peças e Suprimentos para Veiculos"
    },
    {
      codigoMCC: 5013,
      descricaoMCC: "Peças e Suprimentos para Veiculos",
      codigoCnae: 2944100,
      descricaoCnae: "Peças e Suprimentos para Veiculos"
    },
    {
      codigoMCC: 5013,
      descricaoMCC: "Peças e Suprimentos para Veiculos",
      codigoCnae: 2949201,
      descricaoCnae: "Peças e Suprimentos para Veiculos"
    },
    {
      codigoMCC: 5021,
      descricaoMCC: "Moveis Comerciais e para Escritorios",
      codigoCnae: 3102100,
      descricaoCnae: "Moveis Comerciais e para Escritorios"
    },
    {
      codigoMCC: 5039,
      descricaoMCC: "Materiais de Construçao Sem Classificaçao",
      codigoCnae: 2221800,
      descricaoCnae: "Materiais de Construçao Sem Classificaçao"
    },
    {
      codigoMCC: 5039,
      descricaoMCC: "Materiais de Construçao Sem Classificaçao",
      codigoCnae: 2229303,
      descricaoCnae: "Materiais de Construçao Sem Classificaçao"
    },
    {
      codigoMCC: 5039,
      descricaoMCC: "Materiais de Construçao Sem Classificaçao",
      codigoCnae: 2312500,
      descricaoCnae: "Materiais de Construçao Sem Classificaçao"
    },
    {
      codigoMCC: 5039,
      descricaoMCC: "Materiais de Construçao Sem Classificaçao",
      codigoCnae: 2330303,
      descricaoCnae: "Materiais de Construçao Sem Classificaçao"
    },
    {
      codigoMCC: 5039,
      descricaoMCC: "Materiais de Construçao Sem Classificaçao",
      codigoCnae: 2330304,
      descricaoCnae: "Materiais de Construçao Sem Classificaçao"
    },
    {
      codigoMCC: 5039,
      descricaoMCC: "Materiais de Construçao Sem Classificaçao",
      codigoCnae: 2341900,
      descricaoCnae: "Materiais de Construçao Sem Classificaçao"
    },
    {
      codigoMCC: 5039,
      descricaoMCC: "Materiais de Construçao Sem Classificaçao",
      codigoCnae: 2349401,
      descricaoCnae: "Materiais de Construçao Sem Classificaçao"
    },
    {
      codigoMCC: 5039,
      descricaoMCC: "Materiais de Construçao Sem Classificaçao",
      codigoCnae: 2392300,
      descricaoCnae: "Materiais de Construçao Sem Classificaçao"
    },
    {
      codigoMCC: 5039,
      descricaoMCC: "Materiais de Construçao Sem Classificaçao",
      codigoCnae: 4613300,
      descricaoCnae: "Materiais de Construçao Sem Classificaçao"
    },
    {
      codigoMCC: 5044,
      descricaoMCC: "Revelaçao / Fotocopia e Microfilmagem",
      codigoCnae: 2099101,
      descricaoCnae: "Revelaçao / Fotocopia e Microfilmagem"
    },
    {
      codigoMCC: 5044,
      descricaoMCC: "Revelaçao / Fotocopia e Microfilmagem",
      codigoCnae: 2670102,
      descricaoCnae: "Revelaçao / Fotocopia e Microfilmagem"
    },
    {
      codigoMCC: 5044,
      descricaoMCC: "Revelaçao / Fotocopia e Microfilmagem",
      codigoCnae: 2680900,
      descricaoCnae: "Revelaçao / Fotocopia e Microfilmagem"
    },
    {
      codigoMCC: 5044,
      descricaoMCC: "Revelaçao / Fotocopia e Microfilmagem",
      codigoCnae: 2829101,
      descricaoCnae: "Revelaçao / Fotocopia e Microfilmagem"
    },
    {
      codigoMCC: 5045,
      descricaoMCC: "Equipamentos e Perifericos / Computadores",
      codigoCnae: 1830003,
      descricaoCnae: "Equipamentos e Perifericos / Computadores"
    },
    {
      codigoMCC: 5046,
      descricaoMCC: "Comercio de Equipamentos nao Classificados",
      codigoCnae: 2759799,
      descricaoCnae: "Comercio de Equipamentos nao Classificados"
    },
    {
      codigoMCC: 5046,
      descricaoMCC: "Comercio de Equipamentos nao Classificados",
      codigoCnae: 2815102,
      descricaoCnae: "Comercio de Equipamentos nao Classificados"
    },
    {
      codigoMCC: 5046,
      descricaoMCC: "Comercio de Equipamentos nao Classificados",
      codigoCnae: 2821601,
      descricaoCnae: "Comercio de Equipamentos nao Classificados"
    },
    {
      codigoMCC: 5046,
      descricaoMCC: "Comercio de Equipamentos nao Classificados",
      codigoCnae: 2821602,
      descricaoCnae: "Comercio de Equipamentos nao Classificados"
    },
    {
      codigoMCC: 5046,
      descricaoMCC: "Comercio de Equipamentos nao Classificados",
      codigoCnae: 2824101,
      descricaoCnae: "Comercio de Equipamentos nao Classificados"
    },
    {
      codigoMCC: 5046,
      descricaoMCC: "Comercio de Equipamentos nao Classificados",
      codigoCnae: 2824102,
      descricaoCnae: "Comercio de Equipamentos nao Classificados"
    },
    {
      codigoMCC: 5046,
      descricaoMCC: "Comercio de Equipamentos nao Classificados",
      codigoCnae: 2825900,
      descricaoCnae: "Comercio de Equipamentos nao Classificados"
    },
    {
      codigoMCC: 5046,
      descricaoMCC: "Comercio de Equipamentos nao Classificados",
      codigoCnae: 2851800,
      descricaoCnae: "Comercio de Equipamentos nao Classificados"
    },
    {
      codigoMCC: 5046,
      descricaoMCC: "Comercio de Equipamentos nao Classificados",
      codigoCnae: 2852600,
      descricaoCnae: "Comercio de Equipamentos nao Classificados"
    },
    {
      codigoMCC: 5046,
      descricaoMCC: "Comercio de Equipamentos nao Classificados",
      codigoCnae: 2862300,
      descricaoCnae: "Comercio de Equipamentos nao Classificados"
    },
    {
      codigoMCC: 5046,
      descricaoMCC: "Comercio de Equipamentos nao Classificados",
      codigoCnae: 2863100,
      descricaoCnae: "Comercio de Equipamentos nao Classificados"
    },
    {
      codigoMCC: 5046,
      descricaoMCC: "Comercio de Equipamentos nao Classificados",
      codigoCnae: 2864000,
      descricaoCnae: "Comercio de Equipamentos nao Classificados"
    },
    {
      codigoMCC: 5046,
      descricaoMCC: "Comercio de Equipamentos nao Classificados",
      codigoCnae: 2865800,
      descricaoCnae: "Comercio de Equipamentos nao Classificados"
    },
    {
      codigoMCC: 5046,
      descricaoMCC: "Comercio de Equipamentos nao Classificados",
      codigoCnae: 2866600,
      descricaoCnae: "Comercio de Equipamentos nao Classificados"
    },
    {
      codigoMCC: 5047,
      descricaoMCC: "Produtos / Suprimentos Medicos e Hospitalares",
      codigoCnae: 2660400,
      descricaoCnae: "Produtos / Suprimentos Medicos e Hospitalares"
    },
    {
      codigoMCC: 5047,
      descricaoMCC: "Produtos / Suprimentos Medicos e Hospitalares",
      codigoCnae: 4618402,
      descricaoCnae: "Produtos / Suprimentos Medicos e Hospitalares"
    },
    {
      codigoMCC: 5047,
      descricaoMCC: "Produtos / Suprimentos Medicos e Hospitalares",
      codigoCnae: 3250702,
      descricaoCnae: "Produtos / Suprimentos Medicos e Hospitalares"
    },
    {
      codigoMCC: 5047,
      descricaoMCC: "Produtos / Suprimentos Medicos e Hospitalares",
      codigoCnae: 3250703,
      descricaoCnae: "Produtos / Suprimentos Medicos e Hospitalares"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 710301,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 710302,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 721901,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 721902,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 722701,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 722702,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 723501,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 723502,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 725100,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 729401,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 729402,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 729403,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 729404,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 729405,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5094,
      descricaoMCC: "Relojoarias, Joalherias, Metais e Pedras Preciosas",
      codigoCnae: 4649410,
      descricaoCnae: "Comercio atacadista de joias, relogios e bijuterias, inclusive pedras preciosas e semipreciosas lapidadas"
    },
    {
      codigoMCC: 5099,
      descricaoMCC: "Materiais Duraveis nao Classificados",
      codigoCnae: 1629301,
      descricaoCnae: "Fabricaçao de artefatos diversos de madeira, exceto moveis"
    },
    {
      codigoMCC: 5094,
      descricaoMCC: "Relojoarias, Joalherias, Metais e Pedras Preciosas",
      codigoCnae: 3212400,
      descricaoCnae: "Fabricaçao de bijuterias e artefatos semelhantes"
    },
    {
      codigoMCC: 5094,
      descricaoMCC: "Relojoarias, Joalherias, Metais e Pedras Preciosas",
      codigoCnae: 2652300,
      descricaoCnae: "Fabricaçao de cronometros e relogios"
    },
    {
      codigoMCC: 5065,
      descricaoMCC: "Equipamentos e Peças Eletronicas",
      codigoCnae: 4673700,
      descricaoCnae: "Comercio atacadista de material eletrico"
    },
    {
      codigoMCC: 5085,
      descricaoMCC: "Suprimentos Industriais nao Classificados",
      codigoCnae: 4689399,
      descricaoCnae: "Comercio atacadista especializado em outros produtos intermediarios nao especificados anteriormente"
    },
    {
      codigoMCC: 5094,
      descricaoMCC: "Relojoarias, Joalherias, Metais e Pedras Preciosas",
      codigoCnae: 3211602,
      descricaoCnae: "Fabricaçao de artefatos de joalheria e ourivesaria"
    },
    {
      codigoMCC: 5099,
      descricaoMCC: "Materiais Duraveis nao Classificados",
      codigoCnae: 2229399,
      descricaoCnae: "Fabricaçao de artefatos de material plastico para outros usos nao especificados anteriormente"
    },
    {
      codigoMCC: 5085,
      descricaoMCC: "Suprimentos Industriais nao Classificados",
      codigoCnae: 4663000,
      descricaoCnae: "Comercio atacadista de Maquinas e equipamentos para uso industrial; partes e peças"
    },
    {
      codigoMCC: 5065,
      descricaoMCC: "Equipamentos e Peças Eletronicas",
      codigoCnae: 4652400,
      descricaoCnae: "Comercio atacadista de componentes eletronicos e equipamentos de telefonia e comunicaçao"
    },
    {
      codigoMCC: 5111,
      descricaoMCC: "Materiais de Escritorio",
      codigoCnae: 1733800,
      descricaoCnae: "Fabricaçao de chapas e de embalagens de papelao ondulado"
    },
    {
      codigoMCC: 5065,
      descricaoMCC: "Equipamentos e Peças Eletronicas",
      codigoCnae: 2610800,
      descricaoCnae: "Fabricaçao de componentes eletronicos"
    },
    {
      codigoMCC: 5065,
      descricaoMCC: "Equipamentos e Peças Eletronicas",
      codigoCnae: 2631100,
      descricaoCnae: "Fabricaçao de equipamentos transmissores de comunicaçao, peças e acessorios"
    },
    {
      codigoMCC: 5065,
      descricaoMCC: "Equipamentos e Peças Eletronicas",
      codigoCnae: 2732500,
      descricaoCnae: "Fabricaçao de material eletrico para instalaçoes em circuito de consumo"
    },
    {
      codigoMCC: 5065,
      descricaoMCC: "Equipamentos e Peças Eletronicas",
      codigoCnae: 2710403,
      descricaoCnae: "Fabricaçao de motores eletricos, peças e acessorios"
    },
    {
      codigoMCC: 5065,
      descricaoMCC: "Equipamentos e Peças Eletronicas",
      codigoCnae: 2790299,
      descricaoCnae: "Fabricaçao de outros equipamentos e aparelhos eletricos nao especificados anteriormente"
    },
    {
      codigoMCC: 5065,
      descricaoMCC: "Equipamentos e Peças Eletronicas",
      codigoCnae: 2710402,
      descricaoCnae: "Fabricaçao de transformadores, indutores, conversores, sincronizadores e semelhantes, peças e acessorios"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 4685100,
      descricaoCnae: "Comercio atacadista de produtos siderurgicos e metalurgicos, exceto para construçao"
    },
    {
      codigoMCC: 5072,
      descricaoMCC: "Suprimentos e Equipamentos, Maquinas e Ferramentas",
      codigoCnae: 4672900,
      descricaoCnae: "Comercio atacadista de ferragens e ferramentas"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 4687703,
      descricaoCnae: "Comercio atacadista de residuos e sucatas metalicos"
    },
    {
      codigoMCC: 5072,
      descricaoMCC: "Suprimentos e Equipamentos, Maquinas e Ferramentas",
      codigoCnae: 2542000,
      descricaoCnae: "Fabricaçao de artigos de serralheria, exceto esquadrias"
    },
    {
      codigoMCC: 5099,
      descricaoMCC: "Materiais Duraveis nao Classificados",
      codigoCnae: 4615000,
      descricaoCnae: "Representantes comerciais e agentes do comercio de eletrodomesticos, moveis e artigos de uso domestico"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 2451200,
      descricaoCnae: "Fundiçao de ferro e aço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 2452100,
      descricaoCnae: "Fundiçao de metais nao-ferrosos e suas ligas"
    },
    {
      codigoMCC: 5065,
      descricaoMCC: "Equipamentos e Peças Eletronicas",
      codigoCnae: 4649401,
      descricaoCnae: "Comercio atacadista de equipamentos eletricos de uso pessoal e domestico"
    },
    {
      codigoMCC: 5065,
      descricaoMCC: "Equipamentos e Peças Eletronicas",
      codigoCnae: 2731700,
      descricaoCnae: "Fabricaçao de aparelhos e equipamentos para distribuiçao e controle de energia eletrica"
    },
    {
      codigoMCC: 5065,
      descricaoMCC: "Equipamentos e Peças Eletronicas",
      codigoCnae: 2651500,
      descricaoCnae: "Fabricaçao de aparelhos e equipamentos de medida, teste e controle"
    },
    {
      codigoMCC: 5099,
      descricaoMCC: "Materiais Duraveis nao Classificados",
      codigoCnae: 4649405,
      descricaoCnae: "Comercio atacadista de artigos de tapeçaria; persianas e cortinas"
    },
    {
      codigoMCC: 5099,
      descricaoMCC: "Materiais Duraveis nao Classificados",
      codigoCnae: 4671100,
      descricaoCnae: "Comercio atacadista de madeira e produtos derivados"
    },
    {
      codigoMCC: 5085,
      descricaoMCC: "Suprimentos Industriais nao Classificados",
      codigoCnae: 4689301,
      descricaoCnae: "Comercio atacadista de produtos da extraçao mineral, exceto combustiveis"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 2539001,
      descricaoCnae: "Serviços de usinagem, torneiria e solda"
    },
    {
      codigoMCC: 5085,
      descricaoMCC: "Suprimentos Industriais nao Classificados",
      codigoCnae: 4687702,
      descricaoCnae: "Comercio atacadista de residuos e sucatas nao-metalicos, exceto de papel e papelao"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 2512800,
      descricaoCnae: "Fabricaçao de esquadrias de metal"
    },
    {
      codigoMCC: 5065,
      descricaoMCC: "Equipamentos e Peças Eletronicas",
      codigoCnae: 4649406,
      descricaoCnae: "Comercio atacadista de lustres, luminarias e abajures"
    },
    {
      codigoMCC: 5085,
      descricaoMCC: "Suprimentos Industriais nao Classificados",
      codigoCnae: 4686901,
      descricaoCnae: "Comercio atacadista de papel e papelao em bruto"
    },
    {
      codigoMCC: 5111,
      descricaoMCC: "Materiais de Escritorio",
      codigoCnae: 4647801,
      descricaoCnae: "Comercio atacadista de artigos de escritorio e de papelaria"
    },
    {
      codigoMCC: 5065,
      descricaoMCC: "Equipamentos e Peças Eletronicas",
      codigoCnae: 2632900,
      descricaoCnae: "Fabricaçao de aparelhos telefonicos e de outros equipamentos de comunicaçao, peças e acessorios"
    },
    {
      codigoMCC: 5099,
      descricaoMCC: "Materiais Duraveis nao Classificados",
      codigoCnae: 2391503,
      descricaoCnae: "Aparelhamento de placas e execuçao de trabalhos em marmore, granito, ardosia e outras pedras"
    },
    {
      codigoMCC: 5099,
      descricaoMCC: "Materiais Duraveis nao Classificados",
      codigoCnae: 1622699,
      descricaoCnae: "Fabricaçao de outros artigos de carpintaria para construçao"
    },
    {
      codigoMCC: 5099,
      descricaoMCC: "Materiais Duraveis nao Classificados",
      codigoCnae: 1622602,
      descricaoCnae: "Fabricaçao de esquadrias de madeira e de peças de madeira para instalaçoes industriais e comerciais"
    },
    {
      codigoMCC: 5111,
      descricaoMCC: "Materiais de Escritorio",
      codigoCnae: 1741902,
      descricaoCnae: "Fabricaçao de produtos de papel, cartolina, papel cartao e papelao ondulado para uso comercial e de escritorio, exceto formulario continuo"
    },
    {
      codigoMCC: 5099,
      descricaoMCC: "Materiais Duraveis nao Classificados",
      codigoCnae: 3092000,
      descricaoCnae: "Fabricaçao de bicicletas e triciclos nao-motorizados, peças e acessorios"
    },
    {
      codigoMCC: 5099,
      descricaoMCC: "Materiais Duraveis nao Classificados",
      codigoCnae: 4649402,
      descricaoCnae: "Comercio atacadista de aparelhos eletronicos de uso pessoal e domestico"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 2511000,
      descricaoCnae: "Fabricaçao de estruturas metalicas"
    },
    {
      codigoMCC: 5099,
      descricaoMCC: "Materiais Duraveis nao Classificados",
      codigoCnae: 2399199,
      descricaoCnae: "Fabricaçao de outros produtos de minerais nao-metalicos nao especificados anteriormente"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 2599301,
      descricaoCnae: "Serviços de confecçao de armaçoes metalicas para a construçao"
    },
    {
      codigoMCC: 5085,
      descricaoMCC: "Suprimentos Industriais nao Classificados",
      codigoCnae: 2229302,
      descricaoCnae: "Fabricaçao de artefatos de material plastico para usos industriais"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 2532202,
      descricaoCnae: "Metalurgia do po"
    },
    {
      codigoMCC: 5072,
      descricaoMCC: "Suprimentos e Equipamentos, Maquinas e Ferramentas",
      codigoCnae: 2543800,
      descricaoCnae: "Fabricaçao de ferramentas"
    },
    {
      codigoMCC: 5099,
      descricaoMCC: "Materiais Duraveis nao Classificados",
      codigoCnae: 2550102,
      descricaoCnae: "Fabricaçao de armas de fogo, outras armas e muniçoes"
    },
    {
      codigoMCC: 5085,
      descricaoMCC: "Suprimentos Industriais nao Classificados",
      codigoCnae: 2219600,
      descricaoCnae: "Fabricaçao de artefatos de borracha nao especificados anteriormente"
    },
    {
      codigoMCC: 5085,
      descricaoMCC: "Suprimentos Industriais nao Classificados",
      codigoCnae: 4669901,
      descricaoCnae: "Comercio atacadista de bombas e compressores; partes e peças"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 2539002,
      descricaoCnae: "Serviços de tratamento e revestimento em metais (Dispensada *)"
    },
    {
      codigoMCC: 5099,
      descricaoMCC: "Materiais Duraveis nao Classificados",
      codigoCnae: 4623101,
      descricaoCnae: "Comercio atacadista de animais vivos"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 990401,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 990402,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 2411300,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 2412100,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 2421100,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 2422901,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 2422902,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 2423701,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 2423702,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 2424501,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 2424502,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 2431800,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 2439300,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 2441501,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 2441502,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 2442300,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 2443100,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 2449101,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 2449102,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 2449103,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 2449199,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 2521700,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 2531401,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 2531402,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 2532201,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 2541100,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 2591800,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 2592601,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 2592602,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 2593400,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 2599302,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 2599399,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5051,
      descricaoMCC: "Escritorios e Centros de Serviço",
      codigoCnae: 2539000,
      descricaoCnae: "Escritorios e Centros de Serviço"
    },
    {
      codigoMCC: 5065,
      descricaoMCC: "Equipamentos e Peças Eletronicas",
      codigoCnae: 2640000,
      descricaoCnae: "Equipamentos e Peças Eletronicas"
    },
    {
      codigoMCC: 5065,
      descricaoMCC: "Equipamentos e Peças Eletronicas",
      codigoCnae: 2710401,
      descricaoCnae: "Equipamentos e Peças Eletronicas"
    },
    {
      codigoMCC: 5065,
      descricaoMCC: "Equipamentos e Peças Eletronicas",
      codigoCnae: 2721000,
      descricaoCnae: "Equipamentos e Peças Eletronicas"
    },
    {
      codigoMCC: 5065,
      descricaoMCC: "Equipamentos e Peças Eletronicas",
      codigoCnae: 2722801,
      descricaoCnae: "Equipamentos e Peças Eletronicas"
    },
    {
      codigoMCC: 5065,
      descricaoMCC: "Equipamentos e Peças Eletronicas",
      codigoCnae: 2733300,
      descricaoCnae: "Equipamentos e Peças Eletronicas"
    },
    {
      codigoMCC: 5065,
      descricaoMCC: "Equipamentos e Peças Eletronicas",
      codigoCnae: 2740601,
      descricaoCnae: "Equipamentos e Peças Eletronicas"
    },
    {
      codigoMCC: 5065,
      descricaoMCC: "Equipamentos e Peças Eletronicas",
      codigoCnae: 2740602,
      descricaoCnae: "Equipamentos e Peças Eletronicas"
    },
    {
      codigoMCC: 5065,
      descricaoMCC: "Equipamentos e Peças Eletronicas",
      codigoCnae: 2759701,
      descricaoCnae: "Equipamentos e Peças Eletronicas"
    },
    {
      codigoMCC: 5065,
      descricaoMCC: "Equipamentos e Peças Eletronicas",
      codigoCnae: 2790201,
      descricaoCnae: "Equipamentos e Peças Eletronicas"
    },
    {
      codigoMCC: 5065,
      descricaoMCC: "Equipamentos e Peças Eletronicas",
      codigoCnae: 2790202,
      descricaoCnae: "Equipamentos e Peças Eletronicas"
    },
    {
      codigoMCC: 5065,
      descricaoMCC: "Equipamentos e Peças Eletronicas",
      codigoCnae: 3299004,
      descricaoCnae: "Equipamentos e Peças Eletronicas"
    },
    {
      codigoMCC: 5085,
      descricaoMCC: "Suprimentos Industriais nao Classificados",
      codigoCnae: 1623400,
      descricaoCnae: "Suprimentos Industriais nao Classificados"
    },
    {
      codigoMCC: 5085,
      descricaoMCC: "Suprimentos Industriais nao Classificados",
      codigoCnae: 1629302,
      descricaoCnae: "Suprimentos Industriais nao Classificados"
    },
    {
      codigoMCC: 5085,
      descricaoMCC: "Suprimentos Industriais nao Classificados",
      codigoCnae: 2222600,
      descricaoCnae: "Suprimentos Industriais nao Classificados"
    },
    {
      codigoMCC: 5085,
      descricaoMCC: "Suprimentos Industriais nao Classificados",
      codigoCnae: 2522500,
      descricaoCnae: "Suprimentos Industriais nao Classificados"
    },
    {
      codigoMCC: 5085,
      descricaoMCC: "Suprimentos Industriais nao Classificados",
      codigoCnae: 2670101,
      descricaoCnae: "Suprimentos Industriais nao Classificados"
    },
    {
      codigoMCC: 5085,
      descricaoMCC: "Suprimentos Industriais nao Classificados",
      codigoCnae: 2812700,
      descricaoCnae: "Suprimentos Industriais nao Classificados"
    },
    {
      codigoMCC: 5085,
      descricaoMCC: "Suprimentos Industriais nao Classificados",
      codigoCnae: 2813500,
      descricaoCnae: "Suprimentos Industriais nao Classificados"
    },
    {
      codigoMCC: 5085,
      descricaoMCC: "Suprimentos Industriais nao Classificados",
      codigoCnae: 2814301,
      descricaoCnae: "Suprimentos Industriais nao Classificados"
    },
    {
      codigoMCC: 5085,
      descricaoMCC: "Suprimentos Industriais nao Classificados",
      codigoCnae: 2815101,
      descricaoCnae: "Suprimentos Industriais nao Classificados"
    },
    {
      codigoMCC: 5085,
      descricaoMCC: "Suprimentos Industriais nao Classificados",
      codigoCnae: 3091102,
      descricaoCnae: "Suprimentos Industriais nao Classificados"
    },
    {
      codigoMCC: 5085,
      descricaoMCC: "Suprimentos Industriais nao Classificados",
      codigoCnae: 1910100,
      descricaoCnae: "Suprimentos Industriais nao Classificados"
    },
    {
      codigoMCC: 5085,
      descricaoMCC: "Suprimentos Industriais nao Classificados",
      codigoCnae: 4687701,
      descricaoCnae: "Suprimentos Industriais nao Classificados"
    },
    {
      codigoMCC: 5094,
      descricaoMCC: "Relojoarias, Joalherias, Metais e Pedras Preciosas",
      codigoCnae: 724301,
      descricaoCnae: "Relojoarias, Joalherias, Metais e Pedras Preciosas"
    },
    {
      codigoMCC: 5094,
      descricaoMCC: "Relojoarias, Joalherias, Metais e Pedras Preciosas",
      codigoCnae: 724302,
      descricaoCnae: "Relojoarias, Joalherias, Metais e Pedras Preciosas"
    },
    {
      codigoMCC: 5094,
      descricaoMCC: "Relojoarias, Joalherias, Metais e Pedras Preciosas",
      codigoCnae: 893200,
      descricaoCnae: "Relojoarias, Joalherias, Metais e Pedras Preciosas"
    },
    {
      codigoMCC: 5094,
      descricaoMCC: "Relojoarias, Joalherias, Metais e Pedras Preciosas",
      codigoCnae: 3211601,
      descricaoCnae: "Relojoarias, Joalherias, Metais e Pedras Preciosas"
    },
    {
      codigoMCC: 5094,
      descricaoMCC: "Relojoarias, Joalherias, Metais e Pedras Preciosas",
      codigoCnae: 3211603,
      descricaoCnae: "Relojoarias, Joalherias, Metais e Pedras Preciosas"
    },
    {
      codigoMCC: 5099,
      descricaoMCC: "Materiais Duraveis nao Classificados",
      codigoCnae: 1621800,
      descricaoCnae: "Materiais Duraveis nao Classificados"
    },
    {
      codigoMCC: 5099,
      descricaoMCC: "Materiais Duraveis nao Classificados",
      codigoCnae: 2550101,
      descricaoCnae: "Materiais Duraveis nao Classificados"
    },
    {
      codigoMCC: 5099,
      descricaoMCC: "Materiais Duraveis nao Classificados",
      codigoCnae: 2811900,
      descricaoCnae: "Materiais Duraveis nao Classificados"
    },
    {
      codigoMCC: 5099,
      descricaoMCC: "Materiais Duraveis nao Classificados",
      codigoCnae: 2814302,
      descricaoCnae: "Materiais Duraveis nao Classificados"
    },
    {
      codigoMCC: 5099,
      descricaoMCC: "Materiais Duraveis nao Classificados",
      codigoCnae: 3011301,
      descricaoCnae: "Materiais Duraveis nao Classificados"
    },
    {
      codigoMCC: 5099,
      descricaoMCC: "Materiais Duraveis nao Classificados",
      codigoCnae: 3011302,
      descricaoCnae: "Materiais Duraveis nao Classificados"
    },
    {
      codigoMCC: 5099,
      descricaoMCC: "Materiais Duraveis nao Classificados",
      codigoCnae: 3012100,
      descricaoCnae: "Materiais Duraveis nao Classificados"
    },
    {
      codigoMCC: 5099,
      descricaoMCC: "Materiais Duraveis nao Classificados",
      codigoCnae: 3091101,
      descricaoCnae: "Materiais Duraveis nao Classificados"
    },
    {
      codigoMCC: 5099,
      descricaoMCC: "Materiais Duraveis nao Classificados",
      codigoCnae: 3220500,
      descricaoCnae: "Materiais Duraveis nao Classificados"
    },
    {
      codigoMCC: 5099,
      descricaoMCC: "Materiais Duraveis nao Classificados",
      codigoCnae: 3240001,
      descricaoCnae: "Materiais Duraveis nao Classificados"
    },
    {
      codigoMCC: 5099,
      descricaoMCC: "Materiais Duraveis nao Classificados",
      codigoCnae: 3240002,
      descricaoCnae: "Materiais Duraveis nao Classificados"
    },
    {
      codigoMCC: 5099,
      descricaoMCC: "Materiais Duraveis nao Classificados",
      codigoCnae: 3240003,
      descricaoCnae: "Materiais Duraveis nao Classificados"
    },
    {
      codigoMCC: 5099,
      descricaoMCC: "Materiais Duraveis nao Classificados",
      codigoCnae: 3240099,
      descricaoCnae: "Materiais Duraveis nao Classificados"
    },
    {
      codigoMCC: 5111,
      descricaoMCC: "Materiais de Escritorio",
      codigoCnae: 1710900,
      descricaoCnae: "Materiais de Escritorio"
    },
    {
      codigoMCC: 5111,
      descricaoMCC: "Materiais de Escritorio",
      codigoCnae: 1721400,
      descricaoCnae: "Materiais de Escritorio"
    },
    {
      codigoMCC: 5111,
      descricaoMCC: "Materiais de Escritorio",
      codigoCnae: 1722200,
      descricaoCnae: "Materiais de Escritorio"
    },
    {
      codigoMCC: 5111,
      descricaoMCC: "Materiais de Escritorio",
      codigoCnae: 1741901,
      descricaoCnae: "Materiais de Escritorio"
    },
    {
      codigoMCC: 5111,
      descricaoMCC: "Materiais de Escritorio",
      codigoCnae: 1749400,
      descricaoCnae: "Materiais de Escritorio"
    },
    {
      codigoMCC: 5111,
      descricaoMCC: "Materiais de Escritorio",
      codigoCnae: 3299002,
      descricaoCnae: "Materiais de Escritorio"
    },
    {
      codigoMCC: 5111,
      descricaoMCC: "Materiais de Escritorio",
      codigoCnae: 4686902,
      descricaoCnae: "Materiais de Escritorio"
    },
    {
      codigoMCC: 5122,
      descricaoMCC: "Farmacias, Farmaceuticos, Distribuidores de Medica",
      codigoCnae: 1742701,
      descricaoCnae: "Farmacias, Farmaceuticos, Distribuidores de Medica"
    },
    {
      codigoMCC: 5122,
      descricaoMCC: "Farmacias, Farmaceuticos, Distribuidores de Medica",
      codigoCnae: 1742702,
      descricaoCnae: "Farmacias, Farmaceuticos, Distribuidores de Medica"
    },
    {
      codigoMCC: 5137,
      descricaoMCC: "Comercio de Roupas e Uniformes Adulto e Infantil",
      codigoCnae: 1411801,
      descricaoCnae: "Confecçao de roupas intimas"
    },
    {
      codigoMCC: 5137,
      descricaoMCC: "Comercio de Roupas e Uniformes Adulto e Infantil",
      codigoCnae: 1411802,
      descricaoCnae: "Facçao de roupas intimas"
    },
    {
      codigoMCC: 5122,
      descricaoMCC: "Farmacias, Farmaceuticos, Distribuidores de Medica",
      codigoCnae: 4646001,
      descricaoCnae: "Comercio atacadista de cosmeticos e produtos de perfumaria"
    },
    {
      codigoMCC: 5137,
      descricaoMCC: "Comercio de Roupas e Uniformes Adulto e Infantil",
      codigoCnae: 4642701,
      descricaoCnae: "Comercio atacadista de artigos do vestuario e acessorios, exceto profissionais e de segurança"
    },
    {
      codigoMCC: 5139,
      descricaoMCC: "Sapataria",
      codigoCnae: 4643501,
      descricaoCnae: "Comercio atacadista de calçados"
    },
    {
      codigoMCC: 5122,
      descricaoMCC: "Farmacias, Farmaceuticos, Distribuidores de Medica",
      codigoCnae: 4646002,
      descricaoCnae: "Comercio atacadista de produtos de higiene pessoal"
    },
    {
      codigoMCC: 5137,
      descricaoMCC: "Comercio de Roupas e Uniformes Adulto e Infantil",
      codigoCnae: 1412601,
      descricaoCnae: "Confecçao de peças de vestuario, exceto roupas intimas e as confeccionadas sob medida"
    },
    {
      codigoMCC: 5137,
      descricaoMCC: "Comercio de Roupas e Uniformes Adulto e Infantil",
      codigoCnae: 1413402,
      descricaoCnae: "Confecçao, sob medida, de roupas profissionais"
    },
    {
      codigoMCC: 5137,
      descricaoMCC: "Comercio de Roupas e Uniformes Adulto e Infantil",
      codigoCnae: 1412603,
      descricaoCnae: "Facçao de peças do vestuario, exceto roupas intimas"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1092900,
      descricaoCnae: "Fabrica"
    },
    {
      codigoMCC: 5131,
      descricaoMCC: "Atacadistas e Distribuidores de Tecidos e Produtos",
      codigoCnae: 4641901,
      descricaoCnae: "Comercio atacadista de tecidos"
    },
    {
      codigoMCC: 5139,
      descricaoMCC: "Sapataria",
      codigoCnae: 1531901,
      descricaoCnae: "Fabricaçao de calçados de couro"
    },
    {
      codigoMCC: 5139,
      descricaoMCC: "Sapataria",
      codigoCnae: 1539400,
      descricaoCnae: "Fabricaçao de calçados de materiais nao especificados anteriormente"
    },
    {
      codigoMCC: 5139,
      descricaoMCC: "Sapataria",
      codigoCnae: 1531902,
      descricaoCnae: "Acabamento de calçados de couro sob contrato"
    },
    {
      codigoMCC: 5139,
      descricaoMCC: "Sapataria",
      codigoCnae: 1540800,
      descricaoCnae: "Fabricaçao de partes para calçados, de qualquer material"
    },
    {
      codigoMCC: 5131,
      descricaoMCC: "Atacadistas e Distribuidores de Tecidos e Produtos",
      codigoCnae: 1351100,
      descricaoCnae: "Fabricaçao de artefatos texteis para uso domestico"
    },
    {
      codigoMCC: 5137,
      descricaoMCC: "Comercio de Roupas e Uniformes Adulto e Infantil",
      codigoCnae: 1414200,
      descricaoCnae: "Fabricaçao de acessorios do vestuario, exceto para segurança e proteçao"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1065101,
      descricaoCnae: "Fabricaçao de amidos e feculas de vegetais"
    },
    {
      codigoMCC: 5139,
      descricaoMCC: "Sapataria",
      codigoCnae: 1533500,
      descricaoCnae: "Fabricaçao de calçados de material sintetico"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1031700,
      descricaoCnae: "Fabricaçao de conservas de frutas"
    },
    {
      codigoMCC: 5122,
      descricaoMCC: "Farmacias, Farmaceuticos, Distribuidores de Medica",
      codigoCnae: 2063100,
      descricaoCnae: "Fabricaçao de cosmeticos, produtos de perfumaria e de higiene pessoal"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1052000,
      descricaoCnae: "Fabricaçao de laticinios"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1033302,
      descricaoCnae: "Fabricaçao de sucos de frutas, hortaliças e legumes, exceto concentrados"
    },
    {
      codigoMCC: 5139,
      descricaoMCC: "Sapataria",
      codigoCnae: 1532700,
      descricaoCnae: "Fabricaçao de tenis de qualquer material"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1062700,
      descricaoCnae: "Moagem de trigo e fabricaçao de derivados"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1069400,
      descricaoCnae: "Moagem e fabricaçao de produtos de origem vegetal nao especificados anteriormente"
    },
    {
      codigoMCC: 5131,
      descricaoMCC: "Atacadistas e Distribuidores de Tecidos e Produtos",
      codigoCnae: 1330800,
      descricaoCnae: "Fabricaçao de tecidos de malha"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1061902,
      descricaoCnae: "Fabricaçao de produtos do arroz"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1013901,
      descricaoCnae: "Fabricaçao de produtos de carne"
    },
    {
      codigoMCC: 5137,
      descricaoMCC: "Comercio de Roupas e Uniformes Adulto e Infantil",
      codigoCnae: 1413401,
      descricaoCnae: "Confecçao de roupas profissionais, exceto sob medida"
    },
    {
      codigoMCC: 5137,
      descricaoMCC: "Comercio de Roupas e Uniformes Adulto e Infantil",
      codigoCnae: 1413403,
      descricaoCnae: "Facçao de roupas profissionais"
    },
    {
      codigoMCC: 5131,
      descricaoMCC: "Atacadistas e Distribuidores de Tecidos e Produtos",
      codigoCnae: 4689302,
      descricaoCnae: "Comercio atacadista de fios e fibras beneficiados"
    },
    {
      codigoMCC: 5192,
      descricaoMCC: "Livros, Periodicos e Jornais",
      codigoCnae: 6391700,
      descricaoCnae: "Agencias de noticias"
    },
    {
      codigoMCC: 5137,
      descricaoMCC: "Comercio de Roupas e Uniformes Adulto e Infantil",
      codigoCnae: 4642702,
      descricaoCnae: "Comercio atacadista de roupas e acessorios para uso profissional e de segurança do trabalho"
    },
    {
      codigoMCC: 5122,
      descricaoMCC: "Farmacias, Farmaceuticos, Distribuidores de Medica",
      codigoCnae: 1742799,
      descricaoCnae: "Fabricaçao de produtos de papel para uso domestico e higienico-sanitario nao especificados anteriormente"
    },
    {
      codigoMCC: 5131,
      descricaoMCC: "Atacadistas e Distribuidores de Tecidos e Produtos",
      codigoCnae: 1340501,
      descricaoCnae: "Estamparia e texturizaçao em fios, tecidos, artefatos texteis e peças do vestuario"
    },
    {
      codigoMCC: 5122,
      descricaoMCC: "Farmacias, Farmaceuticos, Distribuidores de Medica",
      codigoCnae: 4618401,
      descricaoCnae: "Representantes comerciais e agentes do comercio de medicamentos, cosmeticos e produtos de perfumaria"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1066000,
      descricaoCnae: "Fabricaçao de alimentos para animais"
    },
    {
      codigoMCC: 5169,
      descricaoMCC: "Produtos Quimicos e Relacionados",
      codigoCnae: 4684201,
      descricaoCnae: "Comercio atacadista de resinas e elastomeros"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1033301,
      descricaoCnae: "Fabricaçao de sucos concentrados de frutas, hortaliças e legumes"
    },
    {
      codigoMCC: 5172,
      descricaoMCC: "Petroleo e Derivados",
      codigoCnae: 4681805,
      descricaoCnae: "Comercio atacadista de lubrificantes"
    },
    {
      codigoMCC: 5172,
      descricaoMCC: "Petroleo e Derivados",
      codigoCnae: 4684299,
      descricaoCnae: "Comercio atacadista de outros produtos quimicos e petroquimicos nao especificados anteriormente"
    },
    {
      codigoMCC: 5193,
      descricaoMCC: "Suprimentos para Floristas",
      codigoCnae: 4623106,
      descricaoCnae: "Comercio atacadista de sementes, flores, plantas e gramas"
    },
    {
      codigoMCC: 5172,
      descricaoMCC: "Petroleo e Derivados",
      codigoCnae: 4681802,
      descricaoCnae: "Comercio atacadista de combustiveis realizado por transportador retalhista (T.R.R.)"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1020102,
      descricaoCnae: "Fabricaçao de conservas de peixes, crustaceos e moluscos"
    },
    {
      codigoMCC: 5131,
      descricaoMCC: "Atacadistas e Distribuidores de Tecidos e Produtos",
      codigoCnae: 1359600,
      descricaoCnae: "Fabricaçao de outros produtos texteis nao especificados anteriormente"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1051100,
      descricaoCnae: "Preparaçao do leite"
    },
    {
      codigoMCC: 5122,
      descricaoMCC: "Farmacias, Farmaceuticos, Distribuidores de Medica",
      codigoCnae: 4644302,
      descricaoCnae: "Comercio atacadista de medicamentos e drogas de uso veterinario"
    },
    {
      codigoMCC: 5122,
      descricaoMCC: "Farmacias, Farmaceuticos, Distribuidores de Medica",
      codigoCnae: 2110600,
      descricaoCnae: "Farmacias, Farmaceuticos, Distribuidores de Medica"
    },
    {
      codigoMCC: 5122,
      descricaoMCC: "Farmacias, Farmaceuticos, Distribuidores de Medica",
      codigoCnae: 2121101,
      descricaoCnae: "Farmacias, Farmaceuticos, Distribuidores de Medica"
    },
    {
      codigoMCC: 5122,
      descricaoMCC: "Farmacias, Farmaceuticos, Distribuidores de Medica",
      codigoCnae: 2121102,
      descricaoCnae: "Farmacias, Farmaceuticos, Distribuidores de Medica"
    },
    {
      codigoMCC: 5122,
      descricaoMCC: "Farmacias, Farmaceuticos, Distribuidores de Medica",
      codigoCnae: 2121103,
      descricaoCnae: "Farmacias, Farmaceuticos, Distribuidores de Medica"
    },
    {
      codigoMCC: 5122,
      descricaoMCC: "Farmacias, Farmaceuticos, Distribuidores de Medica",
      codigoCnae: 2122000,
      descricaoCnae: "Farmacias, Farmaceuticos, Distribuidores de Medica"
    },
    {
      codigoMCC: 5122,
      descricaoMCC: "Farmacias, Farmaceuticos, Distribuidores de Medica",
      codigoCnae: 2123800,
      descricaoCnae: "Farmacias, Farmaceuticos, Distribuidores de Medica"
    },
    {
      codigoMCC: 5122,
      descricaoMCC: "Farmacias, Farmaceuticos, Distribuidores de Medica",
      codigoCnae: 4644301,
      descricaoCnae: "Farmacias, Farmaceuticos, Distribuidores de Medica"
    },
    {
      codigoMCC: 5131,
      descricaoMCC: "Atacadistas e Distribuidores de Tecidos e Produtos",
      codigoCnae: 1311100,
      descricaoCnae: "Atacadistas e Distribuidores de Tecidos e Produtos"
    },
    {
      codigoMCC: 5131,
      descricaoMCC: "Atacadistas e Distribuidores de Tecidos e Produtos",
      codigoCnae: 1312000,
      descricaoCnae: "Atacadistas e Distribuidores de Tecidos e Produtos"
    },
    {
      codigoMCC: 5131,
      descricaoMCC: "Atacadistas e Distribuidores de Tecidos e Produtos",
      codigoCnae: 1313800,
      descricaoCnae: "Atacadistas e Distribuidores de Tecidos e Produtos"
    },
    {
      codigoMCC: 5131,
      descricaoMCC: "Atacadistas e Distribuidores de Tecidos e Produtos",
      codigoCnae: 1314600,
      descricaoCnae: "Atacadistas e Distribuidores de Tecidos e Produtos"
    },
    {
      codigoMCC: 5131,
      descricaoMCC: "Atacadistas e Distribuidores de Tecidos e Produtos",
      codigoCnae: 1321900,
      descricaoCnae: "Atacadistas e Distribuidores de Tecidos e Produtos"
    },
    {
      codigoMCC: 5131,
      descricaoMCC: "Atacadistas e Distribuidores de Tecidos e Produtos",
      codigoCnae: 1322700,
      descricaoCnae: "Atacadistas e Distribuidores de Tecidos e Produtos"
    },
    {
      codigoMCC: 5131,
      descricaoMCC: "Atacadistas e Distribuidores de Tecidos e Produtos",
      codigoCnae: 1323500,
      descricaoCnae: "Atacadistas e Distribuidores de Tecidos e Produtos"
    },
    {
      codigoMCC: 5131,
      descricaoMCC: "Atacadistas e Distribuidores de Tecidos e Produtos",
      codigoCnae: 1340502,
      descricaoCnae: "Atacadistas e Distribuidores de Tecidos e Produtos"
    },
    {
      codigoMCC: 5131,
      descricaoMCC: "Atacadistas e Distribuidores de Tecidos e Produtos",
      codigoCnae: 1352900,
      descricaoCnae: "Atacadistas e Distribuidores de Tecidos e Produtos"
    },
    {
      codigoMCC: 5131,
      descricaoMCC: "Atacadistas e Distribuidores de Tecidos e Produtos",
      codigoCnae: 1353700,
      descricaoCnae: "Atacadistas e Distribuidores de Tecidos e Produtos"
    },
    {
      codigoMCC: 5131,
      descricaoMCC: "Atacadistas e Distribuidores de Tecidos e Produtos",
      codigoCnae: 1354500,
      descricaoCnae: "Atacadistas e Distribuidores de Tecidos e Produtos"
    },
    {
      codigoMCC: 5131,
      descricaoMCC: "Atacadistas e Distribuidores de Tecidos e Produtos",
      codigoCnae: 3250708,
      descricaoCnae: "Atacadistas e Distribuidores de Tecidos e Produtos"
    },
    {
      codigoMCC: 5131,
      descricaoMCC: "Atacadistas e Distribuidores de Tecidos e Produtos",
      codigoCnae: 3299005,
      descricaoCnae: "Atacadistas e Distribuidores de Tecidos e Produtos"
    },
    {
      codigoMCC: 5131,
      descricaoMCC: "Atacadistas e Distribuidores de Tecidos e Produtos",
      codigoCnae: 4641903,
      descricaoCnae: "Atacadistas e Distribuidores de Tecidos e Produtos"
    },
    {
      codigoMCC: 5137,
      descricaoMCC: "Comercio de Roupas e Uniformes Adulto e Infantil",
      codigoCnae: 1421500,
      descricaoCnae: "Comercio de Roupas e Uniformes Adulto e Infantil"
    },
    {
      codigoMCC: 5137,
      descricaoMCC: "Comercio de Roupas e Uniformes Adulto e Infantil",
      codigoCnae: 3292201,
      descricaoCnae: "Comercio de Roupas e Uniformes Adulto e Infantil"
    },
    {
      codigoMCC: 5169,
      descricaoMCC: "Produtos Quimicos e Relacionados",
      codigoCnae: 210108,
      descricaoCnae: "Produtos Quimicos e Relacionados"
    },
    {
      codigoMCC: 5169,
      descricaoMCC: "Produtos Quimicos e Relacionados",
      codigoCnae: 220902,
      descricaoCnae: "Produtos Quimicos e Relacionados"
    },
    {
      codigoMCC: 5169,
      descricaoMCC: "Produtos Quimicos e Relacionados",
      codigoCnae: 500301,
      descricaoCnae: "Produtos Quimicos e Relacionados"
    },
    {
      codigoMCC: 5169,
      descricaoMCC: "Produtos Quimicos e Relacionados",
      codigoCnae: 891600,
      descricaoCnae: "Produtos Quimicos e Relacionados"
    },
    {
      codigoMCC: 5169,
      descricaoMCC: "Produtos Quimicos e Relacionados",
      codigoCnae: 892401,
      descricaoCnae: "Produtos Quimicos e Relacionados"
    },
    {
      codigoMCC: 5169,
      descricaoMCC: "Produtos Quimicos e Relacionados",
      codigoCnae: 892402,
      descricaoCnae: "Produtos Quimicos e Relacionados"
    },
    {
      codigoMCC: 5169,
      descricaoMCC: "Produtos Quimicos e Relacionados",
      codigoCnae: 899101,
      descricaoCnae: "Produtos Quimicos e Relacionados"
    },
    {
      codigoMCC: 5169,
      descricaoMCC: "Produtos Quimicos e Relacionados",
      codigoCnae: 899102,
      descricaoCnae: "Produtos Quimicos e Relacionados"
    },
    {
      codigoMCC: 5169,
      descricaoMCC: "Produtos Quimicos e Relacionados",
      codigoCnae: 899103,
      descricaoCnae: "Produtos Quimicos e Relacionados"
    },
    {
      codigoMCC: 5169,
      descricaoMCC: "Produtos Quimicos e Relacionados",
      codigoCnae: 1931400,
      descricaoCnae: "Produtos Quimicos e Relacionados"
    },
    {
      codigoMCC: 5169,
      descricaoMCC: "Produtos Quimicos e Relacionados",
      codigoCnae: 2011800,
      descricaoCnae: "Produtos Quimicos e Relacionados"
    },
    {
      codigoMCC: 5169,
      descricaoMCC: "Produtos Quimicos e Relacionados",
      codigoCnae: 2012600,
      descricaoCnae: "Produtos Quimicos e Relacionados"
    },
    {
      codigoMCC: 5169,
      descricaoMCC: "Produtos Quimicos e Relacionados",
      codigoCnae: 2013401,
      descricaoCnae: "Produtos Quimicos e Relacionados"
    },
    {
      codigoMCC: 5169,
      descricaoMCC: "Produtos Quimicos e Relacionados",
      codigoCnae: 2013402,
      descricaoCnae: "Produtos Quimicos e Relacionados"
    },
    {
      codigoMCC: 5169,
      descricaoMCC: "Produtos Quimicos e Relacionados",
      codigoCnae: 2014200,
      descricaoCnae: "Produtos Quimicos e Relacionados"
    },
    {
      codigoMCC: 5169,
      descricaoMCC: "Produtos Quimicos e Relacionados",
      codigoCnae: 2019301,
      descricaoCnae: "Produtos Quimicos e Relacionados"
    },
    {
      codigoMCC: 5169,
      descricaoMCC: "Produtos Quimicos e Relacionados",
      codigoCnae: 2019399,
      descricaoCnae: "Produtos Quimicos e Relacionados"
    },
    {
      codigoMCC: 5169,
      descricaoMCC: "Produtos Quimicos e Relacionados",
      codigoCnae: 2021500,
      descricaoCnae: "Produtos Quimicos e Relacionados"
    },
    {
      codigoMCC: 5169,
      descricaoMCC: "Produtos Quimicos e Relacionados",
      codigoCnae: 2022300,
      descricaoCnae: "Produtos Quimicos e Relacionados"
    },
    {
      codigoMCC: 5169,
      descricaoMCC: "Produtos Quimicos e Relacionados",
      codigoCnae: 2029100,
      descricaoCnae: "Produtos Quimicos e Relacionados"
    },
    {
      codigoMCC: 5169,
      descricaoMCC: "Produtos Quimicos e Relacionados",
      codigoCnae: 2031200,
      descricaoCnae: "Produtos Quimicos e Relacionados"
    },
    {
      codigoMCC: 5169,
      descricaoMCC: "Produtos Quimicos e Relacionados",
      codigoCnae: 2032100,
      descricaoCnae: "Produtos Quimicos e Relacionados"
    },
    {
      codigoMCC: 5169,
      descricaoMCC: "Produtos Quimicos e Relacionados",
      codigoCnae: 2033900,
      descricaoCnae: "Produtos Quimicos e Relacionados"
    },
    {
      codigoMCC: 5169,
      descricaoMCC: "Produtos Quimicos e Relacionados",
      codigoCnae: 2040100,
      descricaoCnae: "Produtos Quimicos e Relacionados"
    },
    {
      codigoMCC: 5169,
      descricaoMCC: "Produtos Quimicos e Relacionados",
      codigoCnae: 2051700,
      descricaoCnae: "Produtos Quimicos e Relacionados"
    },
    {
      codigoMCC: 5169,
      descricaoMCC: "Produtos Quimicos e Relacionados",
      codigoCnae: 2073800,
      descricaoCnae: "Produtos Quimicos e Relacionados"
    },
    {
      codigoMCC: 5169,
      descricaoMCC: "Produtos Quimicos e Relacionados",
      codigoCnae: 2091600,
      descricaoCnae: "Produtos Quimicos e Relacionados"
    },
    {
      codigoMCC: 5169,
      descricaoMCC: "Produtos Quimicos e Relacionados",
      codigoCnae: 2092401,
      descricaoCnae: "Produtos Quimicos e Relacionados"
    },
    {
      codigoMCC: 5169,
      descricaoMCC: "Produtos Quimicos e Relacionados",
      codigoCnae: 2092402,
      descricaoCnae: "Produtos Quimicos e Relacionados"
    },
    {
      codigoMCC: 5169,
      descricaoMCC: "Produtos Quimicos e Relacionados",
      codigoCnae: 2092403,
      descricaoCnae: "Produtos Quimicos e Relacionados"
    },
    {
      codigoMCC: 5169,
      descricaoMCC: "Produtos Quimicos e Relacionados",
      codigoCnae: 2093200,
      descricaoCnae: "Produtos Quimicos e Relacionados"
    },
    {
      codigoMCC: 5169,
      descricaoMCC: "Produtos Quimicos e Relacionados",
      codigoCnae: 2094100,
      descricaoCnae: "Produtos Quimicos e Relacionados"
    },
    {
      codigoMCC: 5169,
      descricaoMCC: "Produtos Quimicos e Relacionados",
      codigoCnae: 2099199,
      descricaoCnae: "Produtos Quimicos e Relacionados"
    },
    {
      codigoMCC: 5169,
      descricaoMCC: "Produtos Quimicos e Relacionados",
      codigoCnae: 2399102,
      descricaoCnae: "Produtos Quimicos e Relacionados"
    },
    {
      codigoMCC: 5169,
      descricaoMCC: "Produtos Quimicos e Relacionados",
      codigoCnae: 4612500,
      descricaoCnae: "Produtos Quimicos e Relacionados"
    },
    {
      codigoMCC: 5169,
      descricaoMCC: "Produtos Quimicos e Relacionados",
      codigoCnae: 4684202,
      descricaoCnae: "Produtos Quimicos e Relacionados"
    },
    {
      codigoMCC: 5169,
      descricaoMCC: "Produtos Quimicos e Relacionados",
      codigoCnae: 2013400,
      descricaoCnae: "Produtos Quimicos e Relacionados"
    },
    {
      codigoMCC: 5172,
      descricaoMCC: "Petroleo e Derivados",
      codigoCnae: 600001,
      descricaoCnae: "Petroleo e Derivados"
    },
    {
      codigoMCC: 5172,
      descricaoMCC: "Petroleo e Derivados",
      codigoCnae: 600002,
      descricaoCnae: "Petroleo e Derivados"
    },
    {
      codigoMCC: 5172,
      descricaoMCC: "Petroleo e Derivados",
      codigoCnae: 600003,
      descricaoCnae: "Petroleo e Derivados"
    },
    {
      codigoMCC: 5172,
      descricaoMCC: "Petroleo e Derivados",
      codigoCnae: 910600,
      descricaoCnae: "Petroleo e Derivados"
    },
    {
      codigoMCC: 5172,
      descricaoMCC: "Petroleo e Derivados",
      codigoCnae: 1921700,
      descricaoCnae: "Petroleo e Derivados"
    },
    {
      codigoMCC: 5172,
      descricaoMCC: "Petroleo e Derivados",
      codigoCnae: 1922501,
      descricaoCnae: "Petroleo e Derivados"
    },
    {
      codigoMCC: 5172,
      descricaoMCC: "Petroleo e Derivados",
      codigoCnae: 1922502,
      descricaoCnae: "Petroleo e Derivados"
    },
    {
      codigoMCC: 5172,
      descricaoMCC: "Petroleo e Derivados",
      codigoCnae: 1922599,
      descricaoCnae: "Petroleo e Derivados"
    },
    {
      codigoMCC: 5172,
      descricaoMCC: "Petroleo e Derivados",
      codigoCnae: 1932200,
      descricaoCnae: "Petroleo e Derivados"
    },
    {
      codigoMCC: 5172,
      descricaoMCC: "Petroleo e Derivados",
      codigoCnae: 4681801,
      descricaoCnae: "Petroleo e Derivados"
    },
    {
      codigoMCC: 5172,
      descricaoMCC: "Petroleo e Derivados",
      codigoCnae: 4681803,
      descricaoCnae: "Petroleo e Derivados"
    },
    {
      codigoMCC: 5172,
      descricaoMCC: "Petroleo e Derivados",
      codigoCnae: 4681804,
      descricaoCnae: "Petroleo e Derivados"
    },
    {
      codigoMCC: 5172,
      descricaoMCC: "Petroleo e Derivados",
      codigoCnae: 4682600,
      descricaoCnae: "Petroleo e Derivados"
    },
    {
      codigoMCC: 5192,
      descricaoMCC: "Livros, Periodicos e Jornais",
      codigoCnae: 4618403,
      descricaoCnae: "Livros, Periodicos e Jornais"
    },
    {
      codigoMCC: 5192,
      descricaoMCC: "Livros, Periodicos e Jornais",
      codigoCnae: 4647802,
      descricaoCnae: "Livros, Periodicos e Jornais"
    },
    {
      codigoMCC: 5198,
      descricaoMCC: "Tintas, Vernizes e Suprimentos",
      codigoCnae: 2071100,
      descricaoCnae: "Tintas, Vernizes e Suprimentos"
    },
    {
      codigoMCC: 5198,
      descricaoMCC: "Tintas, Vernizes e Suprimentos",
      codigoCnae: 2072000,
      descricaoCnae: "Tintas, Vernizes e Suprimentos"
    },
    {
      codigoMCC: 5198,
      descricaoMCC: "Tintas, Vernizes e Suprimentos",
      codigoCnae: 4679601,
      descricaoCnae: "Tintas, Vernizes e Suprimentos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1032501,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1032599,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1041400,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1042200,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1043100,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1053800,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1063500,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1064300,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1065102,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1065103,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1071600,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1072401,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1072402,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1082100,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5651,
      descricaoMCC: "Lojas de Roupas Masculinas, Femininas e Infantis",
      codigoCnae: 4781400,
      descricaoCnae: "Comercio varejista de artigos do vestuario e acessorios"
    },
    {
      codigoMCC: 5300,
      descricaoMCC: "Atacadista",
      codigoCnae: 4693100,
      descricaoCnae: "Comercio atacadista de mercadorias em geral, sem predominancia de alimentos ou de insumos agropecuarios"
    },
    {
      codigoMCC: 5661,
      descricaoMCC: "Lojas de Sapatos",
      codigoCnae: 4782201,
      descricaoCnae: "Comercio varejista de calçados"
    },
    {
      codigoMCC: 5499,
      descricaoMCC: "Casas de Carnes",
      codigoCnae: 4729699,
      descricaoCnae: "Comercio varejista de produtos alimenticios em geral ou especializado em produtos alimenticios nao especificados anteriormente"
    },
    {
      codigoMCC: 5251,
      descricaoMCC: "Lojas de Ferragens",
      codigoCnae: 4744001,
      descricaoCnae: "Comercio varejista de ferragens e ferramentas"
    },
    {
      codigoMCC: 5300,
      descricaoMCC: "Atacadista",
      codigoCnae: 4691500,
      descricaoCnae: "Comercio atacadista de mercadorias em geral, com predominancia de produtos alimenticios"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 4637107,
      descricaoCnae: "Comercio atacadista de chocolates, confeitos, balas, bombons e semelhantes"
    },
    {
      codigoMCC: 5411,
      descricaoMCC: "Supermercados",
      codigoCnae: 4711302,
      descricaoCnae: "Comercio varejista de mercadorias em geral, com predominancia de produtos alimenticios - supermercados"
    },
    {
      codigoMCC: 5462,
      descricaoMCC: "Padarias",
      codigoCnae: 4721102,
      descricaoCnae: "Padaria e confeitaria com predominancia de revenda"
    },
    {
      codigoMCC: 5441,
      descricaoMCC: "Docerias e Confeitarias",
      codigoCnae: 4721104,
      descricaoCnae: "Comercio varejista de doces, balas, bombons e semelhantes"
    },
    {
      codigoMCC: 5300,
      descricaoMCC: "Atacadista",
      codigoCnae: 4639701,
      descricaoCnae: "Comercio atacadista de produtos alimenticios em geral"
    },
    {
      codigoMCC: 5462,
      descricaoMCC: "Padarias",
      codigoCnae: 1091101,
      descricaoCnae: "Fabricaçao de produtos de panificaçao industrial"
    },
    {
      codigoMCC: 5499,
      descricaoMCC: "Casas de Carnes",
      codigoCnae: 5620104,
      descricaoCnae: "Fornecimento de alimentos preparados preponderantemente para consumo domiciliar"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1093701,
      descricaoCnae: "Fabrica"
    },
    {
      codigoMCC: 5451,
      descricaoMCC: "Laticinio",
      codigoCnae: 4721103,
      descricaoCnae: "Comercio varejista de laticinios e frios"
    },
    {
      codigoMCC: 5411,
      descricaoMCC: "Supermercados",
      codigoCnae: 4712100,
      descricaoCnae: "Comercio varejista de mercadorias em geral, com predominancia de produtos alimenticios - minimercados, mercearias e armazens"
    },
    {
      codigoMCC: 5422,
      descricaoMCC: "Comidas Congeladas",
      codigoCnae: 4722901,
      descricaoCnae: "Comercio varejista de carnes - açougues"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 4649499,
      descricaoCnae: "Comercio atacadista de outros equipamentos e artigos de uso pessoal e domestico nao especificados anteriormente"
    },
    {
      codigoMCC: 5511,
      descricaoMCC: "Carros e Caminhoes (Novos e Usados)",
      codigoCnae: 4511101,
      descricaoCnae: "Comercio a varejo de automoveis, camionetas e utilitarios novos"
    },
    {
      codigoMCC: 5521,
      descricaoMCC: "Automoveis Usados",
      codigoCnae: 4511102,
      descricaoCnae: "Comercio a varejo de automoveis, camionetas e utilitarios usados"
    },
    {
      codigoMCC: 5533,
      descricaoMCC: "Lojas de Acessorios para Veiculos Automotivos",
      codigoCnae: 4530703,
      descricaoCnae: "Comercio a varejo de peças e acessorios novos para veiculos automotores"
    },
    {
      codigoMCC: 5211,
      descricaoMCC: "Lojas de Material de Construçao e Ferragens",
      codigoCnae: 1610201,
      descricaoCnae: "Serrarias com desdobramento de madeira"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 2229301,
      descricaoCnae: "Fabricaçao de artefatos de material plastico para uso pessoal e domestico"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 4635403,
      descricaoCnae: "Comercio atacadista de bebidas com atividade de fracionamento e acondicionamento associada"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 4635499,
      descricaoCnae: "Comercio atacadista de bebidas nao especificadas anteriormente"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 4643502,
      descricaoCnae: "Comercio atacadista de bolsas, malas e artigos de viagem"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 4635402,
      descricaoCnae: "Comercio atacadista de cerveja, chope e refrigerante"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 4631100,
      descricaoCnae: "Comercio atacadista de leite e laticinios"
    },
    {
      codigoMCC: 5300,
      descricaoMCC: "Atacadista",
      codigoCnae: 4637199,
      descricaoCnae: "Comercio atacadista especializado em outros produtos alimenticios nao especificados anteriormente"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1111901,
      descricaoCnae: "Fabricaçao de aguardente de cana-de-açucar"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1099607,
      descricaoCnae: "Fabricaçao de alimentos dieteticos e complementos alimentares"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 3230200,
      descricaoCnae: "Fabricaçao de artefatos para pesca e esporte"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1521100,
      descricaoCnae: "Fabricaçao de artigos para viagem, bolsas e semelhantes de qualquer material"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1122404,
      descricaoCnae: "Fabricaçao de bebidas isotonicas"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1113502,
      descricaoCnae: "Fabricaçao de cervejas e chopes"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1111902,
      descricaoCnae: "Fabricaçao de outras aguardentes e bebidas destiladas"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1122499,
      descricaoCnae: "Fabricaçao de outras bebidas nao-alcoolicas nao especificadas anteriormente"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1099699,
      descricaoCnae: "Fabricaçao de outros produtos alimenticios nao especificados anteriormente"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1122401,
      descricaoCnae: "Fabricaçao de refrigerantes"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1112700,
      descricaoCnae: "Fabricaçao de vinho"
    },
    {
      codigoMCC: 5211,
      descricaoMCC: "Lojas de Material de Construçao e Ferragens",
      codigoCnae: 4742300,
      descricaoCnae: "Comercio varejista de material eletrico"
    },
    {
      codigoMCC: 5211,
      descricaoMCC: "Lojas de Material de Construçao e Ferragens",
      codigoCnae: 4744003,
      descricaoCnae: "Comercio varejista de materiais hidraulicos"
    },
    {
      codigoMCC: 5200,
      descricaoMCC: "Lojas de Material de Construçao e Ferragens",
      codigoCnae: 4744099,
      descricaoCnae: "Comercio varejista de materiais de construçao em geral"
    },
    {
      codigoMCC: 5331,
      descricaoMCC: "Presentes em Geral",
      codigoCnae: 4713002,
      descricaoCnae: "Lojas de variedades, exceto lojas de departamentos ou magazines"
    },
    {
      codigoMCC: 5211,
      descricaoMCC: "Lojas de Material de Construçao e Ferragens",
      codigoCnae: 4744005,
      descricaoCnae: "Comercio varejista de materiais de construçao nao especificados anteriormente"
    },
    {
      codigoMCC: 5541,
      descricaoMCC: "Postos de Gasolina",
      codigoCnae: 4731800,
      descricaoCnae: "Comercio varejista de combustiveis para veiculos automotores"
    },
    {
      codigoMCC: 5300,
      descricaoMCC: "Atacadista",
      codigoCnae: 4619200,
      descricaoCnae: "Representantes comerciais e agentes do comercio de mercadorias em geral nao especializado"
    },
    {
      codigoMCC: 5211,
      descricaoMCC: "Lojas de Material de Construçao e Ferragens",
      codigoCnae: 4744002,
      descricaoCnae: "Comercio varejista de madeira e artefatos"
    },
    {
      codigoMCC: 5499,
      descricaoMCC: "Casas de Carnes",
      codigoCnae: 4724500,
      descricaoCnae: "Comercio varejista de hortifrutigranjeiros"
    },
    {
      codigoMCC: 5533,
      descricaoMCC: "Lojas de Acessorios para Veiculos Automotivos",
      codigoCnae: 4530704,
      descricaoCnae: "Comercio a varejo de peças e acessorios usados para veiculos automotores"
    },
    {
      codigoMCC: 5571,
      descricaoMCC: "Motocicletas",
      codigoCnae: 4541203,
      descricaoCnae: "Comercio a varejo de motocicletas e motonetas novas"
    },
    {
      codigoMCC: 5571,
      descricaoMCC: "Motocicletas",
      codigoCnae: 4541204,
      descricaoCnae: "Comercio a varejo de motocicletas e motonetas usadas"
    },
    {
      codigoMCC: 5533,
      descricaoMCC: "Lojas de Acessorios para Veiculos Automotivos",
      codigoCnae: 4520007,
      descricaoCnae: "Serviços de instalaçao, manutençao e reparaçao de acessorios para veiculos automotores"
    },
    {
      codigoMCC: 5511,
      descricaoMCC: "Carros e Caminhoes (Novos e Usados)",
      codigoCnae: 4512902,
      descricaoCnae: "Comercio sob consignaçao de veiculos automotores"
    },
    {
      codigoMCC: 5599,
      descricaoMCC: "Automoveis",
      codigoCnae: 4614100,
      descricaoCnae: "Representantes comerciais e agentes do comercio de maquinas, equipamentos, embarcaçoes e aeronaves"
    },
    {
      codigoMCC: 5231,
      descricaoMCC: "Lojas de Vidro / Lojas de Pintura / Papel de Pared",
      codigoCnae: 4741500,
      descricaoCnae: "Comercio varejista de tintas e materiais para pintura"
    },
    {
      codigoMCC: 5231,
      descricaoMCC: "Lojas de Vidro / Lojas de Pintura / Papel de Pared",
      codigoCnae: 4743100,
      descricaoCnae: "Comercio varejista de vidros"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 4623108,
      descricaoCnae: "Comercio atacadista de materias-primas agricolas com atividade de fracionamento e acondicionamento associada"
    },
    {
      codigoMCC: 5651,
      descricaoMCC: "Lojas de Roupas Masculinas, Femininas e Infantis",
      codigoCnae: 1422300,
      descricaoCnae: "Fabricaçao de artigos do vestuario, produzidos em malharias e tricotagens, exceto meias"
    },
    {
      codigoMCC: 5499,
      descricaoMCC: "Casas de Carnes",
      codigoCnae: 4729602,
      descricaoCnae: "Comercio varejista de mercadorias em lojas de conveniencia"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 4622200,
      descricaoCnae: "Comercio atacadista de soja"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 4634601,
      descricaoCnae: "Comercio atacadista de carnes bovinas e suinas e derivados"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 4637103,
      descricaoCnae: "Comercio atacadista de oleos e gorduras"
    },
    {
      codigoMCC: 5422,
      descricaoMCC: "Comidas Congeladas",
      codigoCnae: 4722902,
      descricaoCnae: "Peixaria"
    },
    {
      codigoMCC: 5511,
      descricaoMCC: "Carros e Caminhoes (Novos e Usados)",
      codigoCnae: 4511103,
      descricaoCnae: "Comercio por atacado de automoveis, camionetas e utilitarios novos e usados"
    },
    {
      codigoMCC: 5499,
      descricaoMCC: "Casas de Carnes",
      codigoCnae: 5612100,
      descricaoCnae: "Serviços ambulantes de alimentaçao"
    },
    {
      codigoMCC: 5211,
      descricaoMCC: "Lojas de Material de Construçao e Ferragens",
      codigoCnae: 4744006,
      descricaoCnae: "Comercio varejista de pedras para revestimento"
    },
    {
      codigoMCC: 5532,
      descricaoMCC: "Loja de Pneus",
      codigoCnae: 4530705,
      descricaoCnae: "Comercio a varejo de pneumaticos e camaras-de-ar"
    },
    {
      codigoMCC: 5599,
      descricaoMCC: "Automoveis",
      codigoCnae: 4661300,
      descricaoCnae: "Comercio atacadista de maquinas, aparelhos e equipamentos para uso agropecuario; partes e peças"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 4621400,
      descricaoCnae: "Comercio atacadista de cafe em grao"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 4632001,
      descricaoCnae: "Comercio atacadista de cereais e leguminosas beneficiados"
    },
    {
      codigoMCC: 5399,
      descricaoMCC: "Comercio em Geral",
      codigoCnae: 4618499,
      descricaoCnae: "Outros representantes comerciais e agentes do comercio especializado em produtos nao especificados anteriormente"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1094500,
      descricaoCnae: "Fabricaçao de massas alimenticias"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1122403,
      descricaoCnae: "Fabricaçao de refrescos, xaropes e pos para refrescos, exceto refrescos de frutas"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 4637105,
      descricaoCnae: "Comercio atacadista de massas alimenticias"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1095300,
      descricaoCnae: "Fabricaçao de especiarias, molhos, temperos e condimentos"
    },
    {
      codigoMCC: 5499,
      descricaoMCC: "Casas de Carnes",
      codigoCnae: 5620101,
      descricaoCnae: "Fornecimento de alimentos preparados preponderantemente para empresas"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 4641902,
      descricaoCnae: "Comercio atacadista de artigos de cama, mesa e banho"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 4637106,
      descricaoCnae: "Comercio atacadista de sorvetes"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 4635401,
      descricaoCnae: "Comercio atacadista de agua mineral"
    },
    {
      codigoMCC: 5462,
      descricaoMCC: "Padarias",
      codigoCnae: 1091102,
      descricaoCnae: "Fabricaçao de produtos de padaria e confeitaria com predominancia de produçao propria"
    },
    {
      codigoMCC: 5462,
      descricaoMCC: "Padarias",
      codigoCnae: 4637104,
      descricaoCnae: "Comercio atacadista de paes, bolos, biscoitos e similares"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1096100,
      descricaoCnae: "Fabricaçao de alimentos e pratos prontos"
    },
    {
      codigoMCC: 5211,
      descricaoMCC: "Lojas de Material de Construçao e Ferragens",
      codigoCnae: 4744004,
      descricaoCnae: "Comercio varejista de cal, areia, pedra britada, tijolos e telhas"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1099604,
      descricaoCnae: "Fabricaçao de gelo comum"
    },
    {
      codigoMCC: 5533,
      descricaoMCC: "Lojas de Acessorios para Veiculos Automotivos",
      codigoCnae: 4520008,
      descricaoCnae: "Serviços de capotaria"
    },
    {
      codigoMCC: 5599,
      descricaoMCC: "Automoveis",
      codigoCnae: 4662100,
      descricaoCnae: "Comercio atacadista de maquinas, equipamentos para terraplenagem, mineraçao e construçao; partes e peças"
    },
    {
      codigoMCC: 5571,
      descricaoMCC: "Motocicletas",
      codigoCnae: 4541201,
      descricaoCnae: "Comercio por atacado de motocicletas e motonetas"
    },
    {
      codigoMCC: 5599,
      descricaoMCC: "Automoveis",
      codigoCnae: 2854200,
      descricaoCnae: "Fabricaçao de maquinas e equipamentos para terraplenagem, pavimentaçao e construçao, peças e acessorios, exceto tratores"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 4623109,
      descricaoCnae: "Comercio atacadista de alimentos para animais"
    },
    {
      codigoMCC: 5422,
      descricaoMCC: "Comidas Congeladas",
      codigoCnae: 1020101,
      descricaoCnae: "Preservaçao de peixes, crustaceos e moluscos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 4634603,
      descricaoCnae: "Comercio atacadista de pescados e frutos do mar"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 4617600,
      descricaoCnae: "Representantes comerciais e agentes do comercio de produtos alimenticios, bebidas e fumo"
    },
    {
      codigoMCC: 5422,
      descricaoMCC: "Comidas Congeladas",
      codigoCnae: 1011201,
      descricaoCnae: "Frigorifico - abate de bovinos"
    },
    {
      codigoMCC: 5422,
      descricaoMCC: "Comidas Congeladas",
      codigoCnae: 1011203,
      descricaoCnae: "Frigorifico - abate de ovinos e caprinos"
    },
    {
      codigoMCC: 5422,
      descricaoMCC: "Comidas Congeladas",
      codigoCnae: 1011204,
      descricaoCnae: "Frigorifico - abate de bufalinos"
    },
    {
      codigoMCC: 5422,
      descricaoMCC: "Comidas Congeladas",
      codigoCnae: 1012103,
      descricaoCnae: "Frigorifico - abate de suinos"
    },
    {
      codigoMCC: 5422,
      descricaoMCC: "Comidas Congeladas",
      codigoCnae: 1013902,
      descricaoCnae: "Preparaçao de subprodutos do abate"
    },
    {
      codigoMCC: 5300,
      descricaoMCC: "Atacadista",
      codigoCnae: 4639702,
      descricaoCnae: "Comercio atacadista de produtos alimenticios em geral, com atividade de fracionamento e acondicionamento associada"
    },
    {
      codigoMCC: 5571,
      descricaoMCC: "Motocicletas",
      codigoCnae: 4542102,
      descricaoCnae: "Comercio sob consignaçao de motocicletas e motonetas (Dispensada *)"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1093702,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1099601,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1099602,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1099603,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1099605,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1099606,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1113501,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1121600,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1122402,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1220401,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1220402,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1220403,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1220499,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1731100,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 1732000,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 3291400,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 3292202,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 3299001,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 3299006,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 3299099,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 4623102,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 4623103,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 4623104,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 4623105,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 4632002,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 4633801,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 4633802,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 4633803,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 4634602,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 4634699,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 4636201,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 4636202,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 4637101,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5199,
      descricaoMCC: "Feiras, Eventos e Artesanatos",
      codigoCnae: 4637102,
      descricaoCnae: "Feiras, Eventos e Artesanatos"
    },
    {
      codigoMCC: 5211,
      descricaoMCC: "Lojas de Material de Construçao e Ferragens",
      codigoCnae: 1610202,
      descricaoCnae: "Lojas de Material de Construçao e Ferragens"
    },
    {
      codigoMCC: 5309,
      descricaoMCC: "Duty Free Stores",
      codigoCnae: 4713003,
      descricaoCnae: "Duty Free Stores"
    },
    {
      codigoMCC: 5311,
      descricaoMCC: "Lojas de Departamento",
      codigoCnae: 4713001,
      descricaoCnae: "Lojas de Departamento"
    },
    {
      codigoMCC: 5411,
      descricaoMCC: "Supermercados",
      codigoCnae: 4711301,
      descricaoCnae: "Supermercados"
    },
    {
      codigoMCC: 5422,
      descricaoMCC: "Comidas Congeladas",
      codigoCnae: 1011202,
      descricaoCnae: "Comidas Congeladas"
    },
    {
      codigoMCC: 5422,
      descricaoMCC: "Comidas Congeladas",
      codigoCnae: 1011205,
      descricaoCnae: "Comidas Congeladas"
    },
    {
      codigoMCC: 5422,
      descricaoMCC: "Comidas Congeladas",
      codigoCnae: 1012102,
      descricaoCnae: "Comidas Congeladas"
    },
    {
      codigoMCC: 5422,
      descricaoMCC: "Comidas Congeladas",
      codigoCnae: 1012104,
      descricaoCnae: "Comidas Congeladas"
    },
    {
      codigoMCC: 5462,
      descricaoMCC: "Padarias",
      codigoCnae: 1091100,
      descricaoCnae: "Padarias"
    },
    {
      codigoMCC: 5462,
      descricaoMCC: "Padarias",
      codigoCnae: 4721101,
      descricaoCnae: "Padarias"
    },
    {
      codigoMCC: 5499,
      descricaoMCC: "Casas de Carnes",
      codigoCnae: 1012101,
      descricaoCnae: "Casas de Carnes"
    },
    {
      codigoMCC: 5511,
      descricaoMCC: "Carros e Caminhoes (Novos e Usados)",
      codigoCnae: 2910701,
      descricaoCnae: "Carros e Caminhoes (Novos e Usados)"
    },
    {
      codigoMCC: 5511,
      descricaoMCC: "Carros e Caminhoes (Novos e Usados)",
      codigoCnae: 2920401,
      descricaoCnae: "Carros e Caminhoes (Novos e Usados)"
    },
    {
      codigoMCC: 5511,
      descricaoMCC: "Carros e Caminhoes (Novos e Usados)",
      codigoCnae: 4511104,
      descricaoCnae: "Carros e Caminhoes (Novos e Usados)"
    },
    {
      codigoMCC: 5511,
      descricaoMCC: "Carros e Caminhoes (Novos e Usados)",
      codigoCnae: 4511105,
      descricaoCnae: "Carros e Caminhoes (Novos e Usados)"
    },
    {
      codigoMCC: 5511,
      descricaoMCC: "Carros e Caminhoes (Novos e Usados)",
      codigoCnae: 4511106,
      descricaoCnae: "Carros e Caminhoes (Novos e Usados)"
    },
    {
      codigoMCC: 5511,
      descricaoMCC: "Carros e Caminhoes (Novos e Usados)",
      codigoCnae: 4512901,
      descricaoCnae: "Carros e Caminhoes (Novos e Usados)"
    },
    {
      codigoMCC: 5551,
      descricaoMCC: "Barcos",
      codigoCnae: 4763605,
      descricaoCnae: "Barcos"
    },
    {
      codigoMCC: 5571,
      descricaoMCC: "Motocicletas",
      codigoCnae: 4541205,
      descricaoCnae: "Motocicletas"
    },
    {
      codigoMCC: 5571,
      descricaoMCC: "Motocicletas",
      codigoCnae: 4542101,
      descricaoCnae: "Motocicletas"
    },
    {
      codigoMCC: 5571,
      descricaoMCC: "Motocicletas",
      codigoCnae: 3091100,
      descricaoCnae: "Motocicletas"
    },
    {
      codigoMCC: 5599,
      descricaoMCC: "Automoveis",
      codigoCnae: 2831300,
      descricaoCnae: "Automoveis"
    },
    {
      codigoMCC: 5599,
      descricaoMCC: "Automoveis",
      codigoCnae: 2832100,
      descricaoCnae: "Automoveis"
    },
    {
      codigoMCC: 5599,
      descricaoMCC: "Automoveis",
      codigoCnae: 2833000,
      descricaoCnae: "Automoveis"
    },
    {
      codigoMCC: 5599,
      descricaoMCC: "Automoveis",
      codigoCnae: 2853400,
      descricaoCnae: "Automoveis"
    },
    {
      codigoMCC: 5599,
      descricaoMCC: "Automoveis",
      codigoCnae: 2930101,
      descricaoCnae: "Automoveis"
    },
    {
      codigoMCC: 5599,
      descricaoMCC: "Automoveis",
      codigoCnae: 2930103,
      descricaoCnae: "Automoveis"
    },
    {
      codigoMCC: 5599,
      descricaoMCC: "Automoveis",
      codigoCnae: 3031800,
      descricaoCnae: "Automoveis"
    },
    {
      codigoMCC: 5599,
      descricaoMCC: "Automoveis",
      codigoCnae: 3032600,
      descricaoCnae: "Automoveis"
    },
    {
      codigoMCC: 5599,
      descricaoMCC: "Automoveis",
      codigoCnae: 3041500,
      descricaoCnae: "Automoveis"
    },
    {
      codigoMCC: 5599,
      descricaoMCC: "Automoveis",
      codigoCnae: 3042300,
      descricaoCnae: "Automoveis"
    },
    {
      codigoMCC: 5599,
      descricaoMCC: "Automoveis",
      codigoCnae: 3050400,
      descricaoCnae: "Automoveis"
    },
    {
      codigoMCC: 5599,
      descricaoMCC: "Automoveis",
      codigoCnae: 3099700,
      descricaoCnae: "Automoveis"
    },
    {
      codigoMCC: 5734,
      descricaoMCC: "Lojas de Informatica",
      codigoCnae: 4751201,
      descricaoCnae: "COMERCIO VAREJISTA ESPECIALIZADO DE EQUIPAMENTOS E SUPRIMENTOS DE INFORMATICA"
    },
    {
      codigoMCC: 5942,
      descricaoMCC: "Livrarias",
      codigoCnae: 4761001,
      descricaoCnae: "Comercio varejista de livros"
    },
    {
      codigoMCC: 5719,
      descricaoMCC: "Utilidades e Mobilia para o Lar",
      codigoCnae: 4755503,
      descricaoCnae: "Comercio varejista de artigos de cama, mesa e banho"
    },
    {
      codigoMCC: 5977,
      descricaoMCC: "Perfumarias, Cosmeticos e Toucadores",
      codigoCnae: 4772500,
      descricaoCnae: "Comercio varejista de cosmeticos, produtos de perfumaria e de higiene pessoal"
    },
    {
      codigoMCC: 5943,
      descricaoMCC: "Papelarias",
      codigoCnae: 4761003,
      descricaoCnae: "Comercio varejista de artigos de papelaria"
    },
    {
      codigoMCC: 5947,
      descricaoMCC: "Souveniers",
      codigoCnae: 4782202,
      descricaoCnae: "Comercio varejista de artigos de viagem"
    },
    {
      codigoMCC: 5733,
      descricaoMCC: "Instrumentos Musicais",
      codigoCnae: 4756300,
      descricaoCnae: "Comercio varejista especializado de instrumentos musicais e acessorios"
    },
    {
      codigoMCC: 5735,
      descricaoMCC: "Discos / CDs",
      codigoCnae: 4762800,
      descricaoCnae: "Comercio varejista de discos, CDs, DVDs e fitas"
    },
    {
      codigoMCC: 5712,
      descricaoMCC: "Moveis em Geral",
      codigoCnae: 4754701,
      descricaoCnae: "Comercio varejista de moveis"
    },
    {
      codigoMCC: 5712,
      descricaoMCC: "Moveis em Geral",
      codigoCnae: 4754702,
      descricaoCnae: "Comercio varejista de artigos de colchoaria"
    },
    {
      codigoMCC: 5941,
      descricaoMCC: "Artigos para Caça, Pesca e Camping",
      codigoCnae: 4763602,
      descricaoCnae: "Comercio varejista de artigos esportivos"
    },
    {
      codigoMCC: 5945,
      descricaoMCC: "Brinquedos e Jogos",
      codigoCnae: 4763601,
      descricaoCnae: "Comercio varejista de brinquedos e artigos recreativos"
    },
    {
      codigoMCC: 5999,
      descricaoMCC: "Lojas Especializadas (nao classificado em outro)",
      codigoCnae: 4789099,
      descricaoCnae: "Comercio varejista de outros produtos nao especificados anteriormente"
    },
    {
      codigoMCC: 5999,
      descricaoMCC: "Lojas Especializadas (nao classificado em outro)",
      codigoCnae: 4759899,
      descricaoCnae: "Comercio varejista de outros artigos de uso pessoal e domestico nao especificados anteriormente"
    },
    {
      codigoMCC: 5722,
      descricaoMCC: "Utensilios Domesticos",
      codigoCnae: 4753900,
      descricaoCnae: "Comercio varejista especializado de eletrodomesticos e equipamentos de audio e video"
    },
    {
      codigoMCC: 5944,
      descricaoMCC: "Joalherias e Relojoarias",
      codigoCnae: 4783102,
      descricaoCnae: "Comercio varejista de artigos de relojoaria"
    },
    {
      codigoMCC: 5941,
      descricaoMCC: "Artigos para Caça, Pesca e Camping",
      codigoCnae: 4763604,
      descricaoCnae: "Comercio varejista de artigos de caça, pesca e camping"
    },
    {
      codigoMCC: 5921,
      descricaoMCC: "Bebidas",
      codigoCnae: 4723700,
      descricaoCnae: "Comercio varejista de bebidas"
    },
    {
      codigoMCC: 5940,
      descricaoMCC: "Bicicletas / Venda e Serviço",
      codigoCnae: 4763603,
      descricaoCnae: "Comercio varejista de bicicletas e triciclos; peças e acessorios"
    },
    {
      codigoMCC: 5976,
      descricaoMCC: "Materiais Ortopedicos",
      codigoCnae: 4773300,
      descricaoCnae: "Comercio varejista de artigos medicos e ortopedicos"
    },
    {
      codigoMCC: 6300,
      descricaoMCC: "Seguradoras",
      codigoCnae: 6512000,
      descricaoCnae: "Sociedade seguradora de seguros nao vida"
    },
    {
      codigoMCC: 5944,
      descricaoMCC: "Joalherias e Relojoarias",
      codigoCnae: 4783101,
      descricaoCnae: "Comercio varejista de artigos de joalheria"
    },
    {
      codigoMCC: 5697,
      descricaoMCC: "Alfaiates / Costureiras (os)",
      codigoCnae: 1412602,
      descricaoCnae: "Confecçao, sob medida, de peças do vestuario, exceto roupas intimas"
    },
    {
      codigoMCC: 5812,
      descricaoMCC: "Restaurantes",
      codigoCnae: 5611201,
      descricaoCnae: "Restaurantes e similares"
    },
    {
      codigoMCC: 5814,
      descricaoMCC: "Fast Food",
      codigoCnae: 5611203,
      descricaoCnae: "Lanchonetes, casas de cha, de sucos e similares"
    },
    {
      codigoMCC: 5949,
      descricaoMCC: "Armarinhos / Tecidos",
      codigoCnae: 4755502,
      descricaoCnae: "Comercio varejista de artigos de armarinho"
    },
    {
      codigoMCC: 5946,
      descricaoMCC: "Cameras Fotograficas, Acessorios e Som",
      codigoCnae: 4789008,
      descricaoCnae: "Comercio varejista de artigos fotograficos e para filmagem"
    },
    {
      codigoMCC: 6211,
      descricaoMCC: "Corretor de Seguros",
      codigoCnae: 6491300,
      descricaoCnae: "Sociedades de fomento mercantil - factoring"
    },
    {
      codigoMCC: 6012,
      descricaoMCC: "Bancos / Lojas de Poupança e Instituiçoes Financei",
      codigoCnae: 6492100,
      descricaoCnae: "Securitizaçao de creditos"
    },
    {
      codigoMCC: 5969,
      descricaoMCC: "Outros EC’s de Telemarketing",
      codigoCnae: 8220200,
      descricaoCnae: "Atividades de teleatendimento"
    },
    {
      codigoMCC: 5949,
      descricaoMCC: "Armarinhos / Tecidos",
      codigoCnae: 4755501,
      descricaoCnae: "Comercio varejista de tecidos"
    },
    {
      codigoMCC: 5947,
      descricaoMCC: "Souveniers",
      codigoCnae: 4789001,
      descricaoCnae: "Comercio varejista de suvenires, bijuterias e artesanatos"
    },
    {
      codigoMCC: 5999,
      descricaoMCC: "Lojas Especializadas (nao classificado em outro)",
      codigoCnae: 4789005,
      descricaoCnae: "Comercio varejista de produtos saneantes domissanitarios"
    },
    {
      codigoMCC: 5995,
      descricaoMCC: "Animais / Comidas e Suprimentos",
      codigoCnae: 4789004,
      descricaoCnae: "Comercio varejista de animais vivos e de artigos e alimentos para animais de estimaçao"
    },
    {
      codigoMCC: 5995,
      descricaoMCC: "Animais / Comidas e Suprimentos",
      codigoCnae: 9609208,
      descricaoCnae: "Higiene e embelezamento de animais domesticos"
    },
    {
      codigoMCC: 5912,
      descricaoMCC: "Drogarias e Farmacias",
      codigoCnae: 4771704,
      descricaoCnae: "Comercio varejista de medicamentos veterinarios"
    },
    {
      codigoMCC: 6012,
      descricaoMCC: "Bancos / Lojas de Poupança e Instituiçoes Financei",
      codigoCnae: 6421200,
      descricaoCnae: "Bancos comerciais"
    },
    {
      codigoMCC: 5712,
      descricaoMCC: "Moveis em Geral",
      codigoCnae: 4754703,
      descricaoCnae: "Comercio varejista de artigos de iluminaçao"
    },
    {
      codigoMCC: 6051,
      descricaoMCC: "Casas de Cambio",
      codigoCnae: 6613400,
      descricaoCnae: "Administraçao de cartoes de credito"
    },
    {
      codigoMCC: 6012,
      descricaoMCC: "Bancos / Lojas de Poupança e Instituiçoes Financei",
      codigoCnae: 6619305,
      descricaoCnae: "Operadoras de cartoes de debito"
    },
    {
      codigoMCC: 5983,
      descricaoMCC: "Comercio de Combustiveis / Carvao e Lenha",
      codigoCnae: 4732600,
      descricaoCnae: "Comercio varejista de lubrificantes"
    },
    {
      codigoMCC: 6513,
      descricaoMCC: "Imobiliarias",
      codigoCnae: 6810201,
      descricaoCnae: "Compra e venda de imoveis proprios"
    },
    {
      codigoMCC: 6012,
      descricaoMCC: "Bancos / Lojas de Poupança e Instituiçoes Financei",
      codigoCnae: 6619302,
      descricaoCnae: "Correspondentes de instituiçoes financeiras"
    },
    {
      codigoMCC: 5993,
      descricaoMCC: "Tabacaria",
      codigoCnae: 4729601,
      descricaoCnae: "Tabacaria"
    },
    {
      codigoMCC: 5941,
      descricaoMCC: "Artigos para Caça, Pesca e Camping",
      codigoCnae: 7721700,
      descricaoCnae: "Aluguel de equipamentos recreativos e esportivos"
    },
    {
      codigoMCC: 6211,
      descricaoMCC: "Corretor de Seguros",
      codigoCnae: 6630400,
      descricaoCnae: "Atividades de administraçao de fundos por contrato ou comissao"
    },
    {
      codigoMCC: 7230,
      descricaoMCC: "Salao de Beleza / Barbearia",
      codigoCnae: 9602501,
      descricaoCnae: "Cabeleireiros, manicure e pedicure"
    },
    {
      codigoMCC: 5732,
      descricaoMCC: "Aparelhos de Som / Televisores / Eletrodomesticos",
      codigoCnae: 4757100,
      descricaoCnae: "Comercio varejista especializado de peças e acessorios para aparelhos eletroeletronicos para uso domestico, exceto informatica e comunicaçao"
    },
    {
      codigoMCC: 6211,
      descricaoMCC: "Corretor de Seguros",
      codigoCnae: 6612604,
      descricaoCnae: "Corretoras de contratos de mercadorias"
    },
    {
      codigoMCC: 5714,
      descricaoMCC: "Tapeçaria / Cortinas",
      codigoCnae: 4759801,
      descricaoCnae: "Comercio varejista de artigos de tapeçaria, cortinas e persianas"
    },
    {
      codigoMCC: 5992,
      descricaoMCC: "Floricultura",
      codigoCnae: 4789002,
      descricaoCnae: "Comercio varejista de plantas e flores naturais"
    },
    {
      codigoMCC: 5811,
      descricaoMCC: "Fornecedores e Serviço de Buffet",
      codigoCnae: 8230002,
      descricaoCnae: "Casas de festas e eventos"
    },
    {
      codigoMCC: 6051,
      descricaoMCC: "Casas de Cambio",
      codigoCnae: 6612603,
      descricaoCnae: "Corretoras de cambio"
    },
    {
      codigoMCC: 5999,
      descricaoMCC: "Lojas Especializadas (nao classificado em outro)",
      codigoCnae: 4789009,
      descricaoCnae: "Comercio varejista de armas e muniçoes"
    },
    {
      codigoMCC: 5969,
      descricaoMCC: "Outros EC’s de Telemarketing",
      codigoCnae: 7319003,
      descricaoCnae: "Marketing direto"
    },
    {
      codigoMCC: 5943,
      descricaoMCC: "Papelarias",
      codigoCnae: 4789007,
      descricaoCnae: "Comercio varejista de equipamentos para escritorio"
    },
    {
      codigoMCC: 7221,
      descricaoMCC: "Estudio Fotografico",
      codigoCnae: 7420001,
      descricaoCnae: "Atividades de produçao de fotografias, exceto aerea e submarina"
    },
    {
      codigoMCC: 7221,
      descricaoMCC: "Estudio Fotografico",
      codigoCnae: 7420002,
      descricaoCnae: "Atividades de produçao de fotografias aereas e submarinas"
    },
    {
      codigoMCC: 7221,
      descricaoMCC: "Estudio Fotografico",
      codigoCnae: 7420004,
      descricaoCnae: "Filmagem de festas e eventos"
    },
    {
      codigoMCC: 5912,
      descricaoMCC: "Drogarias e Farmacias",
      codigoCnae: 4771702,
      descricaoCnae: "Comercio varejista de produtos farmaceuticos, com manipulaçao de formulas"
    },
    {
      codigoMCC: 5912,
      descricaoMCC: "Drogarias e Farmacias",
      codigoCnae: 4771703,
      descricaoCnae: "Comercio varejista de produtos farmaceuticos homeopaticos"
    },
    {
      codigoMCC: 5971,
      descricaoMCC: "Galerias de Arte / Leilao de Arte",
      codigoCnae: 4789003,
      descricaoCnae: "Comercio varejista de objetos de arte"
    },
    {
      codigoMCC: 6513,
      descricaoMCC: "Imobiliarias",
      codigoCnae: 6821801,
      descricaoCnae: "Corretagem na compra e venda e avaliaçao de imoveis"
    },
    {
      codigoMCC: 6513,
      descricaoMCC: "Imobiliarias",
      codigoCnae: 6821802,
      descricaoCnae: "Corretagem no aluguel de imoveis"
    },
    {
      codigoMCC: 7261,
      descricaoMCC: "Crematorios / Funerarias",
      codigoCnae: 9603304,
      descricaoCnae: "Serviços de funerarias"
    },
    {
      codigoMCC: 5983,
      descricaoMCC: "Comercio de Combustiveis / Carvao e Lenha",
      codigoCnae: 4784900,
      descricaoCnae: "Comercio varejista de gas liquefeito de petroleo (GLP)"
    },
    {
      codigoMCC: 5811,
      descricaoMCC: "Fornecedores e Serviço de Buffet",
      codigoCnae: 5620102,
      descricaoCnae: "Serviços de alimentaçao para eventos e recepçoes - bufe"
    },
    {
      codigoMCC: 5912,
      descricaoMCC: "Drogarias e Farmacias",
      codigoCnae: 4771701,
      descricaoCnae: "Comercio varejista de produtos farmaceuticos, sem manipulaçao de formulas"
    },
    {
      codigoMCC: 5699,
      descricaoMCC: "Artigos Unissex",
      codigoCnae: 4616800,
      descricaoCnae: "Representantes comerciais e agentes do comercio de texteis, vestuario, calçados e artigos de viagem"
    },
    {
      codigoMCC: 5931,
      descricaoMCC: "Artigos de Segunda Mao / Usados",
      codigoCnae: 4785799,
      descricaoCnae: "Comercio varejista de outros artigos usados"
    },
    {
      codigoMCC: 5712,
      descricaoMCC: "Moveis em Geral",
      codigoCnae: 3104700,
      descricaoCnae: "Fabricaçao de colchoes"
    },
    {
      codigoMCC: 5940,
      descricaoMCC: "Bicicletas / Venda e Serviço",
      codigoCnae: 4649403,
      descricaoCnae: "Comercio atacadista de bicicletas, triciclos e outros veiculos recreativos"
    },
    {
      codigoMCC: 7011,
      descricaoMCC: "Hoteis",
      codigoCnae: 5510801,
      descricaoCnae: "Hoteis"
    },
    {
      codigoMCC: 5812,
      descricaoMCC: "Restaurantes",
      codigoCnae: 5620103,
      descricaoCnae: "Cantinas - serviços de alimentaçao privativos"
    },
    {
      codigoMCC: 6211,
      descricaoMCC: "Corretor de Seguros",
      codigoCnae: 6612605,
      descricaoCnae: "Agentes de investimentos em aplicaçoes financeiras"
    },
    {
      codigoMCC: 5994,
      descricaoMCC: "Banca de Jornal",
      codigoCnae: 4761002,
      descricaoCnae: "Comercio varejista de jornais e revistas"
    },
    {
      codigoMCC: 5813,
      descricaoMCC: "Bares",
      codigoCnae: 9329801,
      descricaoCnae: "Discotecas, danceterias, saloes de dança e similares"
    },
    {
      codigoMCC: 6012,
      descricaoMCC: "Bancos / Lojas de Poupança e Instituiçoes Financei",
      codigoCnae: 6470101,
      descricaoCnae: "Fundos de investimento, exceto previdenciarios e imobiliarios"
    },
    {
      codigoMCC: 6012,
      descricaoMCC: "Bancos / Lojas de Poupança e Instituiçoes Financei",
      codigoCnae: 6461100,
      descricaoCnae: "Holdings de instituiçoes financeiras"
    },
    {
      codigoMCC: 5697,
      descricaoMCC: "Alfaiates / Costureiras (os)",
      codigoCnae: 1340599,
      descricaoCnae: "Outros serviços de acabamento em fios, tecidos, artefatos texteis e peças do vestuario"
    },
    {
      codigoMCC: 6012,
      descricaoMCC: "Bancos / Lojas de Poupança e Instituiçoes Financei",
      codigoCnae: 6424703,
      descricaoCnae: "Cooperativas de credito mutuo"
    },
    {
      codigoMCC: 7251,
      descricaoMCC: "Conserto de Chapeus / Sapatos",
      codigoCnae: 9529101,
      descricaoCnae: "Reparaçao de calçados, bolsas e artigos de viagem"
    },
    {
      codigoMCC: 6300,
      descricaoMCC: "Seguradoras",
      codigoCnae: 6622300,
      descricaoCnae: "Corretores e agentes de seguros, de planos de previdencia complementar e de saude"
    },
    {
      codigoMCC: 6513,
      descricaoMCC: "Imobiliarias",
      codigoCnae: 6822600,
      descricaoCnae: "Gestao e administraçao da propriedade imobiliaria"
    },
    {
      codigoMCC: 6513,
      descricaoMCC: "Imobiliarias",
      codigoCnae: 6810202,
      descricaoCnae: "Aluguel de imoveis proprios"
    },
    {
      codigoMCC: 6012,
      descricaoMCC: "Bancos / Lojas de Poupança e Instituiçoes Financei",
      codigoCnae: 6434400,
      descricaoCnae: "Agencias de fomento"
    },
    {
      codigoMCC: 5734,
      descricaoMCC: "Lojas de Informatica",
      codigoCnae: 4751202,
      descricaoCnae: "Lojas de Informatica"
    },
    {
      codigoMCC: 5734,
      descricaoMCC: "Lojas de Informatica",
      codigoCnae: 4751200,
      descricaoCnae: "Lojas de Informatica"
    },
    {
      codigoMCC: 5813,
      descricaoMCC: "Bares",
      codigoCnae: 5611202,
      descricaoCnae: "Bares"
    },
    {
      codigoMCC: 5932,
      descricaoMCC: "Antiguidades / Venda / Conserto",
      codigoCnae: 4785701,
      descricaoCnae: "Antiguidades / Venda / Conserto"
    },
    {
      codigoMCC: 5932,
      descricaoMCC: "Antiguidades / Venda / Conserto",
      codigoCnae: 9002702,
      descricaoCnae: "Antiguidades / Venda / Conserto"
    },
    {
      codigoMCC: 5935,
      descricaoMCC: "Ferro Velho",
      codigoCnae: 3831901,
      descricaoCnae: "Ferro Velho"
    },
    {
      codigoMCC: 5935,
      descricaoMCC: "Ferro Velho",
      codigoCnae: 3831999,
      descricaoCnae: "Ferro Velho"
    },
    {
      codigoMCC: 5948,
      descricaoMCC: "Lojas de Material de Couro",
      codigoCnae: 1510600,
      descricaoCnae: "Lojas de Material de Couro"
    },
    {
      codigoMCC: 5948,
      descricaoMCC: "Lojas de Material de Couro",
      codigoCnae: 1529700,
      descricaoCnae: "Lojas de Material de Couro"
    },
    {
      codigoMCC: 5971,
      descricaoMCC: "Galerias de Arte / Leilao de Arte",
      codigoCnae: 4212000,
      descricaoCnae: "Galerias de Arte / Leilao de Arte"
    },
    {
      codigoMCC: 5983,
      descricaoMCC: "Comercio de Combustiveis / Carvao e Lenha",
      codigoCnae: 500302,
      descricaoCnae: "Comercio de Combustiveis / Carvao e Lenha"
    },
    {
      codigoMCC: 5995,
      descricaoMCC: "Animais / Comidas e Suprimentos",
      codigoCnae: 9609203,
      descricaoCnae: "Animais / Comidas e Suprimentos"
    },
    {
      codigoMCC: 5999,
      descricaoMCC: "Lojas Especializadas (nao classificado em outro)",
      codigoCnae: 4789006,
      descricaoCnae: "Lojas Especializadas (nao classificado em outro)"
    },
    {
      codigoMCC: 6011,
      descricaoMCC: "Caixa Eletronico",
      codigoCnae: 6619304,
      descricaoCnae: "Caixa Eletronico"
    },
    {
      codigoMCC: 6012,
      descricaoMCC: "Bancos / Lojas de Poupança e Instituiçoes Financei",
      codigoCnae: 6422100,
      descricaoCnae: "Bancos / Lojas de Poupança e Instituiçoes Financei"
    },
    {
      codigoMCC: 6012,
      descricaoMCC: "Bancos / Lojas de Poupança e Instituiçoes Financei",
      codigoCnae: 6423900,
      descricaoCnae: "Bancos / Lojas de Poupança e Instituiçoes Financei"
    },
    {
      codigoMCC: 6012,
      descricaoMCC: "Bancos / Lojas de Poupança e Instituiçoes Financei",
      codigoCnae: 6424701,
      descricaoCnae: "Bancos / Lojas de Poupança e Instituiçoes Financei"
    },
    {
      codigoMCC: 6012,
      descricaoMCC: "Bancos / Lojas de Poupança e Instituiçoes Financei",
      codigoCnae: 6424702,
      descricaoCnae: "Bancos / Lojas de Poupança e Instituiçoes Financei"
    },
    {
      codigoMCC: 6012,
      descricaoMCC: "Bancos / Lojas de Poupança e Instituiçoes Financei",
      codigoCnae: 6424704,
      descricaoCnae: "Bancos / Lojas de Poupança e Instituiçoes Financei"
    },
    {
      codigoMCC: 6012,
      descricaoMCC: "Bancos / Lojas de Poupança e Instituiçoes Financei",
      codigoCnae: 6431000,
      descricaoCnae: "Bancos / Lojas de Poupança e Instituiçoes Financei"
    },
    {
      codigoMCC: 6012,
      descricaoMCC: "Bancos / Lojas de Poupança e Instituiçoes Financei",
      codigoCnae: 6432800,
      descricaoCnae: "Bancos / Lojas de Poupança e Instituiçoes Financei"
    },
    {
      codigoMCC: 6012,
      descricaoMCC: "Bancos / Lojas de Poupança e Instituiçoes Financei",
      codigoCnae: 6433600,
      descricaoCnae: "Bancos / Lojas de Poupança e Instituiçoes Financei"
    },
    {
      codigoMCC: 6012,
      descricaoMCC: "Bancos / Lojas de Poupança e Instituiçoes Financei",
      codigoCnae: 6435201,
      descricaoCnae: "Bancos / Lojas de Poupança e Instituiçoes Financei"
    },
    {
      codigoMCC: 6012,
      descricaoMCC: "Bancos / Lojas de Poupança e Instituiçoes Financei",
      codigoCnae: 6435202,
      descricaoCnae: "Bancos / Lojas de Poupança e Instituiçoes Financei"
    },
    {
      codigoMCC: 6012,
      descricaoMCC: "Bancos / Lojas de Poupança e Instituiçoes Financei",
      codigoCnae: 6435203,
      descricaoCnae: "Bancos / Lojas de Poupança e Instituiçoes Financei"
    },
    {
      codigoMCC: 6012,
      descricaoMCC: "Bancos / Lojas de Poupança e Instituiçoes Financei",
      codigoCnae: 6436100,
      descricaoCnae: "Bancos / Lojas de Poupança e Instituiçoes Financei"
    },
    {
      codigoMCC: 6012,
      descricaoMCC: "Bancos / Lojas de Poupança e Instituiçoes Financei",
      codigoCnae: 6437900,
      descricaoCnae: "Bancos / Lojas de Poupança e Instituiçoes Financei"
    },
    {
      codigoMCC: 6012,
      descricaoMCC: "Bancos / Lojas de Poupança e Instituiçoes Financei",
      codigoCnae: 6438701,
      descricaoCnae: "Bancos / Lojas de Poupança e Instituiçoes Financei"
    },
    {
      codigoMCC: 6012,
      descricaoMCC: "Bancos / Lojas de Poupança e Instituiçoes Financei",
      codigoCnae: 6438799,
      descricaoCnae: "Bancos / Lojas de Poupança e Instituiçoes Financei"
    },
    {
      codigoMCC: 6012,
      descricaoMCC: "Bancos / Lojas de Poupança e Instituiçoes Financei",
      codigoCnae: 6440900,
      descricaoCnae: "Bancos / Lojas de Poupança e Instituiçoes Financei"
    },
    {
      codigoMCC: 6012,
      descricaoMCC: "Bancos / Lojas de Poupança e Instituiçoes Financei",
      codigoCnae: 6450600,
      descricaoCnae: "Bancos / Lojas de Poupança e Instituiçoes Financei"
    },
    {
      codigoMCC: 6012,
      descricaoMCC: "Bancos / Lojas de Poupança e Instituiçoes Financei",
      codigoCnae: 6470102,
      descricaoCnae: "Bancos / Lojas de Poupança e Instituiçoes Financei"
    },
    {
      codigoMCC: 6012,
      descricaoMCC: "Bancos / Lojas de Poupança e Instituiçoes Financei",
      codigoCnae: 6470103,
      descricaoCnae: "Bancos / Lojas de Poupança e Instituiçoes Financei"
    },
    {
      codigoMCC: 6012,
      descricaoMCC: "Bancos / Lojas de Poupança e Instituiçoes Financei",
      codigoCnae: 6499903,
      descricaoCnae: "Bancos / Lojas de Poupança e Instituiçoes Financei"
    },
    {
      codigoMCC: 6012,
      descricaoMCC: "Bancos / Lojas de Poupança e Instituiçoes Financei",
      codigoCnae: 6499904,
      descricaoCnae: "Bancos / Lojas de Poupança e Instituiçoes Financei"
    },
    {
      codigoMCC: 6012,
      descricaoMCC: "Bancos / Lojas de Poupança e Instituiçoes Financei",
      codigoCnae: 6499999,
      descricaoCnae: "Bancos / Lojas de Poupança e Instituiçoes Financei"
    },
    {
      codigoMCC: 6012,
      descricaoMCC: "Bancos / Lojas de Poupança e Instituiçoes Financei",
      codigoCnae: 6619301,
      descricaoCnae: "Bancos / Lojas de Poupança e Instituiçoes Financei"
    },
    {
      codigoMCC: 6012,
      descricaoMCC: "Bancos / Lojas de Poupança e Instituiçoes Financei",
      codigoCnae: 6619303,
      descricaoCnae: "Bancos / Lojas de Poupança e Instituiçoes Financei"
    },
    {
      codigoMCC: 6051,
      descricaoMCC: "Casas de Cambio",
      codigoCnae: 6493000,
      descricaoCnae: "Casas de Cambio"
    },
    {
      codigoMCC: 6051,
      descricaoMCC: "Casas de Cambio",
      codigoCnae: 6499905,
      descricaoCnae: "Casas de Cambio"
    },
    {
      codigoMCC: 6211,
      descricaoMCC: "Corretor de Seguros",
      codigoCnae: 6499901,
      descricaoCnae: "Corretor de Seguros"
    },
    {
      codigoMCC: 6211,
      descricaoMCC: "Corretor de Seguros",
      codigoCnae: 6499902,
      descricaoCnae: "Corretor de Seguros"
    },
    {
      codigoMCC: 6211,
      descricaoMCC: "Corretor de Seguros",
      codigoCnae: 6611801,
      descricaoCnae: "Corretor de Seguros"
    },
    {
      codigoMCC: 6211,
      descricaoMCC: "Corretor de Seguros",
      codigoCnae: 6611802,
      descricaoCnae: "Corretor de Seguros"
    },
    {
      codigoMCC: 6211,
      descricaoMCC: "Corretor de Seguros",
      codigoCnae: 6611803,
      descricaoCnae: "Corretor de Seguros"
    },
    {
      codigoMCC: 6211,
      descricaoMCC: "Corretor de Seguros",
      codigoCnae: 6611804,
      descricaoCnae: "Corretor de Seguros"
    },
    {
      codigoMCC: 6211,
      descricaoMCC: "Corretor de Seguros",
      codigoCnae: 6612601,
      descricaoCnae: "Corretor de Seguros"
    },
    {
      codigoMCC: 6211,
      descricaoMCC: "Corretor de Seguros",
      codigoCnae: 6612602,
      descricaoCnae: "Corretor de Seguros"
    },
    {
      codigoMCC: 6300,
      descricaoMCC: "Seguradoras",
      codigoCnae: 6511101,
      descricaoCnae: "Seguradoras"
    },
    {
      codigoMCC: 6300,
      descricaoMCC: "Seguradoras",
      codigoCnae: 6511102,
      descricaoCnae: "Seguradoras"
    },
    {
      codigoMCC: 6300,
      descricaoMCC: "Seguradoras",
      codigoCnae: 6520100,
      descricaoCnae: "Seguradoras"
    },
    {
      codigoMCC: 6300,
      descricaoMCC: "Seguradoras",
      codigoCnae: 6530800,
      descricaoCnae: "Seguradoras"
    },
    {
      codigoMCC: 6300,
      descricaoMCC: "Seguradoras",
      codigoCnae: 6541300,
      descricaoCnae: "Seguradoras"
    },
    {
      codigoMCC: 6300,
      descricaoMCC: "Seguradoras",
      codigoCnae: 6542100,
      descricaoCnae: "Seguradoras"
    },
    {
      codigoMCC: 6300,
      descricaoMCC: "Seguradoras",
      codigoCnae: 6550200,
      descricaoCnae: "Seguradoras"
    },
    {
      codigoMCC: 6300,
      descricaoMCC: "Seguradoras",
      codigoCnae: 6621501,
      descricaoCnae: "Seguradoras"
    },
    {
      codigoMCC: 6300,
      descricaoMCC: "Seguradoras",
      codigoCnae: 6629100,
      descricaoCnae: "Seguradoras"
    },
    {
      codigoMCC: 6513,
      descricaoMCC: "Imobiliarias",
      codigoCnae: 6810203,
      descricaoCnae: "Imobiliarias"
    },
    {
      codigoMCC: 7011,
      descricaoMCC: "Hoteis",
      codigoCnae: 5510802,
      descricaoCnae: "Hoteis"
    },
    {
      codigoMCC: 7011,
      descricaoMCC: "Hoteis",
      codigoCnae: 5510803,
      descricaoCnae: "Hoteis"
    },
    {
      codigoMCC: 7011,
      descricaoMCC: "Hoteis",
      codigoCnae: 5590601,
      descricaoCnae: "Hoteis"
    },
    {
      codigoMCC: 7011,
      descricaoMCC: "Hoteis",
      codigoCnae: 5590603,
      descricaoCnae: "Hoteis"
    },
    {
      codigoMCC: 7011,
      descricaoMCC: "Hoteis",
      codigoCnae: 5590699,
      descricaoCnae: "Hoteis"
    },
    {
      codigoMCC: 7033,
      descricaoMCC: "Camping e Estacionamento de Trailers",
      codigoCnae: 5590602,
      descricaoCnae: "Camping e Estacionamento de Trailers"
    },
    {
      codigoMCC: 7210,
      descricaoMCC: "Lavanderias",
      codigoCnae: 9601703,
      descricaoCnae: "Lavanderias"
    },
    {
      codigoMCC: 7216,
      descricaoMCC: "Tinturaria",
      codigoCnae: 9601701,
      descricaoCnae: "Tinturaria"
    },
    {
      codigoMCC: 7216,
      descricaoMCC: "Tinturaria",
      codigoCnae: 9601702,
      descricaoCnae: "Tinturaria"
    },
    {
      codigoMCC: 7261,
      descricaoMCC: "Crematorios / Funerarias",
      codigoCnae: 9603301,
      descricaoCnae: "Crematorios / Funerarias"
    },
    {
      codigoMCC: 7261,
      descricaoMCC: "Crematorios / Funerarias",
      codigoCnae: 9603302,
      descricaoCnae: "Crematorios / Funerarias"
    },
    {
      codigoMCC: 7261,
      descricaoMCC: "Crematorios / Funerarias",
      codigoCnae: 9603303,
      descricaoCnae: "Crematorios / Funerarias"
    },
    {
      codigoMCC: 7261,
      descricaoMCC: "Crematorios / Funerarias",
      codigoCnae: 9603305,
      descricaoCnae: "Crematorios / Funerarias"
    },
    {
      codigoMCC: 7261,
      descricaoMCC: "Crematorios / Funerarias",
      codigoCnae: 9603399,
      descricaoCnae: "Crematorios / Funerarias"
    },
    {
      codigoMCC: 7273,
      descricaoMCC: "Acompanhante",
      codigoCnae: 9609202,
      descricaoCnae: "Acompanhante"
    },
    {
      codigoMCC: 7379,
      descricaoMCC: "Conserto e Manutençao de Computadores",
      codigoCnae: 6209100,
      descricaoCnae: "SUPORTE TECNICO, MANUTENCAO E OUTROS SERVICOS EM TECNOLOGIA DA INFORMACAO"
    },
    {
      codigoMCC: 7311,
      descricaoMCC: "Anuncios / Classificados / Propaganda",
      codigoCnae: 7319099,
      descricaoCnae: "Outras atividades de publicidade nao especificadas anteriormente"
    },
    {
      codigoMCC: 7399,
      descricaoMCC: "Comerciais - Nao Classificados Anteriormente",
      codigoCnae: 8291100,
      descricaoCnae: "Atividades de cobranças e informaçoes cadastrais"
    },
    {
      codigoMCC: 7372,
      descricaoMCC: "Processamento de Dados e Serviços",
      codigoCnae: 6202300,
      descricaoCnae: "Desenvolvimento e licenciamento de programas de computador customizaveis"
    },
    {
      codigoMCC: 7394,
      descricaoMCC: "Aluguel de Maquinas e Equipamentos",
      codigoCnae: 7733100,
      descricaoCnae: "Aluguel de maquinas e equipamentos para escritorios"
    },
    {
      codigoMCC: 7299,
      descricaoMCC: "Estudio de Tatuagem e Tatuadores",
      codigoCnae: 8299799,
      descricaoCnae: "Outras atividades de serviços prestados principalmente as empresas nao especificadas anteriormente"
    },
    {
      codigoMCC: 7299,
      descricaoMCC: "Estudio de Tatuagem e Tatuadores",
      codigoCnae: 6399200,
      descricaoCnae: "Outras atividades de prestaçao de serviços de informaçao nao especificadas anteriormente"
    },
    {
      codigoMCC: 7392,
      descricaoMCC: "Serviços de Administraçao e Consultoria",
      codigoCnae: 8211300,
      descricaoCnae: "Serviços combinados de escritorio e apoio administrativo"
    },
    {
      codigoMCC: 7392,
      descricaoMCC: "Serviços de Administraçao e Consultoria",
      codigoCnae: 7020400,
      descricaoCnae: "Atividades de consultoria em gestao empresarial, exceto consultoria tecnica especifica"
    },
    {
      codigoMCC: 7531,
      descricaoMCC: "Funilaria / Lanternagem",
      codigoCnae: 4520002,
      descricaoCnae: "Serviços de lanternagem ou funilaria e pintura de veiculos automotores"
    },
    {
      codigoMCC: 7538,
      descricaoMCC: "Oficinas Mecanicas",
      codigoCnae: 4520001,
      descricaoCnae: "Serviços de manutençao e reparaçao mecanica de veiculos automotores"
    },
    {
      codigoMCC: 7538,
      descricaoMCC: "Oficinas Mecanicas",
      codigoCnae: 4520003,
      descricaoCnae: "Serviços de manutençao e reparaçao eletrica de veiculos automotores"
    },
    {
      codigoMCC: 7311,
      descricaoMCC: "Anuncios / Classificados / Propaganda",
      codigoCnae: 7311400,
      descricaoCnae: "Agencias de publicidade"
    },
    {
      codigoMCC: 7392,
      descricaoMCC: "Serviços de Administraçao e Consultoria",
      codigoCnae: 7740300,
      descricaoCnae: "Gestao de ativos intangiveis nao-financeiros"
    },
    {
      codigoMCC: 7372,
      descricaoMCC: "Processamento de Dados e Serviços",
      codigoCnae: 6203100,
      descricaoCnae: "Desenvolvimento e licenciamento de programas de computador nao-customizaveis"
    },
    {
      codigoMCC: 7349,
      descricaoMCC: "Serviços de Manutençao e Conserva",
      codigoCnae: 8129000,
      descricaoCnae: "Atividades de limpeza nao especificadas anteriormente"
    },
    {
      codigoMCC: 7542,
      descricaoMCC: "Lava Rapido",
      codigoCnae: 4520005,
      descricaoCnae: "Serviços de lavagem, lubrificaçao e polimento de veiculos automotores"
    },
    {
      codigoMCC: 7399,
      descricaoMCC: "Comerciais - Nao Classificados Anteriormente",
      codigoCnae: 8219999,
      descricaoCnae: "Preparaçao de documentos e serviços especializados de apoio administrativo nao especificados anteriormente"
    },
    {
      codigoMCC: 7311,
      descricaoMCC: "Anuncios / Classificados / Propaganda",
      codigoCnae: 7319002,
      descricaoCnae: "Promoçao de vendas"
    },
    {
      codigoMCC: 7538,
      descricaoMCC: "Oficinas Mecanicas",
      codigoCnae: 4543900,
      descricaoCnae: "Manutençao e reparaçao de motocicletas e motonetas"
    },
    {
      codigoMCC: 7393,
      descricaoMCC: "Carros Blindados / Detetives / Proteçao",
      codigoCnae: 8020001,
      descricaoCnae: "Atividades de monitoramento de sistemas de segurança eletronico"
    },
    {
      codigoMCC: 7379,
      descricaoMCC: "Conserto e Manutençao de Computadores",
      codigoCnae: 9511800,
      descricaoCnae: "Reparaçao e manutençao de computadores e de equipamentos perifericos"
    },
    {
      codigoMCC: 7512,
      descricaoMCC: "Locadoras de Veiculos",
      codigoCnae: 7711000,
      descricaoCnae: "Locaçao de automoveis sem condutor"
    },
    {
      codigoMCC: 7298,
      descricaoMCC: "Clinicas de Beleza - SPA",
      codigoCnae: 9602502,
      descricaoCnae: "Atividades de estetica e outros serviços de cuidados com a beleza"
    },
    {
      codigoMCC: 7372,
      descricaoMCC: "Processamento de Dados e Serviços",
      codigoCnae: 6204000,
      descricaoCnae: "Consultoria em tecnologia da informaçao"
    },
    {
      codigoMCC: 7372,
      descricaoMCC: "Processamento de Dados e Serviços",
      codigoCnae: 6201501,
      descricaoCnae: "Desenvolvimento de programas de computador sob encomenda"
    },
    {
      codigoMCC: 7399,
      descricaoMCC: "Comerciais - Nao Classificados Anteriormente",
      codigoCnae: 3321000,
      descricaoCnae: "Instalaçao de maquinas e equipamentos industriais"
    },
    {
      codigoMCC: 7699,
      descricaoMCC: "Lojas de Reparos e Serviços Relacionados",
      codigoCnae: 3313999,
      descricaoCnae: "Manutençao e reparaçao de maquinas, aparelhos e materiais eletricos nao especificados anteriormente"
    },
    {
      codigoMCC: 7629,
      descricaoMCC: "Consertos Eletricos",
      codigoCnae: 9521500,
      descricaoCnae: "Reparaçao e manutençao de equipamentos eletroeletronicos de uso pessoal e domestico"
    },
    {
      codigoMCC: 7299,
      descricaoMCC: "Estudio de Tatuagem e Tatuadores",
      codigoCnae: 4329101,
      descricaoCnae: "Instalaçao de paineis publicitarios"
    },
    {
      codigoMCC: 7641,
      descricaoMCC: "Reparo e Restauraçao de Moveis",
      codigoCnae: 9529105,
      descricaoCnae: "Reparaçao de artigos do mobiliario"
    },
    {
      codigoMCC: 7299,
      descricaoMCC: "Estudio de Tatuagem e Tatuadores",
      codigoCnae: 3329599,
      descricaoCnae: "Instalaçao de outros equipamentos nao especificados anteriormente"
    },
    {
      codigoMCC: 7699,
      descricaoMCC: "Lojas de Reparos e Serviços Relacionados",
      codigoCnae: 3314706,
      descricaoCnae: "Manutençao e reparaçao de maquinas, aparelhos e equipamentos para instalaçoes termicas"
    },
    {
      codigoMCC: 7394,
      descricaoMCC: "Aluguel de Maquinas e Equipamentos",
      codigoCnae: 7739099,
      descricaoCnae: "Aluguel de outras maquinas e equipamentos comerciais e industriais nao especificados anteriormente, sem operador"
    },
    {
      codigoMCC: 7699,
      descricaoMCC: "Lojas de Reparos e Serviços Relacionados",
      codigoCnae: 3313901,
      descricaoCnae: "Manutençao e reparaçao de geradores, transformadores e motores eletricos"
    },
    {
      codigoMCC: 7361,
      descricaoMCC: "Agencia de Empregos e Serviços Temporarios",
      codigoCnae: 7820500,
      descricaoCnae: "Locaçao de mao-de-obra temporaria"
    },
    {
      codigoMCC: 7299,
      descricaoMCC: "Estudio de Tatuagem e Tatuadores",
      codigoCnae: 5920100,
      descricaoCnae: "Atividades de gravaçao de som e de ediçao de musica"
    },
    {
      codigoMCC: 7311,
      descricaoMCC: "Anuncios / Classificados / Propaganda",
      codigoCnae: 7312200,
      descricaoCnae: "Agenciamento de espaços para publicidade, exceto em veiculos de comunicaçao"
    },
    {
      codigoMCC: 7311,
      descricaoMCC: "Anuncios / Classificados / Propaganda",
      codigoCnae: 7319004,
      descricaoCnae: "Consultoria em publicidade"
    },
    {
      codigoMCC: 7299,
      descricaoMCC: "Estudio de Tatuagem e Tatuadores",
      codigoCnae: 9609299,
      descricaoCnae: "Outras atividades de serviços pessoais nao especificadas anteriormente (Nao dispensada *)"
    },
    {
      codigoMCC: 7349,
      descricaoMCC: "Serviços de Manutençao e Conserva",
      codigoCnae: 8121400,
      descricaoCnae: "Limpeza em predios e em domicilios (Nao dispensada *)"
    },
    {
      codigoMCC: 7699,
      descricaoMCC: "Lojas de Reparos e Serviços Relacionados",
      codigoCnae: 3314712,
      descricaoCnae: "Manutençao e reparaçao de tratores agricolas"
    },
    {
      codigoMCC: 7394,
      descricaoMCC: "Aluguel de Maquinas e Equipamentos",
      codigoCnae: 7732201,
      descricaoCnae: "Aluguel de maquinas e equipamentos para construçao sem operador, exceto andaimes"
    },
    {
      codigoMCC: 7394,
      descricaoMCC: "Aluguel de Maquinas e Equipamentos",
      codigoCnae: 7729202,
      descricaoCnae: "Aluguel de moveis, utensilios e aparelhos de uso domestico e pessoal; instrumentos musicais"
    },
    {
      codigoMCC: 7299,
      descricaoMCC: "Estudio de Tatuagem e Tatuadores",
      codigoCnae: 9609206,
      descricaoCnae: "Serviços de tatuagem e colocaçao de piercing"
    },
    {
      codigoMCC: 7299,
      descricaoMCC: "Estudio de Tatuagem e Tatuadores",
      codigoCnae: 2399101,
      descricaoCnae: "Decoraçao, lapidaçao, gravaçao, vitrificaçao e outros trabalhos em ceramica, louça, vidro e cristal"
    },
    {
      codigoMCC: 7699,
      descricaoMCC: "Lojas de Reparos e Serviços Relacionados",
      codigoCnae: 3319800,
      descricaoCnae: "Manutençao e reparaçao de equipamentos e produtos nao especificados anteriormente"
    },
    {
      codigoMCC: 7338,
      descricaoMCC: "Graficas",
      codigoCnae: 1822999,
      descricaoCnae: "Serviços de acabamentos graficos, exceto encadernaçao e plastificaçao"
    },
    {
      codigoMCC: 7622,
      descricaoMCC: "Radio / TV / Aparelho de Som - Consertos",
      codigoCnae: 9512600,
      descricaoCnae: "Reparaçao e manutençao de equipamentos de comunicaçao"
    },
    {
      codigoMCC: 7299,
      descricaoMCC: "Estudio de Tatuagem e Tatuadores",
      codigoCnae: 3329501,
      descricaoCnae: "Serviços de montagem de moveis de qualquer material"
    },
    {
      codigoMCC: 7399,
      descricaoMCC: "Comerciais - Nao Classificados Anteriormente",
      codigoCnae: 5250801,
      descricaoCnae: "Comissaria de despachos"
    },
    {
      codigoMCC: 7399,
      descricaoMCC: "Comerciais - Nao Classificados Anteriormente",
      codigoCnae: 9529102,
      descricaoCnae: "Chaveiros"
    },
    {
      codigoMCC: 7299,
      descricaoMCC: "Estudio de Tatuagem e Tatuadores",
      codigoCnae: 1830001,
      descricaoCnae: "Reproduçao de som em qualquer suporte"
    },
    {
      codigoMCC: 7534,
      descricaoMCC: "Borracheiro",
      codigoCnae: 4520006,
      descricaoCnae: "Serviços de borracharia para veiculos automotores"
    },
    {
      codigoMCC: 7538,
      descricaoMCC: "Oficinas Mecanicas",
      codigoCnae: 4520004,
      descricaoCnae: "Serviços de alinhamento e balanceamento de veiculos automotores"
    },
    {
      codigoMCC: 7699,
      descricaoMCC: "Lojas de Reparos e Serviços Relacionados",
      codigoCnae: 3312102,
      descricaoCnae: "Manutençao e reparaçao de aparelhos e instrumentos de medida, teste e controle"
    },
    {
      codigoMCC: 7699,
      descricaoMCC: "Lojas de Reparos e Serviços Relacionados",
      codigoCnae: 3314713,
      descricaoCnae: "Manutençao e reparaçao de maquinas-ferramenta"
    },
    {
      codigoMCC: 7299,
      descricaoMCC: "Estudio de Tatuagem e Tatuadores",
      codigoCnae: 7420005,
      descricaoCnae: "Serviços de microfilmagem"
    },
    {
      codigoMCC: 7338,
      descricaoMCC: "Graficas",
      codigoCnae: 8219901,
      descricaoCnae: "Fotocopias"
    },
    {
      codigoMCC: 7549,
      descricaoMCC: "Reboque",
      codigoCnae: 5229002,
      descricaoCnae: "Serviços de reboque de veiculos"
    },
    {
      codigoMCC: 7699,
      descricaoMCC: "Lojas de Reparos e Serviços Relacionados",
      codigoCnae: 3314702,
      descricaoCnae: "Manutençao e reparaçao de equipamentos hidraulicos e pneumaticos, exceto valvulas"
    },
    {
      codigoMCC: 7699,
      descricaoMCC: "Lojas de Reparos e Serviços Relacionados",
      codigoCnae: 3313902,
      descricaoCnae: "Manutençao e reparaçao de baterias e acumuladores eletricos, exceto para veiculos"
    },
    {
      codigoMCC: 7523,
      descricaoMCC: "Estacionamento de Automoveis",
      codigoCnae: 5223100,
      descricaoCnae: "Estacionamento de veiculos"
    },
    {
      codigoMCC: 7299,
      descricaoMCC: "Estudio de Tatuagem e Tatuadores",
      codigoCnae: 9002701,
      descricaoCnae: "Atividades de artistas plasticos, jornalistas independentes e escritores"
    },
    {
      codigoMCC: 7399,
      descricaoMCC: "Comerciais - Nao Classificados Anteriormente",
      codigoCnae: 7490101,
      descricaoCnae: "Serviços de traduçao, interpretaçao e similares"
    },
    {
      codigoMCC: 7699,
      descricaoMCC: "Lojas de Reparos e Serviços Relacionados",
      codigoCnae: 3314718,
      descricaoCnae: "Manutençao e reparaçao de maquinas para a industria metalurgica, exceto maquinas-ferramenta"
    },
    {
      codigoMCC: 7538,
      descricaoMCC: "Oficinas Mecanicas",
      codigoCnae: 2950600,
      descricaoCnae: "Recondicionamento e recuperaçao de motores para veiculos automotores"
    },
    {
      codigoMCC: 7299,
      descricaoMCC: "Estudio de Tatuagem e Tatuadores",
      codigoCnae: 4399104,
      descricaoCnae: "Serviços de operaçao e fornecimento de equipamentos para transporte e elevaçao de cargas e pessoas para uso em obras"
    },
    {
      codigoMCC: 7361,
      descricaoMCC: "Agencia de Empregos e Serviços Temporarios",
      codigoCnae: 7810800,
      descricaoCnae: "Seleçao e agenciamento de mao-de-obra"
    },
    {
      codigoMCC: 7342,
      descricaoMCC: "Dedetizaçao",
      codigoCnae: 8122200,
      descricaoCnae: "Imunizaçao e controle de pragas urbanas"
    },
    {
      codigoMCC: 7699,
      descricaoMCC: "Lojas de Reparos e Serviços Relacionados",
      codigoCnae: 3314799,
      descricaoCnae: "Manutençao e reparaçao de outras maquinas e equipamentos para usos industriais nao especificados anteriormente"
    },
    {
      codigoMCC: 7299,
      descricaoMCC: "Estudio de Tatuagem e Tatuadores",
      codigoCnae: 9609207,
      descricaoCnae: "Alojamento de animais domesticos"
    },
    {
      codigoMCC: 7394,
      descricaoMCC: "Aluguel de Maquinas e Equipamentos",
      codigoCnae: 7739003,
      descricaoCnae: "Aluguel de palcos, coberturas e outras estruturas de uso temporario, exceto andaimes"
    },
    {
      codigoMCC: 7349,
      descricaoMCC: "Serviços de Manutençao e Conserva",
      codigoCnae: 8111700,
      descricaoCnae: "Serviços combinados para apoio a edificios, exceto condominios prediais"
    },
    {
      codigoMCC: 7699,
      descricaoMCC: "Lojas de Reparos e Serviços Relacionados",
      codigoCnae: 3314709,
      descricaoCnae: "Manutençao e reparaçao de maquinas de escrever, calcular e de outros equipamentos nao-eletronicos para escritorio"
    },
    {
      codigoMCC: 7399,
      descricaoMCC: "Comerciais - Nao Classificados Anteriormente",
      codigoCnae: 3832700,
      descricaoCnae: "Recuperaçao de materiais plasticos"
    },
    {
      codigoMCC: 7699,
      descricaoMCC: "Lojas de Reparos e Serviços Relacionados",
      codigoCnae: 3311200,
      descricaoCnae: "Manutençao e reparaçao de tanques, reservatorios metalicos e caldeiras, exceto para veiculos"
    },
    {
      codigoMCC: 7699,
      descricaoMCC: "Lojas de Reparos e Serviços Relacionados",
      codigoCnae: 3312103,
      descricaoCnae: "Manutençao e reparaçao de aparelhos eletromedicos e eletroterapeuticos e equipamentos de irradiaçao"
    },
    {
      codigoMCC: 7699,
      descricaoMCC: "Lojas de Reparos e Serviços Relacionados",
      codigoCnae: 3314717,
      descricaoCnae: "Manutençao e reparaçao de maquinas e equipamentos de terraplenagem, pavimentaçao e construçao, exceto tratores"
    },
    {
      codigoMCC: 7512,
      descricaoMCC: "Locadoras de Veiculos",
      codigoCnae: 7719599,
      descricaoCnae: "Locaçao de outros meios de transporte nao especificados anteriormente, sem condutor"
    },
    {
      codigoMCC: 7699,
      descricaoMCC: "Lojas de Reparos e Serviços Relacionados",
      codigoCnae: 3314701,
      descricaoCnae: "Manutençao e reparaçao de maquinas motrizes nao-eletricas"
    },
    {
      codigoMCC: 7399,
      descricaoMCC: "Comerciais - Nao Classificados Anteriormente",
      codigoCnae: 7719502,
      descricaoCnae: "Locaçao de aeronaves sem tripulaçao"
    },
    {
      codigoMCC: 7699,
      descricaoMCC: "Lojas de Reparos e Serviços Relacionados",
      codigoCnae: 3314710,
      descricaoCnae: "Manutençao e reparaçao de maquinas e equipamentos para uso geral nao especificados anteriormente"
    },
    {
      codigoMCC: 7394,
      descricaoMCC: "Aluguel de Maquinas e Equipamentos",
      codigoCnae: 7731400,
      descricaoCnae: "Aluguel de maquinas e equipamentos agricolas sem operador"
    },
    {
      codigoMCC: 7534,
      descricaoMCC: "Borracheiro",
      codigoCnae: 2212900,
      descricaoCnae: "Reforma de pneumaticos usados"
    },
    {
      codigoMCC: 7699,
      descricaoMCC: "Lojas de Reparos e Serviços Relacionados",
      codigoCnae: 3314711,
      descricaoCnae: "Manutençao e reparaçao de maquinas e equipamentos para agricultura e pecuaria"
    },
    {
      codigoMCC: 7297,
      descricaoMCC: "Casas de Massagem e Saunas",
      codigoCnae: 9609205,
      descricaoCnae: "Casas de Massagem e Saunas"
    },
    {
      codigoMCC: 7298,
      descricaoMCC: "Clinicas de Beleza - SPA",
      codigoCnae: 9609201,
      descricaoCnae: "Clinicas de Beleza - SPA"
    },
    {
      codigoMCC: 7299,
      descricaoMCC: "Estudio de Tatuagem e Tatuadores",
      codigoCnae: 9609204,
      descricaoCnae: "Estudio de Tatuagem e Tatuadores"
    },
    {
      codigoMCC: 7299,
      descricaoMCC: "Estudio de Tatuagem e Tatuadores",
      codigoCnae: 5231101,
      descricaoCnae: "Estudio de Tatuagem e Tatuadores"
    },
    {
      codigoMCC: 7299,
      descricaoMCC: "Estudio de Tatuagem e Tatuadores",
      codigoCnae: 6911703,
      descricaoCnae: "Estudio de Tatuagem e Tatuadores"
    },
    {
      codigoMCC: 7311,
      descricaoMCC: "Anuncios / Classificados / Propaganda",
      codigoCnae: 7319001,
      descricaoCnae: "Anuncios / Classificados / Propaganda"
    },
    {
      codigoMCC: 7338,
      descricaoMCC: "Graficas",
      codigoCnae: 1822901,
      descricaoCnae: "Graficas"
    },
    {
      codigoMCC: 7338,
      descricaoMCC: "Graficas",
      codigoCnae: 1822900,
      descricaoCnae: "Graficas"
    },
    {
      codigoMCC: 7349,
      descricaoMCC: "Serviços de Manutençao e Conserva",
      codigoCnae: 8112500,
      descricaoCnae: "Serviços de Manutençao e Conserva"
    },
    {
      codigoMCC: 7349,
      descricaoMCC: "Serviços de Manutençao e Conserva",
      codigoCnae: 9700500,
      descricaoCnae: "Serviços de Manutençao e Conserva"
    },
    {
      codigoMCC: 7361,
      descricaoMCC: "Agencia de Empregos e Serviços Temporarios",
      codigoCnae: 7830200,
      descricaoCnae: "Agencia de Empregos e Serviços Temporarios"
    },
    {
      codigoMCC: 7372,
      descricaoMCC: "Processamento de Dados e Serviços",
      codigoCnae: 6201500,
      descricaoCnae: "Processamento de Dados e Serviços"
    },
    {
      codigoMCC: 7393,
      descricaoMCC: "Carros Blindados / Detetives / Proteçao",
      codigoCnae: 8011101,
      descricaoCnae: "Carros Blindados / Detetives / Proteçao"
    },
    {
      codigoMCC: 7393,
      descricaoMCC: "Carros Blindados / Detetives / Proteçao",
      codigoCnae: 8011102,
      descricaoCnae: "Carros Blindados / Detetives / Proteçao"
    },
    {
      codigoMCC: 7393,
      descricaoMCC: "Carros Blindados / Detetives / Proteçao",
      codigoCnae: 8012900,
      descricaoCnae: "Carros Blindados / Detetives / Proteçao"
    },
    {
      codigoMCC: 7393,
      descricaoMCC: "Carros Blindados / Detetives / Proteçao",
      codigoCnae: 8020002,
      descricaoCnae: "Carros Blindados / Detetives / Proteçao"
    },
    {
      codigoMCC: 7393,
      descricaoMCC: "Carros Blindados / Detetives / Proteçao",
      codigoCnae: 8030700,
      descricaoCnae: "Carros Blindados / Detetives / Proteçao"
    },
    {
      codigoMCC: 7393,
      descricaoMCC: "Carros Blindados / Detetives / Proteçao",
      codigoCnae: 8020000,
      descricaoCnae: "Carros Blindados / Detetives / Proteçao"
    },
    {
      codigoMCC: 7394,
      descricaoMCC: "Aluguel de Maquinas e Equipamentos",
      codigoCnae: 7729203,
      descricaoCnae: "Aluguel de Maquinas e Equipamentos"
    },
    {
      codigoMCC: 7394,
      descricaoMCC: "Aluguel de Maquinas e Equipamentos",
      codigoCnae: 7729299,
      descricaoCnae: "Aluguel de Maquinas e Equipamentos"
    },
    {
      codigoMCC: 7394,
      descricaoMCC: "Aluguel de Maquinas e Equipamentos",
      codigoCnae: 7732202,
      descricaoCnae: "Aluguel de Maquinas e Equipamentos"
    },
    {
      codigoMCC: 7394,
      descricaoMCC: "Aluguel de Maquinas e Equipamentos",
      codigoCnae: 7739001,
      descricaoCnae: "Aluguel de Maquinas e Equipamentos"
    },
    {
      codigoMCC: 7394,
      descricaoMCC: "Aluguel de Maquinas e Equipamentos",
      codigoCnae: 7739002,
      descricaoCnae: "Aluguel de Maquinas e Equipamentos"
    },
    {
      codigoMCC: 7395,
      descricaoMCC: "Laboratorios Fotograficos",
      codigoCnae: 7420003,
      descricaoCnae: "Laboratorios Fotograficos"
    },
    {
      codigoMCC: 7399,
      descricaoMCC: "Comerciais - Nao Classificados Anteriormente",
      codigoCnae: 3839401,
      descricaoCnae: "Comerciais - Nao Classificados Anteriormente"
    },
    {
      codigoMCC: 7399,
      descricaoMCC: "Comerciais - Nao Classificados Anteriormente",
      codigoCnae: 3839499,
      descricaoCnae: "Comerciais - Nao Classificados Anteriormente"
    },
    {
      codigoMCC: 7399,
      descricaoMCC: "Comerciais - Nao Classificados Anteriormente",
      codigoCnae: 5030103,
      descricaoCnae: "Comerciais - Nao Classificados Anteriormente"
    },
    {
      codigoMCC: 7399,
      descricaoMCC: "Comerciais - Nao Classificados Anteriormente",
      codigoCnae: 5231102,
      descricaoCnae: "Comerciais - Nao Classificados Anteriormente"
    },
    {
      codigoMCC: 7399,
      descricaoMCC: "Comerciais - Nao Classificados Anteriormente",
      codigoCnae: 5231103,
      descricaoCnae: "Comerciais - Nao Classificados Anteriormente"
    },
    {
      codigoMCC: 7399,
      descricaoMCC: "Comerciais - Nao Classificados Anteriormente",
      codigoCnae: 5239701,
      descricaoCnae: "Comerciais - Nao Classificados Anteriormente"
    },
    {
      codigoMCC: 7399,
      descricaoMCC: "Comerciais - Nao Classificados Anteriormente",
      codigoCnae: 5239799,
      descricaoCnae: "Comerciais - Nao Classificados Anteriormente"
    },
    {
      codigoMCC: 7399,
      descricaoMCC: "Comerciais - Nao Classificados Anteriormente",
      codigoCnae: 5250802,
      descricaoCnae: "Comerciais - Nao Classificados Anteriormente"
    },
    {
      codigoMCC: 7399,
      descricaoMCC: "Comerciais - Nao Classificados Anteriormente",
      codigoCnae: 8292000,
      descricaoCnae: "Comerciais - Nao Classificados Anteriormente"
    },
    {
      codigoMCC: 7399,
      descricaoMCC: "Comerciais - Nao Classificados Anteriormente",
      codigoCnae: 8299702,
      descricaoCnae: "Comerciais - Nao Classificados Anteriormente"
    },
    {
      codigoMCC: 7399,
      descricaoMCC: "Comerciais - Nao Classificados Anteriormente",
      codigoCnae: 5239700,
      descricaoCnae: "Comerciais - Nao Classificados Anteriormente"
    },
    {
      codigoMCC: 7538,
      descricaoMCC: "Oficinas Mecanicas",
      codigoCnae: 2722802,
      descricaoCnae: "Oficinas Mecanicas"
    },
    {
      codigoMCC: 7631,
      descricaoMCC: "Joias e Relogios - Consertos",
      codigoCnae: 9529103,
      descricaoCnae: "Joias e Relogios - Consertos"
    },
    {
      codigoMCC: 7631,
      descricaoMCC: "Joias e Relogios - Consertos",
      codigoCnae: 9529106,
      descricaoCnae: "Joias e Relogios - Consertos"
    },
    {
      codigoMCC: 7699,
      descricaoMCC: "Lojas de Reparos e Serviços Relacionados",
      codigoCnae: 3312104,
      descricaoCnae: "Lojas de Reparos e Serviços Relacionados"
    },
    {
      codigoMCC: 7699,
      descricaoMCC: "Lojas de Reparos e Serviços Relacionados",
      codigoCnae: 3314703,
      descricaoCnae: "Lojas de Reparos e Serviços Relacionados"
    },
    {
      codigoMCC: 7699,
      descricaoMCC: "Lojas de Reparos e Serviços Relacionados",
      codigoCnae: 3314704,
      descricaoCnae: "Lojas de Reparos e Serviços Relacionados"
    },
    {
      codigoMCC: 7699,
      descricaoMCC: "Lojas de Reparos e Serviços Relacionados",
      codigoCnae: 3314705,
      descricaoCnae: "Lojas de Reparos e Serviços Relacionados"
    },
    {
      codigoMCC: 7699,
      descricaoMCC: "Lojas de Reparos e Serviços Relacionados",
      codigoCnae: 3314707,
      descricaoCnae: "Lojas de Reparos e Serviços Relacionados"
    },
    {
      codigoMCC: 7699,
      descricaoMCC: "Lojas de Reparos e Serviços Relacionados",
      codigoCnae: 3314708,
      descricaoCnae: "Lojas de Reparos e Serviços Relacionados"
    },
    {
      codigoMCC: 7699,
      descricaoMCC: "Lojas de Reparos e Serviços Relacionados",
      codigoCnae: 3314714,
      descricaoCnae: "Lojas de Reparos e Serviços Relacionados"
    },
    {
      codigoMCC: 7699,
      descricaoMCC: "Lojas de Reparos e Serviços Relacionados",
      codigoCnae: 3314715,
      descricaoCnae: "Lojas de Reparos e Serviços Relacionados"
    },
    {
      codigoMCC: 7699,
      descricaoMCC: "Lojas de Reparos e Serviços Relacionados",
      codigoCnae: 3314716,
      descricaoCnae: "Lojas de Reparos e Serviços Relacionados"
    },
    {
      codigoMCC: 7699,
      descricaoMCC: "Lojas de Reparos e Serviços Relacionados",
      codigoCnae: 3314719,
      descricaoCnae: "Lojas de Reparos e Serviços Relacionados"
    },
    {
      codigoMCC: 7699,
      descricaoMCC: "Lojas de Reparos e Serviços Relacionados",
      codigoCnae: 3314720,
      descricaoCnae: "Lojas de Reparos e Serviços Relacionados"
    },
    {
      codigoMCC: 7699,
      descricaoMCC: "Lojas de Reparos e Serviços Relacionados",
      codigoCnae: 3314721,
      descricaoCnae: "Lojas de Reparos e Serviços Relacionados"
    },
    {
      codigoMCC: 7699,
      descricaoMCC: "Lojas de Reparos e Serviços Relacionados",
      codigoCnae: 3314722,
      descricaoCnae: "Lojas de Reparos e Serviços Relacionados"
    },
    {
      codigoMCC: 7699,
      descricaoMCC: "Lojas de Reparos e Serviços Relacionados",
      codigoCnae: 3315500,
      descricaoCnae: "Lojas de Reparos e Serviços Relacionados"
    },
    {
      codigoMCC: 7699,
      descricaoMCC: "Lojas de Reparos e Serviços Relacionados",
      codigoCnae: 3316301,
      descricaoCnae: "Lojas de Reparos e Serviços Relacionados"
    },
    {
      codigoMCC: 7699,
      descricaoMCC: "Lojas de Reparos e Serviços Relacionados",
      codigoCnae: 3316302,
      descricaoCnae: "Lojas de Reparos e Serviços Relacionados"
    },
    {
      codigoMCC: 8244,
      descricaoMCC: "Serviços Escolares",
      codigoCnae: 8599603,
      descricaoCnae: "TREINAMENTO EM INFORMATICA"
    },
    {
      codigoMCC: 8299,
      descricaoMCC: "Escolas e Cursos em Geral",
      codigoCnae: 8599605,
      descricaoCnae: "Cursos preparatorios para concursos"
    },
    {
      codigoMCC: 8641,
      descricaoMCC: "Associaçao Civica e Social",
      codigoCnae: 9430800,
      descricaoCnae: "Atividades de associaçoes de defesa de direitos sociais"
    },
    {
      codigoMCC: 8699,
      descricaoMCC: "Sindicatos Trabalhistas e Organizaçoes Profissiona",
      codigoCnae: 9493600,
      descricaoCnae: "Atividades de organizaçoes associativas ligadas a cultura e a arte"
    },
    {
      codigoMCC: 8699,
      descricaoMCC: "Sindicatos Trabalhistas e Organizaçoes Profissiona",
      codigoCnae: 9499500,
      descricaoCnae: "Atividades associativas nao especificadas anteriormente"
    },
    {
      codigoMCC: 8999,
      descricaoMCC: "SERVIÇOS PROFISSIONAIS, NAO CLASSIFICADOS EM OUTRA PARTE",
      codigoCnae: 7490104,
      descricaoCnae: "Atividades de intermediaçao e agenciamento de serviços e negocios em geral, exceto imobiliarios"
    },
    {
      codigoMCC: 8071,
      descricaoMCC: "Laboratorios Medicos / Dentarios",
      codigoCnae: 8640202,
      descricaoCnae: "Laboratorios clinicos"
    },
    {
      codigoMCC: 8043,
      descricaoMCC: "Oticas / Produtos Oticos",
      codigoCnae: 4774100,
      descricaoCnae: "Comercio varejista de artigos de optica"
    },
    {
      codigoMCC: 8911,
      descricaoMCC: "Arquitetura e Engenharia",
      codigoCnae: 7410202,
      descricaoCnae: "Design de interiores"
    },
    {
      codigoMCC: 8021,
      descricaoMCC: "Dentistas / Ortodontistas - Clinicas",
      codigoCnae: 8630504,
      descricaoCnae: "Atividade odontologica"
    },
    {
      codigoMCC: 8299,
      descricaoMCC: "Escolas e Cursos em Geral",
      codigoCnae: 8599604,
      descricaoCnae: "Treinamento em desenvolvimento profissional e gerencial"
    },
    {
      codigoMCC: 8999,
      descricaoMCC: "",
      codigoCnae: 6619399,
      descricaoCnae: "Outras atividades auxiliares dos serviços financeiros nao especificadas anteriormente"
    },
    {
      codigoMCC: 8999,
      descricaoMCC: "",
      codigoCnae: 7490199,
      descricaoCnae: "Outras atividades profissionais, cientificas e tecnicas nao especificadas anteriormente"
    },
    {
      codigoMCC: 8299,
      descricaoMCC: "Escolas e Cursos em Geral",
      codigoCnae: 8599699,
      descricaoCnae: "Outras atividades de ensino nao especificadas anteriormente"
    },
    {
      codigoMCC: 7997,
      descricaoMCC: "Clubes / Atletismo - Socios",
      codigoCnae: 9313100,
      descricaoCnae: "Atividades de condicionamento fisico"
    },
    {
      codigoMCC: 8011,
      descricaoMCC: "Medicos / Clinicas",
      codigoCnae: 8630503,
      descricaoCnae: "Atividade medica ambulatorial restrita a consultas"
    },
    {
      codigoMCC: 8099,
      descricaoMCC: "Prontos Socorros",
      codigoCnae: 8630501,
      descricaoCnae: "Atividade medica ambulatorial com recursos para realizaçao de procedimentos cirurgicos"
    },
    {
      codigoMCC: 8099,
      descricaoMCC: "Prontos Socorros",
      codigoCnae: 8630502,
      descricaoCnae: "Atividade medica ambulatorial com recursos para realizaçao de exames complementares"
    },
    {
      codigoMCC: 8999,
      descricaoMCC: "",
      codigoCnae: 7210000,
      descricaoCnae: "Pesquisa e desenvolvimento experimental em ciencias fisicas e naturais"
    },
    {
      codigoMCC: 8911,
      descricaoMCC: "Arquitetura e Engenharia",
      codigoCnae: 7112000,
      descricaoCnae: "Serviços de engenharia"
    },
    {
      codigoMCC: 7699,
      descricaoMCC: "Lojas de Reparos e Serviços Relacionados",
      codigoCnae: 9529199,
      descricaoCnae: "Reparaçao e manutençao de outros objetos e equipamentos pessoais e domesticos nao especificados anteriormente"
    },
    {
      codigoMCC: 7991,
      descricaoMCC: "Exposiçoes e Atraçoes Turisticas",
      codigoCnae: 8230001,
      descricaoCnae: "Serviços de organizaçao de feiras, congressos, exposiçoes e festas"
    },
    {
      codigoMCC: 7829,
      descricaoMCC: "Produçao e Distribuiçao de Video Tape",
      codigoCnae: 5911199,
      descricaoCnae: "Atividades de produçao cinematografica, de videos e de programas de televisao nao especificadas anteriormente"
    },
    {
      codigoMCC: 8249,
      descricaoMCC: "Escolas Tecnicas",
      codigoCnae: 8541400,
      descricaoCnae: "Educaçao profissional de nivel tecnico"
    },
    {
      codigoMCC: 7999,
      descricaoMCC: "Venda de Ingressos",
      codigoCnae: 9329899,
      descricaoCnae: "Outras atividades de recreaçao e lazer nao especificadas anteriormente"
    },
    {
      codigoMCC: 7829,
      descricaoMCC: "Produçao e Distribuiçao de Video Tape",
      codigoCnae: 5912099,
      descricaoCnae: "Atividades de pos-produçao cinematografica, de videos e de programas de televisao nao especificadas anteriormente"
    },
    {
      codigoMCC: 8099,
      descricaoMCC: "Prontos Socorros",
      codigoCnae: 8660700,
      descricaoCnae: "Atividades de apoio a gestao de saude"
    },
    {
      codigoMCC: 8999,
      descricaoMCC: "",
      codigoCnae: 6201502,
      descricaoCnae: "Web design"
    },
    {
      codigoMCC: 8062,
      descricaoMCC: "Hospitais",
      codigoCnae: 8610101,
      descricaoCnae: "Atividades de atendimento hospitalar, exceto pronto-socorro e unidades para atendimento a urgencias"
    },
    {
      codigoMCC: 8021,
      descricaoMCC: "Dentistas / Ortodontistas - Clinicas",
      codigoCnae: 3250706,
      descricaoCnae: "Serviços de protese dentaria (Nao dispensada *)"
    },
    {
      codigoMCC: 7997,
      descricaoMCC: "Clubes / Atletismo - Socios",
      codigoCnae: 9312300,
      descricaoCnae: "Clubes sociais, esportivos e similares"
    },
    {
      codigoMCC: 7699,
      descricaoMCC: "Lojas de Reparos e Serviços Relacionados",
      codigoCnae: 9529104,
      descricaoCnae: "Reparaçao de bicicletas, triciclos e outros veiculos nao-motorizados"
    },
    {
      codigoMCC: 8299,
      descricaoMCC: "Escolas e Cursos em Geral",
      codigoCnae: 8591100,
      descricaoCnae: "Ensino de esportes"
    },
    {
      codigoMCC: 7699,
      descricaoMCC: "Lojas de Reparos e Serviços Relacionados",
      codigoCnae: 4329103,
      descricaoCnae: "Instalaçao, manutençao e reparaçao de elevadores, escadas e esteiras rolantes"
    },
    {
      codigoMCC: 7999,
      descricaoMCC: "Venda de Ingressos",
      codigoCnae: 9319199,
      descricaoCnae: "Outras atividades esportivas nao especificadas anteriormente"
    },
    {
      codigoMCC: 8999,
      descricaoMCC: "",
      codigoCnae: 6463800,
      descricaoCnae: "Outras sociedades de participaçao, exceto holdings"
    },
    {
      codigoMCC: 8911,
      descricaoMCC: "Arquitetura e Engenharia",
      codigoCnae: 4221902,
      descricaoCnae: "Construçao de estaçoes e redes de distribuiçao de energia eletrica"
    },
    {
      codigoMCC: 7829,
      descricaoMCC: "Produçao e Distribuiçao de Video Tape",
      codigoCnae: 4649407,
      descricaoCnae: "Comercio atacadista de filmes, CDs, DVDs, fitas e discos"
    },
    {
      codigoMCC: 8931,
      descricaoMCC: "Contadores",
      codigoCnae: 6920602,
      descricaoCnae: "Atividades de consultoria e auditoria contabil e tributaria"
    },
    {
      codigoMCC: 8111,
      descricaoMCC: "Serviços Legais",
      codigoCnae: 6911701,
      descricaoCnae: "Serviços advocaticios"
    },
    {
      codigoMCC: 8911,
      descricaoMCC: "Arquitetura e Engenharia",
      codigoCnae: 4292801,
      descricaoCnae: "Montagem de estruturas metalicas"
    },
    {
      codigoMCC: 7932,
      descricaoMCC: "Casas de Bilhar",
      codigoCnae: 9329803,
      descricaoCnae: "Exploraçao de jogos de sinuca, bilhar e similares"
    },
    {
      codigoMCC: 8099,
      descricaoMCC: "Prontos Socorros",
      codigoCnae: 8630599,
      descricaoCnae: "Atividades de atençao ambulatorial nao especificadas anteriormente"
    },
    {
      codigoMCC: 8398,
      descricaoMCC: "Organizaçao de Caridade e Serviço Social",
      codigoCnae: 8800600,
      descricaoCnae: "Serviços de assistencia social sem alojamento"
    },
    {
      codigoMCC: 8050,
      descricaoMCC: "Casas de Repouso / Serviço de Enfermagem",
      codigoCnae: 8711501,
      descricaoCnae: "Clinicas e residencias geriatricas"
    },
    {
      codigoMCC: 8050,
      descricaoMCC: "Casas de Repouso / Serviço de Enfermagem",
      codigoCnae: 8711503,
      descricaoCnae: "Atividades de assistencia a deficientes fisicos, imunodeprimidos e convalescentes"
    },
    {
      codigoMCC: 8050,
      descricaoMCC: "Casas de Repouso / Serviço de Enfermagem",
      codigoCnae: 8711504,
      descricaoCnae: "Centros de apoio a pacientes com cancer e com AIDS"
    },
    {
      codigoMCC: 8099,
      descricaoMCC: "Prontos Socorros",
      codigoCnae: 8712300,
      descricaoCnae: "Atividades de fornecimento de infra-estrutura de apoio e assistencia a paciente no domicilio"
    },
    {
      codigoMCC: 8099,
      descricaoMCC: "Prontos Socorros",
      codigoCnae: 8720401,
      descricaoCnae: "Atividades de centros de assistencia psicossocial"
    },
    {
      codigoMCC: 8099,
      descricaoMCC: "Prontos Socorros",
      codigoCnae: 8720499,
      descricaoCnae: "Atividades de assistencia psicossocial e a saude a portadores de disturbios psiquicos, deficiencia mental e dependencia quimica e grupos similares nao especificadas anteriormente"
    },
    {
      codigoMCC: 8398,
      descricaoMCC: "Organizaçao de Caridade e Serviço Social",
      codigoCnae: 8730199,
      descricaoCnae: "Atividades de assistencia social prestadas em residencias coletivas e particulares nao especificadas anteriormente"
    },
    {
      codigoMCC: 8651,
      descricaoMCC: "Organizaçao Politica",
      codigoCnae: 9492800,
      descricaoCnae: "Atividades de organizaçoes politicas"
    },
    {
      codigoMCC: 7941,
      descricaoMCC: "Campo de Esporte / Propaganda Esportiva",
      codigoCnae: 7490105,
      descricaoCnae: "Agenciamento de profissionais para atividades esportivas, culturais e artisticas (Dispensada *)"
    },
    {
      codigoMCC: 8911,
      descricaoMCC: "Arquitetura e Engenharia",
      codigoCnae: 7119702,
      descricaoCnae: "Atividades de estudos geologicos"
    },
    {
      codigoMCC: 8999,
      descricaoMCC: "",
      codigoCnae: 6462000,
      descricaoCnae: "Holdings de instituiçoes nao-financeiras"
    },
    {
      codigoMCC: 7994,
      descricaoMCC: "Video Game - Lojas",
      codigoCnae: 7729201,
      descricaoCnae: "Aluguel de aparelhos de jogos eletronicos"
    },
    {
      codigoMCC: 8398,
      descricaoMCC: "Organizaçao de Caridade e Serviço Social",
      codigoCnae: 8730102,
      descricaoCnae: "Albergues assistenciais"
    },
    {
      codigoMCC: 8398,
      descricaoMCC: "Organizaçao de Caridade e Serviço Social",
      codigoCnae: 8730101,
      descricaoCnae: "Orfanatos"
    },
    {
      codigoMCC: 8299,
      descricaoMCC: "Escolas e Cursos em Geral",
      codigoCnae: 8599602,
      descricaoCnae: "Cursos de pilotagem"
    },
    {
      codigoMCC: 8661,
      descricaoMCC: "Organizaçao Religiosa",
      codigoCnae: 9491000,
      descricaoCnae: "Atividades de organizaçoes religiosas"
    },
    {
      codigoMCC: 7999,
      descricaoMCC: "Venda de Ingressos",
      codigoCnae: 9319101,
      descricaoCnae: "Produçao e promoçao de eventos esportivos"
    },
    {
      codigoMCC: 8071,
      descricaoMCC: "Laboratorios Medicos / Dentarios",
      codigoCnae: 3250709,
      descricaoCnae: "Serviço de laboratorio optico"
    },
    {
      codigoMCC: 8911,
      descricaoMCC: "Arquitetura e Engenharia",
      codigoCnae: 4221904,
      descricaoCnae: "Construçao de estaçoes e redes de telecomunicaçoes"
    },
    {
      codigoMCC: 8050,
      descricaoMCC: "Casas de Repouso / Serviço de Enfermagem",
      codigoCnae: 8711502,
      descricaoCnae: "Instituiçoes de longa permanencia para idosos"
    },
    {
      codigoMCC: 8099,
      descricaoMCC: "Prontos Socorros",
      codigoCnae: 8690901,
      descricaoCnae: "Atividades de praticas integrativas e complementares em saude humana"
    },
    {
      codigoMCC: 8099,
      descricaoMCC: "Prontos Socorros",
      codigoCnae: 8650099,
      descricaoCnae: "Atividades de profissionais da area de saude nao especificadas anteriormente"
    },
    {
      codigoMCC: 8931,
      descricaoMCC: "Contadores",
      codigoCnae: 6920601,
      descricaoCnae: "Atividades de contabilidade"
    },
    {
      codigoMCC: 8099,
      descricaoMCC: "Prontos Socorros",
      codigoCnae: 8650002,
      descricaoCnae: "Atividades de profissionais da nutriçao"
    },
    {
      codigoMCC: 8244,
      descricaoMCC: "Serviços Escolares",
      codigoCnae: 8550302,
      descricaoCnae: "Atividades de apoio a educaçao, exceto caixas escolares"
    },
    {
      codigoMCC: 8911,
      descricaoMCC: "Arquitetura e Engenharia",
      codigoCnae: 4313400,
      descricaoCnae: "Obras de terraplenagem"
    },
    {
      codigoMCC: 8099,
      descricaoMCC: "Prontos Socorros",
      codigoCnae: 8690999,
      descricaoCnae: "Outras atividades de atençao a saude humana nao especificadas anteriormente"
    },
    {
      codigoMCC: 8099,
      descricaoMCC: "Prontos Socorros",
      codigoCnae: 8650004,
      descricaoCnae: "Atividades de fisioterapia"
    },
    {
      codigoMCC: 8299,
      descricaoMCC: "Escolas e Cursos em Geral",
      codigoCnae: 8592999,
      descricaoCnae: "Ensino de arte e cultura nao especificado anteriormente"
    },
    {
      codigoMCC: 7829,
      descricaoMCC: "Produçao e Distribuiçao de Video Tape",
      codigoCnae: 1830002,
      descricaoCnae: "Produçao e Distribuiçao de Video Tape"
    },
    {
      codigoMCC: 7829,
      descricaoMCC: "Produçao e Distribuiçao de Video Tape",
      codigoCnae: 5911101,
      descricaoCnae: "Produçao e Distribuiçao de Video Tape"
    },
    {
      codigoMCC: 7829,
      descricaoMCC: "Produçao e Distribuiçao de Video Tape",
      codigoCnae: 5911102,
      descricaoCnae: "Produçao e Distribuiçao de Video Tape"
    },
    {
      codigoMCC: 7829,
      descricaoMCC: "Produçao e Distribuiçao de Video Tape",
      codigoCnae: 5912001,
      descricaoCnae: "Produçao e Distribuiçao de Video Tape"
    },
    {
      codigoMCC: 7829,
      descricaoMCC: "Produçao e Distribuiçao de Video Tape",
      codigoCnae: 5912002,
      descricaoCnae: "Produçao e Distribuiçao de Video Tape"
    },
    {
      codigoMCC: 7829,
      descricaoMCC: "Produçao e Distribuiçao de Video Tape",
      codigoCnae: 5913800,
      descricaoCnae: "Produçao e Distribuiçao de Video Tape"
    },
    {
      codigoMCC: 7832,
      descricaoMCC: "Cinema",
      codigoCnae: 5914600,
      descricaoCnae: "Cinema"
    },
    {
      codigoMCC: 7841,
      descricaoMCC: "Locadoras de Video",
      codigoCnae: 7722500,
      descricaoCnae: "Locadoras de Video"
    },
    {
      codigoMCC: 7911,
      descricaoMCC: "Dança (Academias)",
      codigoCnae: 8592901,
      descricaoCnae: "Dança (Academias)"
    },
    {
      codigoMCC: 7922,
      descricaoMCC: "Teatros / Shows / Concertos / Entretenimento em Ge",
      codigoCnae: 9001901,
      descricaoCnae: "Teatros / Shows / Concertos / Entretenimento em Ge"
    },
    {
      codigoMCC: 7922,
      descricaoMCC: "Teatros / Shows / Concertos / Entretenimento em Ge",
      codigoCnae: 9001903,
      descricaoCnae: "Teatros / Shows / Concertos / Entretenimento em Ge"
    },
    {
      codigoMCC: 7922,
      descricaoMCC: "Teatros / Shows / Concertos / Entretenimento em Ge",
      codigoCnae: 9001904,
      descricaoCnae: "Teatros / Shows / Concertos / Entretenimento em Ge"
    },
    {
      codigoMCC: 7922,
      descricaoMCC: "Teatros / Shows / Concertos / Entretenimento em Ge",
      codigoCnae: 9001906,
      descricaoCnae: "Teatros / Shows / Concertos / Entretenimento em Ge"
    },
    {
      codigoMCC: 7922,
      descricaoMCC: "Teatros / Shows / Concertos / Entretenimento em Ge",
      codigoCnae: 9001999,
      descricaoCnae: "Teatros / Shows / Concertos / Entretenimento em Ge"
    },
    {
      codigoMCC: 7922,
      descricaoMCC: "Teatros / Shows / Concertos / Entretenimento em Ge",
      codigoCnae: 9003500,
      descricaoCnae: "Teatros / Shows / Concertos / Entretenimento em Ge"
    },
    {
      codigoMCC: 7929,
      descricaoMCC: "Bandas, Artistas, Orquestras e Casas de Shows",
      codigoCnae: 9001902,
      descricaoCnae: "Bandas, Artistas, Orquestras e Casas de Shows"
    },
    {
      codigoMCC: 7933,
      descricaoMCC: "Boliche",
      codigoCnae: 9329802,
      descricaoCnae: "Boliche"
    },
    {
      codigoMCC: 7941,
      descricaoMCC: "Campo de Esporte / Propaganda Esportiva",
      codigoCnae: 9311500,
      descricaoCnae: "Campo de Esporte / Propaganda Esportiva"
    },
    {
      codigoMCC: 7991,
      descricaoMCC: "Exposiçoes e Atraçoes Turisticas",
      codigoCnae: 9101500,
      descricaoCnae: "Exposiçoes e Atraçoes Turisticas"
    },
    {
      codigoMCC: 7991,
      descricaoMCC: "Exposiçoes e Atraçoes Turisticas",
      codigoCnae: 9102301,
      descricaoCnae: "Exposiçoes e Atraçoes Turisticas"
    },
    {
      codigoMCC: 7994,
      descricaoMCC: "Video Game - Lojas",
      codigoCnae: 9329804,
      descricaoCnae: "Video Game - Lojas"
    },
    {
      codigoMCC: 7995,
      descricaoMCC: "Apostas, Casas Lotericas, Bingos, Jokey Clube",
      codigoCnae: 8299706,
      descricaoCnae: "Apostas, Casas Lotericas, Bingos, Jokey Clube"
    },
    {
      codigoMCC: 7995,
      descricaoMCC: "Apostas, Casas Lotericas, Bingos, Jokey Clube",
      codigoCnae: 9200301,
      descricaoCnae: "Apostas, Casas Lotericas, Bingos, Jokey Clube"
    },
    {
      codigoMCC: 7995,
      descricaoMCC: "Apostas, Casas Lotericas, Bingos, Jokey Clube",
      codigoCnae: 9200302,
      descricaoCnae: "Apostas, Casas Lotericas, Bingos, Jokey Clube"
    },
    {
      codigoMCC: 7995,
      descricaoMCC: "Apostas, Casas Lotericas, Bingos, Jokey Clube",
      codigoCnae: 9200399,
      descricaoCnae: "Apostas, Casas Lotericas, Bingos, Jokey Clube"
    },
    {
      codigoMCC: 7996,
      descricaoMCC: "Parque de Diversoes / Espetaculos Circenses",
      codigoCnae: 9321200,
      descricaoCnae: "Parque de Diversoes / Espetaculos Circenses"
    },
    {
      codigoMCC: 7998,
      descricaoMCC: "Aquarios e Museus Aquaticos",
      codigoCnae: 9103100,
      descricaoCnae: "Aquarios e Museus Aquaticos"
    },
    {
      codigoMCC: 7999,
      descricaoMCC: "Venda de Ingressos",
      codigoCnae: 9001905,
      descricaoCnae: "Venda de Ingressos"
    },
    {
      codigoMCC: 8031,
      descricaoMCC: "Ortopedista - Clinica",
      codigoCnae: 8690903,
      descricaoCnae: "Ortopedista - Clinica"
    },
    {
      codigoMCC: 8031,
      descricaoMCC: "Ortopedista - Clinica",
      codigoCnae: 8690904,
      descricaoCnae: "Ortopedista - Clinica"
    },
    {
      codigoMCC: 8050,
      descricaoMCC: "Casas de Repouso / Serviço de Enfermagem",
      codigoCnae: 8711505,
      descricaoCnae: "Casas de Repouso / Serviço de Enfermagem"
    },
    {
      codigoMCC: 8062,
      descricaoMCC: "Hospitais",
      codigoCnae: 8610102,
      descricaoCnae: "Hospitais"
    },
    {
      codigoMCC: 8071,
      descricaoMCC: "Laboratorios Medicos / Dentarios",
      codigoCnae: 8640201,
      descricaoCnae: "Laboratorios Medicos / Dentarios"
    },
    {
      codigoMCC: 8071,
      descricaoMCC: "Laboratorios Medicos / Dentarios",
      codigoCnae: 8640203,
      descricaoCnae: "Laboratorios Medicos / Dentarios"
    },
    {
      codigoMCC: 8071,
      descricaoMCC: "Laboratorios Medicos / Dentarios",
      codigoCnae: 8640204,
      descricaoCnae: "Laboratorios Medicos / Dentarios"
    },
    {
      codigoMCC: 8071,
      descricaoMCC: "Laboratorios Medicos / Dentarios",
      codigoCnae: 8640205,
      descricaoCnae: "Laboratorios Medicos / Dentarios"
    },
    {
      codigoMCC: 8071,
      descricaoMCC: "Laboratorios Medicos / Dentarios",
      codigoCnae: 8640206,
      descricaoCnae: "Laboratorios Medicos / Dentarios"
    },
    {
      codigoMCC: 8071,
      descricaoMCC: "Laboratorios Medicos / Dentarios",
      codigoCnae: 8640207,
      descricaoCnae: "Laboratorios Medicos / Dentarios"
    },
    {
      codigoMCC: 8071,
      descricaoMCC: "Laboratorios Medicos / Dentarios",
      codigoCnae: 8640208,
      descricaoCnae: "Laboratorios Medicos / Dentarios"
    },
    {
      codigoMCC: 8071,
      descricaoMCC: "Laboratorios Medicos / Dentarios",
      codigoCnae: 8640209,
      descricaoCnae: "Laboratorios Medicos / Dentarios"
    },
    {
      codigoMCC: 8071,
      descricaoMCC: "Laboratorios Medicos / Dentarios",
      codigoCnae: 8640210,
      descricaoCnae: "Laboratorios Medicos / Dentarios"
    },
    {
      codigoMCC: 8071,
      descricaoMCC: "Laboratorios Medicos / Dentarios",
      codigoCnae: 8640211,
      descricaoCnae: "Laboratorios Medicos / Dentarios"
    },
    {
      codigoMCC: 8071,
      descricaoMCC: "Laboratorios Medicos / Dentarios",
      codigoCnae: 8640212,
      descricaoCnae: "Laboratorios Medicos / Dentarios"
    },
    {
      codigoMCC: 8071,
      descricaoMCC: "Laboratorios Medicos / Dentarios",
      codigoCnae: 8640213,
      descricaoCnae: "Laboratorios Medicos / Dentarios"
    },
    {
      codigoMCC: 8071,
      descricaoMCC: "Laboratorios Medicos / Dentarios",
      codigoCnae: 8640214,
      descricaoCnae: "Laboratorios Medicos / Dentarios"
    },
    {
      codigoMCC: 8071,
      descricaoMCC: "Laboratorios Medicos / Dentarios",
      codigoCnae: 8640299,
      descricaoCnae: "Laboratorios Medicos / Dentarios"
    },
    {
      codigoMCC: 8099,
      descricaoMCC: "Prontos Socorros",
      codigoCnae: 8630506,
      descricaoCnae: "Prontos Socorros"
    },
    {
      codigoMCC: 8099,
      descricaoMCC: "Prontos Socorros",
      codigoCnae: 8630507,
      descricaoCnae: "Prontos Socorros"
    },
    {
      codigoMCC: 8099,
      descricaoMCC: "Prontos Socorros",
      codigoCnae: 8650001,
      descricaoCnae: "Prontos Socorros"
    },
    {
      codigoMCC: 8099,
      descricaoMCC: "Prontos Socorros",
      codigoCnae: 8650003,
      descricaoCnae: "Prontos Socorros"
    },
    {
      codigoMCC: 8099,
      descricaoMCC: "Prontos Socorros",
      codigoCnae: 8650005,
      descricaoCnae: "Prontos Socorros"
    },
    {
      codigoMCC: 8099,
      descricaoMCC: "Prontos Socorros",
      codigoCnae: 8650006,
      descricaoCnae: "Prontos Socorros"
    },
    {
      codigoMCC: 8099,
      descricaoMCC: "Prontos Socorros",
      codigoCnae: 8650007,
      descricaoCnae: "Prontos Socorros"
    },
    {
      codigoMCC: 8099,
      descricaoMCC: "Prontos Socorros",
      codigoCnae: 8690902,
      descricaoCnae: "Prontos Socorros"
    },
    {
      codigoMCC: 8111,
      descricaoMCC: "Serviços Legais",
      codigoCnae: 6911702,
      descricaoCnae: "Serviços Legais"
    },
    {
      codigoMCC: 8211,
      descricaoMCC: "Escolas de 1o e 2o Graus",
      codigoCnae: 8513900,
      descricaoCnae: "Escolas de 1o e 2o Graus"
    },
    {
      codigoMCC: 8211,
      descricaoMCC: "Escolas de 1o e 2o Graus",
      codigoCnae: 8520100,
      descricaoCnae: "Escolas de 1o e 2o Graus"
    },
    {
      codigoMCC: 8220,
      descricaoMCC: "Universidades",
      codigoCnae: 8531700,
      descricaoCnae: "Universidades"
    },
    {
      codigoMCC: 8220,
      descricaoMCC: "Universidades",
      codigoCnae: 8532500,
      descricaoCnae: "Universidades"
    },
    {
      codigoMCC: 8220,
      descricaoMCC: "Universidades",
      codigoCnae: 8533300,
      descricaoCnae: "Universidades"
    },
    {
      codigoMCC: 8244,
      descricaoMCC: "Serviços Escolares",
      codigoCnae: 8550301,
      descricaoCnae: "Serviços Escolares"
    },
    {
      codigoMCC: 8249,
      descricaoMCC: "Escolas Tecnicas",
      codigoCnae: 8542200,
      descricaoCnae: "Escolas Tecnicas"
    },
    {
      codigoMCC: 8299,
      descricaoMCC: "Escolas e Cursos em Geral",
      codigoCnae: 8592902,
      descricaoCnae: "Escolas e Cursos em Geral"
    },
    {
      codigoMCC: 8299,
      descricaoMCC: "Escolas e Cursos em Geral",
      codigoCnae: 8592903,
      descricaoCnae: "Escolas e Cursos em Geral"
    },
    {
      codigoMCC: 8299,
      descricaoMCC: "Escolas e Cursos em Geral",
      codigoCnae: 8593700,
      descricaoCnae: "Escolas e Cursos em Geral"
    },
    {
      codigoMCC: 8299,
      descricaoMCC: "Escolas e Cursos em Geral",
      codigoCnae: 8599601,
      descricaoCnae: "Escolas e Cursos em Geral"
    },
    {
      codigoMCC: 8351,
      descricaoMCC: "Creches",
      codigoCnae: 8511200,
      descricaoCnae: "Creches"
    },
    {
      codigoMCC: 8351,
      descricaoMCC: "Creches",
      codigoCnae: 8512100,
      descricaoCnae: "Creches"
    },
    {
      codigoMCC: 8699,
      descricaoMCC: "Sindicatos Trabalhistas e Organizaçoes Profissiona",
      codigoCnae: 9411100,
      descricaoCnae: "Sindicatos Trabalhistas e Organizaçoes Profissiona"
    },
    {
      codigoMCC: 8699,
      descricaoMCC: "Sindicatos Trabalhistas e Organizaçoes Profissiona",
      codigoCnae: 9412099,
      descricaoCnae: "Sindicatos Trabalhistas e Organizaçoes Profissiona"
    },
    {
      codigoMCC: 8699,
      descricaoMCC: "Sindicatos Trabalhistas e Organizaçoes Profissiona",
      codigoCnae: 9420100,
      descricaoCnae: "Sindicatos Trabalhistas e Organizaçoes Profissiona"
    },
    {
      codigoMCC: 8699,
      descricaoMCC: "Sindicatos Trabalhistas e Organizaçoes Profissiona",
      codigoCnae: 9412000,
      descricaoCnae: "Sindicatos Trabalhistas e Organizaçoes Profissiona"
    },
    {
      codigoMCC: 8734,
      descricaoMCC: "Testes Laboratoriais",
      codigoCnae: 7120100,
      descricaoCnae: "Testes Laboratoriais"
    },
    {
      codigoMCC: 8911,
      descricaoMCC: "Arquitetura e Engenharia",
      codigoCnae: 4221901,
      descricaoCnae: "Arquitetura e Engenharia"
    },
    {
      codigoMCC: 8911,
      descricaoMCC: "Arquitetura e Engenharia",
      codigoCnae: 4312600,
      descricaoCnae: "Arquitetura e Engenharia"
    },
    {
      codigoMCC: 8911,
      descricaoMCC: "Arquitetura e Engenharia",
      codigoCnae: 4319300,
      descricaoCnae: "Arquitetura e Engenharia"
    },
    {
      codigoMCC: 8911,
      descricaoMCC: "Arquitetura e Engenharia",
      codigoCnae: 4329102,
      descricaoCnae: "Arquitetura e Engenharia"
    },
    {
      codigoMCC: 8911,
      descricaoMCC: "Arquitetura e Engenharia",
      codigoCnae: 7111100,
      descricaoCnae: "Arquitetura e Engenharia"
    },
    {
      codigoMCC: 8911,
      descricaoMCC: "Arquitetura e Engenharia",
      codigoCnae: 7119701,
      descricaoCnae: "Arquitetura e Engenharia"
    },
    {
      codigoMCC: 8911,
      descricaoMCC: "Arquitetura e Engenharia",
      codigoCnae: 7119703,
      descricaoCnae: "Arquitetura e Engenharia"
    },
    {
      codigoMCC: 8911,
      descricaoMCC: "Arquitetura e Engenharia",
      codigoCnae: 7119799,
      descricaoCnae: "Arquitetura e Engenharia"
    },
    {
      codigoMCC: 8911,
      descricaoMCC: "Arquitetura e Engenharia",
      codigoCnae: 7410201,
      descricaoCnae: "Arquitetura e Engenharia"
    },
    {
      codigoMCC: 8931,
      descricaoMCC: "Contadores",
      codigoCnae: 6621502,
      descricaoCnae: "Contadores"
    },
    {
      codigoMCC: 5813,
      descricaoMCC: "Bares",
      codigoCnae: 5611204,
      descricaoCnae: "Bares e outros estabelecimentos especializados em servir bebidas, sem entretenimento"
    },
    {
      codigoMCC: 5571,
      descricaoMCC: "Motocicletas",
      codigoCnae: 4541206,
      descricaoCnae: "Comercio a varejo de peças e acessorios novos para  motocicletas e motonetas"
    },
    {
      codigoMCC: 9399,
      descricaoMCC: "Serviços Governamentais ( cartorios, orgao da administraçao publica, taxas registro de veiculos)",
      codigoCnae: 8423000,
      descricaoCnae: "Justiça"
    },
    {
      codigoMCC: 5813,
      descricaoMCC: "Bares",
      codigoCnae: 5611205,
      descricaoCnae: "Bares e outros estabelecimentos especializados em servir bebidas, com entretenimento"
    },
    {
      codigoMCC: 9399,
      descricaoMCC: "Serviços Governamentais ( cartorios, orgao da administraçao publica, taxas registro de veiculos)",
      codigoCnae: 8412400,
      descricaoCnae: "Regulaçao das atividades de saude, educaçao, serviços culturais e outros serviços sociais"
    },
    {
      codigoMCC: 9399,
      descricaoMCC: "Serviços Governamentais ( cartorios, orgao da administraçao publica, taxas registro de veiculos)",
      codigoCnae: 8411600,
      descricaoCnae: "Administraçao publica em geral"
    },
    {
      codigoMCC: 9399,
      descricaoMCC: "Serviços Governamentais ( cartorios, orgao da administraçao publica, taxas registro de veiculos)",
      codigoCnae: 6410700,
      descricaoCnae: "Serviços Governamentais ( cartorios, orgao da administraçao publica, taxas registro de veiculos)"
    },
    {
      codigoMCC: 9399,
      descricaoMCC: "Serviços Governamentais ( cartorios, orgao da administraçao publica, taxas registro de veiculos)",
      codigoCnae: 8413200,
      descricaoCnae: "Serviços Governamentais ( cartorios, orgao da administraçao publica, taxas registro de veiculos)"
    },
    {
      codigoMCC: 9399,
      descricaoMCC: "Serviços Governamentais ( cartorios, orgao da administraçao publica, taxas registro de veiculos)",
      codigoCnae: 8421300,
      descricaoCnae: "Serviços Governamentais ( cartorios, orgao da administraçao publica, taxas registro de veiculos)"
    },
    {
      codigoMCC: 9399,
      descricaoMCC: "Serviços Governamentais ( cartorios, orgao da administraçao publica, taxas registro de veiculos)",
      codigoCnae: 8422100,
      descricaoCnae: "Serviços Governamentais ( cartorios, orgao da administraçao publica, taxas registro de veiculos)"
    },
    {
      codigoMCC: 9399,
      descricaoMCC: "Serviços Governamentais ( cartorios, orgao da administraçao publica, taxas registro de veiculos)",
      codigoCnae: 8424800,
      descricaoCnae: "Serviços Governamentais ( cartorios, orgao da administraçao publica, taxas registro de veiculos)"
    },
    {
      codigoMCC: 9399,
      descricaoMCC: "Serviços Governamentais ( cartorios, orgao da administraçao publica, taxas registro de veiculos)",
      codigoCnae: 8425600,
      descricaoCnae: "Serviços Governamentais ( cartorios, orgao da administraçao publica, taxas registro de veiculos)"
    },
    {
      codigoMCC: 9399,
      descricaoMCC: "Serviços Governamentais ( cartorios, orgao da administraçao publica, taxas registro de veiculos)",
      codigoCnae: 8430200,
      descricaoCnae: "Serviços Governamentais ( cartorios, orgao da administraçao publica, taxas registro de veiculos)"
    },
    {
      codigoMCC: 9402,
      descricaoMCC: "Serviços Postais (Apenas Publicos)",
      codigoCnae: 5310501,
      descricaoCnae: "Serviços Postais (Apenas Publicos)"
    },
    {
      codigoMCC: 9402,
      descricaoMCC: "Serviços Postais (Apenas Publicos)",
      codigoCnae: 5310502,
      descricaoCnae: "Serviços Postais (Apenas Publicos)"
    }
  ]