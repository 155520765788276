import Vue from 'vue'
import Router from 'vue-router'
import verificaSeEhOperador from '@/common/middleware/verificaSeEhOperador.js'
import verificaAutenticacao from '@/common/middleware/verificaAutenticacao.js'
import redirecionaSeAutenticado from '@/common/middleware/redirecionaSeAutenticado.js'

import Estabelecimentos from './Estabelecimentos';
import Planos from './Planos';
import Clientes from "./Clientes";
import Produtos from "./Produtos";
import LinkPagamento from "./LinkPagamento";
import ExtratoTransacao from './ExtratoTransacao';
import Usuarios from "./Usuarios";
import Assinaturas from './Assinaturas';
import GrupoEconomico from './GrupoEconomico';

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,

  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      component: () => import('@/layouts/full-layout/FullLayout'),
      children: [
        {
          name: 'dashboard',
          path: '/dashboard',
          meta: {
            middleware: [verificaAutenticacao, verificaSeEhOperador]
          },
          component: () =>
            import('@/views/dashboards/')
        },
        {
          name: 'auditoria',
          path: '/auditoria',
          meta: {
            middleware: verificaAutenticacao
          },
          beforeEnter: (to, from, next) => {
            verificarPermissao(next, 'Auditoria')
          },
          component: () => import('@/views/auditoria/Index')
        },
        {
          name: 'permissoes',
          path: '/permissoes',
          meta: {
            middleware: verificaAutenticacao
          },
          beforeEnter: (to, from, next) => {
            verificarPermissao(next, 'Perfil')
          },
          component: () => import('@/views/permissoes/Index')
        },
        ...Usuarios,
        ...Estabelecimentos,
        ...Planos,
        ...Clientes,
        ...Produtos,
        ...LinkPagamento,
        ...ExtratoTransacao,
        ...Assinaturas,
        ...GrupoEconomico,
      ]
    },
    {
      path: '/',
      component: () => import('@/layouts/blank-layout/Blanklayout'),
      children: [
        {
          name: 'Login',
          path: 'login',
          meta: {
            middleware: redirecionaSeAutenticado
          },
          component: () => import('@/views/autenticacao/Login')
        },
        {
          name: 'esqueceu-sua-senha',
          path: 'esqueceu-sua-senha/',
          meta: {
            middleware: redirecionaSeAutenticado
          },
          component: () => import('@/views/autenticacao/EsqueceuSenha')
        }
      ]
    },
    {
      path: '*',
      redirect: '/login'
    },
    {
      path: '/',
      redirect: '/login'
    }
  ]
})

import NProgress from 'nprogress'
import verificarPermissao from '../common/middleware/verificarPermissao'



router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start(800)
  }
  next()
})

router.afterEach(() => {
  NProgress.done()
})

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index]

  if (!subsequentMiddleware) return context.next

  return (...parameters) => {
    context.next(...parameters)
    const nextMiddleware = nextFactory(context, middleware, index + 1)
    subsequentMiddleware({ ...context, next: nextMiddleware })
  }
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware]

    const context = {
      from,
      next,
      router,
      to
    }
    const nextMiddleware = nextFactory(context, middleware, 1)

    return middleware[0]({ ...context, next: nextMiddleware })
  }

  return next()
})

export default router
