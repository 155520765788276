import verificaAutenticacao from "@/common/middleware/verificaAutenticacao.js";
import verificarPermissao from "../common/middleware/verificarPermissao";

export default [
    {
        name: "grupo-economico",
        path: "/grupo_economico",
        meta: {
            middleware: verificaAutenticacao,
        },
        beforeEnter: (to, from, next) => {
            verificarPermissao(next, "GrupoEconomico");
        },
        component: () => import("@/views/grupo_economico"),
    },
    {
        name: "grupo-economico-novo",
        path: "/grupo_economico/novo",
        meta: {
            middleware: verificaAutenticacao,
        },
        beforeEnter: (to, from, next) => {
            verificarPermissao(next, "GrupoEconomico");
        },
        component: () => import("@/views/grupo_economico/formulario"),
    },
    {
        name: "grupo-economico-editar",
        path: "/grupo_economico/:id?",
        meta: {
            middleware: verificaAutenticacao,
        },
        beforeEnter: (to, from, next) => {
            verificarPermissao(next, "GrupoEconomico");
        },
        props : true,
        component: () => import("@/views/grupo_economico/formulario"),
    },
]