import verificaAutenticacao from "@/common/middleware/verificaAutenticacao.js";
import verificarPermissao from "../common/middleware/verificarPermissao";

export default [
    {
        name: "usuarios",
        path: "/usuarios",
        meta: {
            middleware: verificaAutenticacao,
        },
        beforeEnter: (to, from, next) => {
            verificarPermissao(next, "Usuario");
        },
        component: () => import("@/views/usuarios/Index"),
    },
    {
        name: "usuarios-novo",
        path: "/usuarios/novo",
        meta: {
            middleware: verificaAutenticacao,
        },
        beforeEnter: (to, from, next) => {
            verificarPermissao(next, "Usuario");
        },
        component: () => import("@/views/usuarios/Formulario"),
    },
    {
        name: "usuarios-editar",
        path: "/usuarios/:id?",
        meta: {
            middleware: verificaAutenticacao,
        },
        beforeEnter: (to, from, next) => {
            verificarPermissao(next, "Usuario");
        },
        props : true,
        component: () => import("@/views/usuarios/Formulario"),
    },
    {
        name: 'minha-conta',
        path: '/minha-conta',
        meta: {
          middleware: verificaAutenticacao
        },
        component: () => import('@/views/usuarios/Formulario')
      },
]