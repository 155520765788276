export default [
    { value: "NOV", text: "Nova" },
    { value: "INV", text: "Inválida" },
    { value: "PPC", text: "Pendente de confirmação" },
    { value: "PPN", text: "Desfeita" },
    { value: "PEN", text: "Aguardando pagamento" },
    { value: "CON", text: "Aprovada" },
    { value: "NEG", text: "Negada" },
    { value: "ERR", text: "Erro ao efetuar pagamento" },
    { value: "BLQ", text: "Bloqueada" },
    { value: "EXP", text: "Expirada" },
    { value: "EST", text: "Estornada" },
    { value: "AGU", text: "Aguardando Usuário" },
    { value: "ABA", text: "Abandonada" },
    { value: "CAN", text: "Cancelada" },
    { value: "RET", text: "Retentativa" }
]