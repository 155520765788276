import ApiService from "@/common/api/api.service";
import helpers from "@/common/utils/helpers";

const MinhaContaService = {  
  buscar() {
    return ApiService.get(`minha-conta`);
  },
  salvar(form) {
    form.cnpj = helpers.removerMascara(form.cnpj);    
    return ApiService.put(`minha-conta`, { ...form });
  },
  alterarSenha(form) {
    return ApiService.put(`minha-conta/alterar-senha`, form);
  },
  buscarEstabelecimento : function(){
    return ApiService.get(`estabelecimento/dropdown`);
  },
};

export default MinhaContaService;
