<template>
  <b-form-group
    :label="label"
    :label-sr-only="noLabel"
    :label-class="{ 'text-required': required }"
  >
    <b-form-datepicker
      v-model="content"
      :state="state"
      @input="handleInput"
      type="date"
      :autocomplete="autocomplete"
      :readonly="readonly"
      :formatter="formatter"
      :placeholder="placeholder"
      :lazy="lazy"
      @wheel="$event.target.blur()"
      :date-format-options="{
        year: 'numeric',
        month: 'numeric',
        day: '2-digit',
      }"
      locale="br"
      :min="min"
      :max="max"
      :size="size"
      @keyup.enter="sendEnterEvent"
    /><button
      type="button"
      @click="changePasswordField"
      class="button-eye"
      v-if="showPassword"
    >
      <v-icon>{{ inputPasswordIcon }}</v-icon>
    </button>
    <slot name="append"></slot>
    <b-form-invalid-feedback v-if="!hideFeedback">{{ feedback }}</b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
export default {
  name: "InputDatePicker",
  props: {
    stateCustom: { type: Boolean, default: true },
    value: { type: [String, Number], default: "" },
    type: { type: String, default: "text" },
    label: { type: String },
    size: { type: String },
    autocomplete: { type: String, default: "new-password" },
    upper: { type: Boolean },
    noLabel: { type: Boolean, default: false },
    removeSpecialChars: { type: Boolean },
    removeWhiteSpaces: { type: Boolean },
    required: { type: Boolean },
    requiredMessage: { type: String },
    readonly: { type: Boolean, default: false },
    length: { type: Number, default: 0 },
    lengthMessage: { type: String },
    minLength: { type: Number, default: null },
    generalRef: { type: String, default: null },
    minLengthMessage: { type: String },
    maxLength: { type: Number, default: null },
    maxLengthMessage: { type: String },
    placeholder: { type: String },
    invalidEmailMessage: { type: String },
    validate: { type: Boolean, default: null },
    showPassword: { type: Boolean, default: false },
    lazy: { type: Boolean, default: false },
    min: { type: [Number, String], required: false },
    max: { type: [Number, String], required: false },
    hideFeedback: { type: Boolean, default: false },
    propsError: { type: Boolean, default: null },
  },
  data() {
    return {
      content: this.value,
      validForm: false,
      hasError: false,
      errorMessage: "",
      inputPasswordIcon: "fas fa-eye",
    };
  },
  computed: {
    requiredValid() {
      return !(this.required && (this.content == "" || this.content == null));
    },
    minValid() {
      return !(this.min && parseInt(this.content) < parseInt(this.min));
    },
    maxValid() {
      return !(this.max && parseInt(this.content) > parseInt(this.max));
    },
    lengthValid() {
      return !(this.length > 0 && String(this.content)?.length != this.length);
    },
    minLengthValid() {
      return !(this.minLength > 0 && this.content?.length < this.minLength);
    },
    maxLengthValid() {
      return !(this.maxLength > 0 && this.content?.length > this.maxLength);
    },
    validEmail() {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(this.content);
    },
    state() {
      if (!this.stateCustom) return false;

      if (this.minLength && this.content?.length >= this.minLength) return true;

      if (this.propsError == false) return false;

      if (!this.validForm) return null;

      if (!this.requiredValid) return false;
      if (!this.minValid) return false;
      if (!this.maxValid) return false;
      if (!this.lengthValid) return false;
      if (!this.minLengthValid) return false;
      if (!this.maxLengthValid) return false;
      if (this.type == "email") return this.validEmail;
      if (this.hasError) return false;
      if (this.validate != null) return this.validate;
      return true;
    },
    feedback() {
      if (!this.requiredValid)
        return (
          this.requiredMessage ||
          this.$t("CAMPOS_VALIDACAO.REQUERIDO").formatUnicorn({
            name: this.label,
          })
        );
      if (!this.minValid)
        return (
          this.requiredMessage ||
          this.$t("CAMPOS_VALIDACAO.VALOR_MINIMO").formatUnicorn({
            min: this.min,
          })
        );
      if (!this.maxValid)
        return (
          this.requiredMessage ||
          this.$t("CAMPOS_VALIDACAO.VALOR_MAXIMO").formatUnicorn({
            max: this.max,
          })
        );
      if (!this.lengthValid)
        return (
          this.lengthMessage ||
          this.$t("CAMPOS_VALIDACAO.TAMANHO").formatUnicorn({
            name: this.label,
            length: this.length,
          })
        );
      if (!this.minLengthValid)
        return (
          this.minLengthMessage ||
          this.$t("CAMPOS_VALIDACAO.COMPRIMENTO_MINIMO").formatUnicorn({
            name: this.label,
            length: this.minLength,
          })
        );
      if (!this.maxLengthValid)
        return (
          this.maxLengthMessage ||
          this.$t("CAMPOS_VALIDACAO.COMPRIMENTO_MAXIMO").formatUnicorn({
            name: this.label,
            length: this.maxLength,
          })
        );
      if (
        (this.type == "email" && !this.validEmail) ||
        (this.validate != null && !this.validate)
      )
        return (
          this.invalidEmailMessage ||
          this.$t("CAMPOS_VALIDACAO.INVALIDO").formatUnicorn({
            name: this.label,
          })
        );
      if (this.hasError) return this.errorMessage;
      return "";
    },
  },
  methods: {
    showError(message) {
      this.validForm = true;
      this.hasError = true;
      this.errorMessage = message;
    },
    formatter(e) {
      if (e) {
        if (this.upper) e = e.toUpperCase();

        if (this.maxLength > 0 && e.length > this.maxLength) {
          e = e.substring(0, this.maxLength);
        }

        if (this.removeSpecialChars)
          e = e.replace(/[^a-z0-9\s]/gi, "").replace(/[_\s]/g, "-");

        if (this.removeWhiteSpaces) e = e.replace(/\s/g, "");
      }
      return e;
    },
    handleInput() {
      this.$emit("input", this.content);
      this.hasError = false;
    },
    valid() {
      this.validForm = true;
      return this.state;
    },
    clear() {
      this.content = "";
      this.validForm = false;
      this.$emit("input", this.content);
    },
    changePasswordField() {
      if (this.type == "text") {
        this.type = "password";
        this.inputPasswordIcon = "fas fa-eye";
      } else {
        this.type = "text";
        this.inputPasswordIcon = "fas fa-eye-slash";
      }
    },
    sendEnterEvent() {
      this.$emit("enter");
    },
  },
  watch: {
    value: {
      handler(value) {
        this.content = value;
      },
      deep: true,
      immediate: true,
    },
    content(val) {
      this.$emit("input", val);
      this.$emit("change", val);
    },
  },
};
</script>
<style lang="scss" scoped>
input[readonly] {
  background-color: #f2f3f8 !important;
}

.button-eye {
  position: absolute;
  top: 31px;
  right: 31px;
  z-index: 9;

  .fas {
    font-size: 17px;
  }
}
</style>
