export default {
    CANCELAMENTO_COBRANCA: {
        NSU: "NSU",
        DATA_VENDA: "Data da venda",
        VALOR_VENDA: "Valor da venda",
        VALOR_DISPONIVEL: "Valor disponível para cancelamento",
        OPCAO_CANCELAMENTO: "Opção de cancelamento",
        VALOR_COBRADO: "Valor a ser cancelado",

        APROVAR: {
            TITULO_MODAL: "Aprovar solicitação de cancelamento da cobrança",
            BOTAO_CANCELAMENTO: "Aprovar cancelamento",
            CONFIRMAR_CANCELAMENTO: "Deseja aprovar o cancelamento da cobrança?",
            TITULO_CONFIRMAR: "Confirmação",
            SUCESSO: "Cobrança cancelada com sucesso",
        },
        REJEITAR: {
            TITULO_MODAL: "Rejeitar solicitação de cancelamento da cobrança",
            BOTAO_CANCELAMENTO: "Rejeitar cancelamento",
            CONFIRMAR_CANCELAMENTO: "Deseja rejeitar o cancelamento da cobrança?",
            TITULO_CONFIRMAR: "Confirmação",
            SUCESSO: "Cancelamento rejeitado com sucesso",
        },

        TITULO_MODAL: "Solicitação de cancelamento de cobrança",
        TITULO_MODAL_AUTOMATICO: "Cancelamento de cobrança",
        TITULO_MODAL_VISUALIZAR: "Solicitação de cancelamento da cobrança",
        CONFIRMAR_CANCELAMENTO: "Deseja realizar o pedido de cancelamento da cobrança?",
        TITULO_CONFIRMAR_PEDIDO_CANCELAMENTO: "Confirmação do pedido",
        TITULO_CONFIRMAR_PEDIDO_CANCELAMENTO_AUTOMATICO: "Confirmação",
        BOTAO_SOLICITAR_CANCELAMENTO: "Solicitar cancelamento",
        BOTAO_SOLICITAR_CANCELAMENTO_AUTOMATICO: "Cancelar cobrança",
        SUCESSO: "Pedido realizado com sucesso",
        SUCESSO_AUTOMATICO: "Cobrança cancelada com sucesso"

    },
    CANCELAMENTO_ASSINATURA: {
        APROVAR: {
            TITULO_MODAL: "Aprovar solicitação de cancelamento da assinatura",
            BOTAO_CANCELAMENTO: "Aprovar cancelamento",
            CONFIRMAR_CANCELAMENTO: "Deseja aprovar o cancelamento da assinatura?",
            TITULO_CONFIRMAR: "Confirmação",
            SUCESSO: "Assinatura cancelada com sucesso",
        },
        REJEITAR: {
            TITULO_MODAL: "Rejeitar solicitação de cancelamento da assinatura",
            BOTAO_CANCELAMENTO: "Rejeitar cancelamento",
            CONFIRMAR_CANCELAMENTO: "Deseja rejeitar o cancelamento da assinatura?",
            TITULO_CONFIRMAR: "Confirmação",
            SUCESSO: "Cancelamento rejeitado com sucesso",
        },
        TITULO_MODAL: "Solicitação de cancelamento da assinatura",
        TITULO_MODAL_VISUALIZAR: "Solicitação de cancelamento da assinatura",
        CONFIRMAR_CANCELAMENTO: "Deseja realizar o pedido de cancelamento da assinatura?",
        TITULO_CONFIRMAR_PEDIDO_CANCELAMENTO: "Confirmação do pedido",
        BOTAO_SOLICITAR_CANCELAMENTO: "Solicitar cancelamento",
        SUCESSO: "Pedido realizado com sucesso",
    },

    ANTECIPACAO: {
        /*APROVAR : {
            TITULO_MODAL : "Aprovar solicitação de cancelamento da assinatura",
            BOTAO_CANCELAMENTO : "Aprovar cancelamento",
            CONFIRMAR_CANCELAMENTO : "Deseja aprovar o cancelamento da assinatura?",
            TITULO_CONFIRMAR : "Confirmação",
            SUCESSO : "Assinatura cancelada com sucesso",
        },
        REJEITAR : {
            TITULO_MODAL : "Rejeitar solicitação de cancelamento da assinatura",
            BOTAO_CANCELAMENTO : "Rejeitar cancelamento",
            CONFIRMAR_CANCELAMENTO : "Deseja rejeitar o cancelamento da assinatura?",
            TITULO_CONFIRMAR : "Confirmação",
            SUCESSO : "Cancelamento rejeitado com sucesso",
        },
        TITULO_MODAL : "Solicitação de cancelamento da assinatura",
        TITULO_MODAL_VISUALIZAR : "Solicitação de cancelamento da assinatura",*/
        CONFIRMAR_ANTECIPACAO: "Deseja realizar o pedido de antecipação de cobranças?",
        TITULO_CONFIRMAR_PEDIDO_ANTECIPACAO: "Confirmação do pedido",
        BOTAO_SOLICITAR_ANTECIPACAO: "Solicitar antecipação",
        SUCESSO: "Pedido realizado com sucesso",
    },

    TABELA: {
        TIPO_SOLICITACAO: "Tipo da solicitação",
        PARCELA: "Parcela",
        DATA_SOLICITACAO: "Data da solicitação",
        MOTIVO_CANCELAMENTO: "Motivo do cancelamento",
        DATA_PARECER: "Data da resposta",
        PARECER: "Resposta da Valorem",
    },

    STATUS_AGENDAMENTO: {
        TITULO_MODAL_VISUALIZAR: "Status Agendamento Sitef",
        SID: "Sid",
        STATUS: "Status",
        USN: "Schedule Usn",
        AMOUNT: "Valor",
        PARCELAS: "Parcelas",
        INICIO: "Próximo pagamento",
    },

    VER_SOLICITACOES: "Visualizar solicitação",

    MOTIVO_CANCELAMENTO: "Motivo do cancelamento",
    RESPOSTA_VALOREM: "Resposta da Valorem"
}