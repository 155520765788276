export default {
    br : {
        PARCELA_1 : "À Vista",
        PARCELA_2 : "Parcelado em 2 vezes",
        PARCELA_3 : "Parcelado em 3 vezes",
        PARCELA_4 : "Parcelado em 4 vezes",
        PARCELA_5 : "Parcelado em 5 vezes",
        PARCELA_6 : "Parcelado em 6 vezes",
        PARCELA_7 : "Parcelado em 7 vezes",
        PARCELA_8 : "Parcelado em 8 vezes",
        PARCELA_9 : "Parcelado em 9 vezes",
        PARCELA_10 : "Parcelado em 10 vezes",
        PARCELA_11 : "Parcelado em 11 vezes",
        PARCELA_12 : "Parcelado em 12 vezes",
    }
}