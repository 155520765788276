export default {
    TITULO : "Dashboard",
    LIMITE_ANTECIPACAO : "Limite antecipação",
    ASSINATURAS_ATIVAS : "Assinaturas ativas",
    ASSINATURAS_ATRASO : "Assinaturas em atraso",
    ASSINATURAS_ANTECIPADAS : "Assinaturas ativas antecipadas",
    LIMITE_CREDITO_ANTECIPACAO : "Limite de crédito para antecipação",
    ASSINATURAS_ATRASADAS : "Assinaturas antecipadas em atraso",
    LINK_AVISTA : "Total links à Vista/Parcelado",
    LINK_RECORRENCIA : "Total links recorrência",
    TICKET_MEDIO : "Ticket médio",
    TAXA_CONVERSAO : "Taxa de conversão",
    FILTRO : "Estabelecimento",
    PERIODO : "Período de vendas",
    GERENCIAMENTO : "Gerenciamento",
    TOTAL_VENDAS : "Total de vendas aprovadas",
    VENDAS_RECORRENCIA : "Vendas recorrência",
    VENDAS_AVISTA : "Vendas à vista/parcelado",
    MENU : {
        CLIENTES : {
            DETALHE : "Gerenciamento dos clientes"
        },
        ESTABELECIMENTOS : {
            DETALHE : "Gerenciamento dos estabelecimentos"
        },
        PLANOS : {
            DETALHE : "Gerenciamento dos planos"
        },
        PRODUTOS : {
            DETALHE : "Gerenciamento dos produtos"
        },
        USUARIOS : {
            DETALHE : "Gerenciamento dos usuários"
        },
        GRUPO_ECONOMICO : {
            DETALHE : "Gerenciamento dos grupos econômicos"
        },
    }
}