export default {
    TITULO_LISTA: "Clientes",
    TITULO_FORMULARIO_NOVO: "Novo cliente",
    TITULO_FORMULARIO_EDICAO: "Editar cliente",
    NOVO: "Novo cliente",
    EDITAR: "Editar",
    INATIVAR: "Inativar",
    ATIVAR: "Ativar",
    FILTRO: "Pesquise pelo email, CPF ou contato",
    TOOLTIP: "Selecione o estabelecimento que gostaria de víncular esse contato",
    TABELA: {
        CODIGO: " Código",
        NOME: "Nome completo",
        CPF: "CPF",
        EMAIL: "Email",
        CONTATO: "Contato",
        SITUACAO: "Situação",
        ESTABELECIMENTO: "Estabelecimento",
        ACOES: "Ações",
    },
    MENSAGEM_SUCESSO: "Cliente salvo com sucesso",

    TITULO_SALVAR: "Deseja salvar o cliente?",

    INATIVAR_SUCESSO: "Cliente(s) inativado(s) com sucesso.",
    ATIVAR_SUCESSO: "Cliente(s) ativado(s) com sucesso.",
    TITULO_INATIVAR: "Inativar cliente(s)",
    TITULO_ATIVAR: "Ativar cliente(s)",
    CONFIRMACAO_INATIVAR: "Deseja inativar cliente(s) selecionado(s)?",
    CONFIRMACAO_ATIVAR: "Deseja ativar cliente(s) selecionado(s)?",
    BOTAO_INATIVAR: "Inativar",
    BOTAO_ATIVAR: "Ativar"
}