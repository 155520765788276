import Situacao from "./enumeradores/Situacao";
import TipoLinkPagamentoCobranca from "./enumeradores/TipoLinkPagamentoCobranca.js";
import TipoLinkPagamento from "./enumeradores/TipoLinkPagamento.js";
import TipoLinkPagamentoCliente from "./enumeradores/TipoLinkPagamentoCliente";
import Perfil from "./enumeradores/Perfil";
import TipoContato from "./enumeradores/TipoContato";
import TipoContaBancaria from "./enumeradores/TipoContaBancaria";
import Parcelas from "./Parcelas";
import Frequencia from "./enumeradores/Frequencia";
import TipoSituacaoAssinatura from "./enumeradores/TipoSituacaoAssinatura";
import TipoSituacaoAssinaturaAntecipacao from "./enumeradores/TipoSituacaoAssinaturaAntecipacao";
import TipoSituacaoAssinaturaCobranca from "./enumeradores/TipoSituacaoAssinaturaCobranca";
import TipoCancelamentoCobranca from "./enumeradores/TipoCancelamentoCobranca";
import TipoStatusCancelamento from "./enumeradores/TipoStatusCancelamento";
import TipoSolicitacao from "./enumeradores/TipoSolicitacao";
import MCC from "./enumeradores/MCC";
import TipoStatusEsitef from "./enumeradores/TipoStatusEsitef";
import SituacaoAssinatura from "./enumeradores/SituacaoAssinatura";
import ProprietariosContaBancaria from "./enumeradores/ProprietariosContaBancaria";
import Token from "./enumeradores/Token";

export default {
    Frequencia,
    MCC,
    Situacao,
    TipoLinkPagamentoCobranca,
    TipoLinkPagamentoCliente,
    TipoLinkPagamento,
    Perfil,
    TipoContato,
    TipoContaBancaria,
    Parcelas,
    TipoSituacaoAssinatura,
    TipoSituacaoAssinaturaAntecipacao,
    TipoSituacaoAssinaturaCobranca,
    TipoSolicitacao,
    TipoCancelamentoCobranca,
    TipoStatusCancelamento,
    TipoStatusEsitef,
    SituacaoAssinatura,
    ProprietariosContaBancaria,
    Token,
    //Buscar enum pelo value
    getEnum(v) {
        let Enum = [
            ...Frequencia,
            ...MCC,
            ...Parcelas,
            ...Perfil,
            ...Situacao,
            ...SituacaoAssinatura,
            ...TipoContato,
            ...TipoContaBancaria,
            ...TipoLinkPagamento,
            ...TipoSituacaoAssinatura,
            ...TipoSituacaoAssinaturaAntecipacao,
            ...TipoSituacaoAssinaturaCobranca,
            ...TipoCancelamentoCobranca,
            ...TipoStatusCancelamento,
            ...TipoSolicitacao,
            ...TipoStatusEsitef,
            ...TipoLinkPagamentoCobranca,
            ...TipoLinkPagamentoCliente,
            ...ProprietariosContaBancaria,
            ...Token
        ];
        if (v == -1)
            return { value: -1, text: "Total" }
        return Enum.find(el => el.value == v);
    },
}