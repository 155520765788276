import i18n from "../../i18n/i18n";
export default [
    { value: 2, text: i18n.t('ENUM.PARCELAS.PARCELA_1') },
    { value: 2, text: i18n.t('ENUM.PARCELAS.PARCELA_2') },
    { value: 3, text: i18n.t('ENUM.PARCELAS.PARCELA_3') },
    { value: 4, text: i18n.t('ENUM.PARCELAS.PARCELA_4') },
    { value: 5, text: i18n.t('ENUM.PARCELAS.PARCELA_5') },
    { value: 6, text: i18n.t('ENUM.PARCELAS.PARCELA_6') },
    { value: 7, text: i18n.t('ENUM.PARCELAS.PARCELA_7') },
    { value: 8, text: i18n.t('ENUM.PARCELAS.PARCELA_8') },
    { value: 9, text: i18n.t('ENUM.PARCELAS.PARCELA_9') },
    { value: 10, text: i18n.t('ENUM.PARCELAS.PARCELA_10') },
    { value: 11, text: i18n.t('ENUM.PARCELAS.PARCELA_11') },
    { value: 12, text: i18n.t('ENUM.PARCELAS.PARCELA_12') },
]