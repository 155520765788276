import verificaAutenticacao from "@/common/middleware/verificaAutenticacao.js";
import verificarPermissao from "../common/middleware/verificarPermissao";

export default [
    {
        name: "produtos",
        path: "/produtos",
        meta: {
            middleware: verificaAutenticacao,
        },
        beforeEnter: (to, from, next) => {
            verificarPermissao(next, "Produto");
        },
        component: () => import("@/views/produtos"),
    },
    {
        name: "produtos-novo",
        path: "/produtos/novo",
        meta: {
            middleware: verificaAutenticacao,
        },
        beforeEnter: (to, from, next) => {
            verificarPermissao(next, "Produto");
        },
        component: () => import("@/views/produtos/formulario"),
    },
    {
        name: "produtos-editar",
        path: "/produtos/:id?",
        meta: {
            middleware: verificaAutenticacao,
        },
        beforeEnter: (to, from, next) => {
            verificarPermissao(next, "Produto");
        },
        props : true,
        component: () => import("@/views/produtos/formulario"),
    },
]