export default {
    TITULO_LISTA : "Produtos",
    TITULO_FORMULARIO_NOVO : "Novo produto",
    TITULO_FORMULARIO_EDICAO : "Editar produto",
    NOVO : "Novo produto",
    EDITAR : "Editar",
    INATIVAR : "Inativar",
    FILTRO : "Pesquise pelo nome do produto",
    TOOLTIP : "Selecione o estabelecimento que gostaria de víncular esse contato",
    TABELA : {
        CODIGO : " Código",
        PRODUTO : "Produto",
        VALOR : "Valor",
        SITUACAO : "Situação",
        ESTABELECIMENTO : "Estabelecimento",
    },


    INATIVAR_SUCESSO : "Produto(s) inativado(s) com sucesso.",
    ATIVAR_SUCESSO : "Produto(s) ativado(s) com sucesso.",
    TITULO_INATIVAR : "Inativar produto(s)",
    TITULO_ATIVAR : "Ativar produto(s)",
    CONFIRMACAO_INATIVAR : "Deseja inativar produto(s) selecionado(s)?",
    CONFIRMACAO_ATIVAR : "Deseja ativar produto(s) selecionado(s)?",
    
}