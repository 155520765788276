<template>
  <b-form-group :label="label" :label-for="id" :id="`${id}-root`">
    <template #label>
      <slot name="label">{{ label }}</slot>
    </template>
    <multiselect
      :id="id"
      v-model="valueLocal"
      :options="options"
      placeholder=""
      :selectLabel="!semOptionsLabels ? labelAdicionar : ''"
      :deselectLabel="!semOptionsLabels ? labelRemover : ''"
      :selectedLabel="!semOptionsLabels ? labelRemover : ''"
      label="text"
      track-by="value"
      :multiple="!unico"
      :taggable="!unico"
      @input="onInput"
      :disabled="disabled"
    >
      <template #option="data">
        <slot name="option" v-bind="data.option">{{ data.option.text }}</slot>
      </template>
      <template #singleLabel="data">
        <slot name="singleLabel" v-bind="data.option">{{ data.option.text }}</slot>
      </template>
    </multiselect>
  </b-form-group>
</template>
<script>
import Multiselect from "vue-multiselect";
export default {
  components: {
    Multiselect,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      required: true,
    },
    label: {
      type: String,
    },
    id: {
      type: String,
      default: () => {
        return `modal-${Math.random()}`;
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    unico: {
      type: Boolean,
      default: false,
    },
    labelAdicionar: {
      type: String,
      default: "Clique para adicionar",
    },
    labelRemover: {
      type: String,
      default: "Clique para remover",
    },
    labelSelecionado: {
      type: String,
      default: "Selecionado",
    },
    semOptionsLabels: {
      type: Boolean,
      default: false,
    },
    semFicarVazio: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    valueLocal: [],
  }),
  methods: {
    onInput: function (value) {
      if (this.semFicarVazio) {
        this.valueLocal = this.options.find((element) => element.value == this.value);
      }
      if (!value) return;
      if (this.unico) {
        this.$emit("input", value.value);
      } else {
        this.$emit(
          "input",
          value.map((el) => el.value)
        );
      }
    },
  },
  watch: {
    value: function (value) {
      if (this.unico) {
        this.valueLocal = this.options.find((element) => element.value == value);
      } else {
        let values = [];
        value.forEach((el) => {
          values.push(this.options.find((element) => element.value == el));
        });
        this.valueLocal = values;
      }
    },
    options: function () {
      if (this.unico) {
        this.valueLocal = this.options.find((element) => element.value == this.value);
      } else {
        let values = [];
        this.value.forEach((el) => {
          values.push(this.options.find((element) => element.value == el));
        });
        this.valueLocal = values;
      }
    },
  },
  mounted() {
    if (this.unico) {
      this.valueLocal = this.options.find((element) => element.value == this.value);
    } else {
      let values = [];
      this.value.forEach((el) => {
        values.push(this.options.find((element) => element.value == el));
      });
      this.valueLocal = values;
    }
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  min-height: 35px !important;
  padding-top: 6px !important;
}
.multiselect__tag {
  margin-bottom: 0px !important ;
}
.multiselect__option--highlight,
.multiselect__option--highlight:after {
  background: #fff;
  outline: none;
  color: #333;
}

.multiselect__option,
.multiselect__option:after {
  line-height: 0;
  font-size: 0.875rem;
  min-height: auto;
  top: inherit;
}

.multiselect {
  box-sizing: inherit;
}

.multiselect__tag {
  background: #f0f0f0;
  color: #333;
}

.multiselect__content-wrapper {
  width: calc(100%);
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: #f0f0f0;
}

.multiselect__option--selected {
  font-weight: inherit;
}
</style>
