export default {
    TITULO_LISTA: "Assinaturas",
    TITULO_FORMULARIO_NOVO: "Nova assinatura",
    TITULO_FORMULARIO_VISUALIZAR: "Visualizar assinatura",
    NOVO: "Nova assinatura",
    CONFIRMAR_CANCELAMENTO: "Confirmar cancelamento",
    REJEITAR_CANCELAMENTO: "Rejeitar cancelamento",
    EFETUAR_ANTECIPACAO: "Efetuar antecipação",
    EFETUAR_RECOMPRA: "Recomprar assinatura",
    FILTRO: "Pesquise pelo nome do cliente ou assinatura",
    TABELA: {
        NOME: "Assinatura",
        VALOR: "Valor parcela",
        VALOR_TOTAL: "Valor total",
        NOME_CLIENTE: "Nome do cliente",
        FREQUENCIA: "Frequência",
        QTD_COBRANCA: "Qtd de cobranças",
        PROXIMA_COBRANCA: "Próxima cobrança",
        SITUACAO: "Situação",
        VALOR_ANTECIPACAO: "Valor disp. antecipação",
        ANTECIPACAO: "Antecipação",
    },
    LABEL: {
        FILTRO: "Filtro",
        DINICIAL: "Data inicial",
        DFINAL: "Data final"
    },
    DESCRICAO: "Nova assinatura",
    FREQUENCIA: "Frequência",
    QUANTIDADE_COBRANCAS: "Qtd. de cobranças",
    VALOR: "Valor",
    SITUACAO: "Situação",
    DATA_INICIO: "Data de início",
    DATA_TERMINO: "Data de término",
    DETALHES_ASSINANTE: "Detalhes do assinante",
    NOME_ASSINANTE: "Nome do assinante",
    EMAIL_ASSINANTE: "Email do assinante",
    CARTAO_ASSINANTE: "Cartão do assinante",
    TITULO_SALVAR: "Deseja salvar a assinatura?",
    MENSAGEM_SUCESSO: "Assinatura salva com sucesso!",
    TITULO_SALVAR_CARTAO: "Deseja alterar o cartão da assinatura?",
    TITULO_RECOMPRAR: "Deseja alterar recomprar assinatura?",
    MENSAGEM_SUCESSO_CARTAO: "Foi enviado novo link para alteração dos dados do cartão!",
    MENSAGEM_SUCESSO_RECOMPRA: "Assinatura recomprada com sucesso!",
    PLANO: "Plano",
    ALTERAR_CARTAO: "Alterar cartão",
    CANCELAR_ASSINATURA: "Cancelar assinatura",
    ABA_DETALHES: "Detalhes",
    PRODUTOS: "Produtos do plano",
    MOTIVO_CANCELAMENTO: "Motivo do cancelamento",
    TITULO_MODAL: "Solicitar cancelamento da assinatura",
    BOTAO_SOLICITAR_CANCELAMENTO: "Solicitar cancelamento",
    TITULO_CONFIRMAR_PEDIDO_CANCELAMENTO: "Solicitar cancelamento de assinatura",
    SOLICITAR_CANCELAMENTO: "Cancelar cobrança",
    VALOR_PAGO: "Valor pago",
    VALOR_EM_ATRASO: "Valor em atraso",
    VALOR_A_RECEBER: "Valor à receber",
    CONFIRMAR_SALVAR_CARTAO: "Essa ação irá enviar um novo link para o cliente atualizar o cartão de crédito. Tem certeza que deseja executar esta ação?",
    COBRANCAS: {
        PARCELA: "Parcela",
        DATA_COBRANCA: "Data de cobrança",
        DATA_VENCIMENTO: "Data de vencimento",
        NSU: "NSU",
        COD_AUTORIZACAO: "Código de autorização",
        VALOR_TOTAL: "Valor total",
        ANTECIPACAO: "Antecipação",
        VALOR_PROCESSADO: "Taxa processamento",
        VALOR_ANTECIPADO: "Valor líquido",
        VALOR_DESAGIO: "Taxa de antecipação",
        VALOR_TARIFA: "Valor tarifa antecipação",
        VALOR_LIQUIDO: "Valor líq. antecipado",
        SITUACAO: "Situação"
    },
    ABA_HISTORICO_TRANSACAO: "Histórico de transações",
    ABA_HISTORICO_TROCA_CARTAO: "Histórico de trocas de cartões",
    HISTORICO: {
        PARCELA: "Parcela",
        DATA_COBRANCA: "Data de cobrança",
        DATA_VENCIMENTO: "Data de vencimento",
        NSU: "NSU",
        COD_AUTORIZACAO: "Código de autorização",
        VALOR_TOTAL: "Valor total",
        BANDEIRA: "Bandeira do cartão",
        NUMERO_CARTAO: "Numero do cartão",
        ADQUIRINTE: "Adquirente",
        MENSAGEM: "Mensagem",
        SITUACAO: "Situação"
    },
    ABA_PEDIDOS: "Solicitações de cancelamento",
    ABA_COBRANCAS: "Cobranças",
    SOLICITAR_ANTECIPACAO: "Antecipar cobranças",

    LIMITE_CREDITO_ANTECIPACAO: "Limite de crédito para antecipação",
    TOTAL_ASSINATURAS_SELECIONADAS_ANTECIPACAO: "Total selecionado para antecipação",
    TOTAL_DISPONIVEIS_ANTECIPACAO: "Assinaturas disponiveis para antecipação",
    VISUALIZAR_SOMENTE_DISPONIVEIS_ANTECIPACAO: "Visualizar disponiveis para antecipação",
    VALOR_EXCEDE_LIMITE_ANTECIPACAO: "O valor selecionado de assinaturas excede o limite máximo de antecipação.",
    ANTECIPAR_ASSINATURA: "Deseja antecipar assinatura(s)?",
    ANTECIPACAO: {
        TITULO_MODAL: "Selecione o Favorecido da Operação",
    },
    FILTRO_FAVORECIDO: "Buscar favorecido",
    ANTECIPADO_SUCESSO: "Antecipação solicitada com sucesso",
    ANTECIPADO_SUCESSO_AVISO: "Antecipação solicitada com sucesso",
    CODIGO: "Codigo da assinatura",
    CONSULTA_STATUS_ESITEF: "Consultar status (ESitef)",
    TITULO_CONSULTA_STATUS_ESITEF: "Deseja forçar a consulta de status no ESitef?",
    CONSULTA_AGENDAMENTO: "Obter Agendamento (ESitef)",
    TITULO_CONSULTA_AGENDAMENTO: "Deseja buscar o agendamento no ESitef?",
}