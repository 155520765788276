import verificaAutenticacao from "@/common/middleware/verificaAutenticacao.js";
import verificarPermissao from "../common/middleware/verificarPermissao";

export default [
    {
        name: "extrato-transacao",
        path: "/extrato_transacao",
        meta: {
            middleware: verificaAutenticacao,
        },
        beforeEnter: (to, from, next) => {
            verificarPermissao(next, "ExtratoTransacao");
        },
        component: () => import("@/views/extrato_transacao"),
    },
    {
        name: "extrato-transacao-pendentes",
        path: "/extrato_transacao/pendentes",
        meta: {
            middleware: verificaAutenticacao,
        },
        beforeEnter: (to, from, next) => {
            verificarPermissao(next, "ExtratoTransacao");
        },
        component: () => import("@/views/extrato_transacao"),
    },
    {
        name: "extrato-transacao-detalhes-avistaparcelado",
        path: "/extrato_transacao/:id?",
        meta: {
            middleware: verificaAutenticacao,
        },
        beforeEnter: (to, from, next) => {
            verificarPermissao(next, "ExtratoTransacao");
        },
        props : true,
        component: () => import("@/views/extrato_transacao/formulario"),
    },
    {
        name: "extrato-transacao-detalhes-recorrencia",
        path: "/extrato_transacao_cobranca/:id?",
        meta: {
            middleware: verificaAutenticacao,
        },
        beforeEnter: (to, from, next) => {
            verificarPermissao(next, "ExtratoTransacao");
        },
        props : true,
        component: () => import("@/views/extrato_transacao/formulario"),
    },
]