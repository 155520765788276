export default {
    TITULO_LISTA : "Grupos econômicos",
    TITULO_FORMULARIO_NOVO : "Novo grupo econômico",
    TITULO_FORMULARIO_EDICAO : "Editar grupo econômico",
    NOVO : "Novo grupo econômico",
    FILTRO : "Pesquise pelo nome do grupo",
    TABELA : {
        NOME : "Nome do grupo econômico",
    },
    MENSAGEM_SUCESSO : "Grupo econômico salvo com sucesso",

    TITULO_SALVAR : "Deseja salvar o grupo econômico?",

    INATIVAR_SUCESSO : "Grupo(s) econômico(s) inativado(s) com sucesso.",
    ATIVAR_SUCESSO : "Grupo(s) econômico(s) ativado(s) com sucesso.",
    TITULO_INATIVAR : "Inativar grupo(s) econômico(s)",
    TITULO_ATIVAR : "Ativar grupo(s) econômico(s)",
    CONFIRMACAO_INATIVAR : "Deseja inativar o(s) grupo(s) econômico(s) selecionado(s)?",
    CONFIRMACAO_ATIVAR : "Deseja ativar o(s) grupo(s) econômico(s) selecionado(s)?",
    BOTAO_INATIVAR : "Inativar",
    BOTAO_ATIVAR : "Ativar",
}