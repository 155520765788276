export default {
    NOVO_ESTABELECIMENTO: 'Novo estabelecimento',
    TITULO_LISTA: 'Estabelecimentos',
    TITULO_FORMULARIO_NOVO: "Novo estabelecimento",
    TITULO_FORMULARIO_EDICAO: "Editar estabelecimento",
    CPF_CNPJ: 'CPF ou CNPJ',
    RAZAO_SOCIAL: 'Razão social',
    NOME_FANTASIA: 'Nome fantasia',
    EMAIL: 'Email',
    MCC: 'MCC',
    SOFT_DESCRIPTOR: 'SoftDescriptor',
    LINK_RECORRENCIA: 'Link de recorrência',
    CEP: 'CEP',
    ENDERECO: 'Endereço',
    NUMERO: 'Número',
    COMPLEMENTO: 'Complemento',
    BAIRRO: 'Bairro',
    CIDADE: 'Cidade',
    ESTADO: 'UF',
    PAIS: 'País',
    REPRESENTANTE_COMERCIAL: "Representante comercial",
    MERCHANTID: "Código EC",
    PARCELA_MAXIMA: "Número máximo de parcelas",

    NOVO_CONTATO: 'Novo Contato',
    NOVO_CONTA: 'Nova conta',
    NOVO_USUARIO: 'Adicionar usuário',
    GERAR_TOKEN: "Gerar Token",
    NOVO_TOKEN: "Novo Token",
    NOVO_TOKEN_GERADO: "Seu novo Token foi gerado com sucesso!",

    TIPO_CONTATO: 'Tipo de contato',

    DATA_INCLUSAO: 'Data de inclusão',
    STATUS: 'Status',
    LIBERAR_ANTECIPACAO: 'Liberar antecipação de recorrência',
    LIMITE_DISPONIVEL: 'Limite de crédito disponível',
    DATA_ANTECIPACACAO: 'Data de início da antecipação',
    FATURAMENTO_MENSAL: 'Faturamento mensal',
    PERCENTUAL_ANTECIPACADO: 'Percentual de antecipação',
    LIMITE_MAX: 'Limite máximo inicial',
    VALIDADE_INICIAL: 'Validade do limite inicial (em dias)',
    TAXA_CREDITO: 'Taxa de crédito à vista',
    LIMITE_INADIMPLECIA: 'Limite máximo de inadimplência',

    INATIVAR_SUCESSO: "Estabelecimento(s) inativado(s) com sucesso.",
    ATIVAR_SUCESSO: "Estabelecimento(s) ativado(s) com sucesso.",
    TITULO_INATIVAR: "Inativar estabelecimento(s)",
    TITULO_ATIVAR: "Ativar estabelecimento(s)",
    CONFIRMACAO_INATIVAR: "Deseja inativar estabelecimento(s) selecionado(s)?",
    CONFIRMACAO_ATIVAR: "Deseja ativar estabelecimento(s) selecionado(s)?",
    BOTAO_INATIVAR: "Inativar",
    BOTAO_ATIVAR: "Ativar",
    TITULO_SALVAR: "Salvar dados do estabelecimento",
    MENSAGEM_SUCESSO: "Estabelecimento salvo com sucesso",
    CONFIRMAR_GERAR_NOVO_TOKEN_TITULO:
        "Tem certeza que gostaria de gerar um novo token?",
    CONFIRMAR_GERAR_NOVO_TOKEN_MENSAGEM:
        "Esta ação irá invalidar seu antigo token.",

    CONTATO: {
        TITULO_MODAL_NOVO: "Novo contato",
        TITULO_MODAL_EDITAR: "Editar contato",
        NOME: "Nome",
        EMAIL: "E-mail",
        CELULAR: "Telefone/Celular",
        TIPO: "Tipo",

        INATIVAR_SUCESSO: "Contato(s) inativada(s) com sucesso.",
        ATIVAR_SUCESSO: "Contato(s) ativado(s) com sucesso.",
        TITULO_INATIVAR: "Inativar contato(s)",
        TITULO_ATIVAR: "Ativar contato(s)",
        CONFIRMACAO_INATIVAR: "Deseja inativar contato(s) selecionada(s)?",
        CONFIRMACAO_ATIVAR: "Deseja ativar contato(s) selecionada(s)?",
        BOTAO_INATIVAR: "Inativar",
        BOTAO_ATIVAR: "Ativar",

        ADICIONADO: "Contato salvo com sucesso!"
    },

    CONTA_BANCARIA: {
        TITULO_MODAL_NOVO: "Nova conta bancária",
        TITULO_MODAL_EDITAR: "Editar conta bancária",
        CPF_CNPJ: 'CPF ou CNPJ',
        FAVORECIDO: 'Favorecido',
        BANCO: 'Banco',
        TIPO_CONTA: 'Tipo de conta',
        AGENCIA: 'Agência',
        DIGITO: 'DV',
        CONTA: 'Conta',
        CONVENIO: 'Id Externo',
        DONO_CONTA: 'Proprietário da conta',
        CODIGO_QPROF: 'Código Qprof',

        INATIVAR_SUCESSO: "Conta(s) bancária(s) inativada(s) com sucesso.",
        ATIVAR_SUCESSO: "Conta(s) bancária(s) ativado(s) com sucesso.",
        TITULO_INATIVAR: "Inativar conta(s) bancária(s)",
        TITULO_ATIVAR: "Ativar conta(s) bancária(s)",
        CONFIRMACAO_INATIVAR: "Deseja inativar conta(s) bancária(s) selecionada(s)?",
        CONFIRMACAO_ATIVAR: "Deseja ativar conta(s) bancária(s) selecionada(s)?",
        BOTAO_INATIVAR: "Inativar",
        BOTAO_ATIVAR: "Ativar",
        ADICIONADO: "Conta bancária salva com sucesso!",
        DIGITOVERIFICADOR: "DV",
        TOKEN: "Token"
    },
    USUARIOS: {
        FILTRO: "Filtrar pelo nome",
        ADICIONADO: "Usuário(s) adicionado(s) com sucesso!"
    },
    GRUPO_ECONOMICO: "Grupo econômico",
    NOVO_GRUPO: "Novo grupo",
    SOFT_DESCRIPTOR_DESCRICAO: "É a descrição do serviço, mensagem ou nome do site que aparecerá na transação da fatura do portador do cartão de crédito.",


    LIMITE_CREDITO_MAXIMO: "Limite de crédito máximo",
    LIMITE_CREDITO_ATIVO: "Limite de crédito ativo",
    LIMITE_CREDITO_UTILIZADO: "Limite de crédito utilizado",
    LIMITE_CREDITO_DISPONIVEL: "Limite de crédito disponível",

    MERCHANT_ID: "MerchantId"
}