export default {
    TITULO_LISTA : "Usuários",
    TITULO_FORMULARIO_NOVO : "Novo usuário",
    TITULO_FORMULARIO_EDICAO : "Editar usuário",
    TITULO_DADOS : "Dados usuário",
    TITULO_PERFIL : "Perfil*",
    TITULO_ACESSO : "Dados de acesso",
    ALTERAR_SENHA : "Alterar senha",
    NOVO : "Novo usuário",
    EDITAR : "Editar",
    INATIVAR : "Inativar",
    ATIVAR : "Ativar",
    FILTRO : "Pesquise pelo nome, email, CPF ou telefone",
    TABELA : {
        NOME : "Nome",
        CPF : "CPF",
        EMAIL : "Email",
        CONTATO : "Telefone",
        CONTATO_CELULAR : "Telefone/Celular",
        SITUACAO : "Situação",
        ESTABELECIMENTO : "Estabelecimento",
        ACOES : "Ações",
        EMPRESAS : "Empresas",
        USUARIO  : "Usuário",
        SUPERVISOR : "Supervisor / Gestor",
        SENHA : "Senha",
        CONFIRMACAO_SENHA : "Confirmação senha",
        PERFIL  :"Perfil"
    },
    MENSAGEM_SUCESSO : "Usuário salvo com sucesso",
    MENSAGEM_SUCESSO_SENHA : "Senha alterada com sucesso",
    TITULO_MINHA_CONTA : "Minha conta",
    MENSAGEM_SENHA : "As senhas não são iguais",
    TOOLTIP : {
        SUPERVISOR : "Selecione o supervisor / gestor que deseja vincular à esse contato"
    },
    TITULO_SALVAR_USUARIO : "Salvar dados do usuário",
    TITULO_SALVAR_PERFIL : "Salvar dados do perfil",
    TITULO_SENHA : "Alterar senha do usuário",
    INATIVAR_SUCESSO : "Usuário(s) inativado(s) com sucesso.",
    ATIVAR_SUCESSO : "Usuário(s) ativado(s) com sucesso.",
    TITULO_INATIVAR : "Inativar usuário(s)",
    TITULO_ATIVAR : "Ativar usuário(s)",
    CONFIRMACAO_INATIVAR : "Deseja inativar usuário(s) selecionado(s)?",
    CONFIRMACAO_ATIVAR : "Deseja ativar usuário(s) selecionado(s)?",
    BOTAO_INATIVAR : "Inativar",
    BOTAO_ATIVAR : "Ativar",
    CANCELAMENTO_AUTOMATICO : "Cancelamento automático de cobrança"
}