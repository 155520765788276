<template>
  <div>
    <b-form-checkbox
      :id="id"
      v-model="valueCheckbox"
      :name="id"
      :key="id"
      v-on:change="onChange()"
      :disabled="checkDisabled"
      :switch="typeSwitch"
      :button="button">
      {{ textCheckbox }}
    </b-form-checkbox>
  </div>
</template>

<script>
export default {
  name: 'InputCheckbox',
  props: {
    text: { type: [String, Number], default: '' },
    value: { type: Boolean },
    disabled: { type: Boolean },
    id: { type: String },
    switch: { type: Boolean },
    button : {type: Boolean}
  },
  data() {
    return {
      textCheckbox: this.text,
      valueCheckbox: this.value,
      checkDisabled: this.disabled,
      typeSwitch: this.switch
    }
  },

  methods: {
    onChange() {
      this.$emit('onChange', this.valueCheckbox)
      ;(this.valueCheckbox = this.value),
        (this.checkDisabled = this.disabled),
        (this.typeSwitch = this.switch)
    }
  },
  watch: {
    value() {
      this.valueCheckbox = this.value
    },
    disabled() {
      this.checkDisabled = this.disabled
    },
    valueCheckbox() {
      this.$emit('input', this.valueCheckbox)
    },
    switch() {
      this.typeSwitch = this.switch
    }
  }
}
</script>

<style>
.custom-control-input:focus ~ .custom-control-label::before {
  border-color: var(--primary) !important;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0) !important;
}

.custom-control-input:checked ~ .custom-control-label::before,
.custom-control-input:active ~ .custom-control-label::before {
  border-color: var(--primary) !important;
  background-color: var(--primary) !important;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: var(--primary) !important;
}
</style>
