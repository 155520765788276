export default {
    TITULO_LISTA: "Planos",
    TITULO_FORMULARIO_NOVO: "Novo plano",
    TITULO_FORMULARIO_EDICAO: "Editar plano",
    NOVO: "Novo plano",
    EDITAR: "Editar",
    INATIVAR: "Inativar",
    ATIVAR: "Ativar",
    FILTRO: "Pesquise pelo código, razão social ou CNPJ",
    TOOLTIP: "Selecione o estabelecimento que gostaria de víncular esse plano",

    NOME: 'Nome',
    VAL_PLANO: 'Valor plano',
    VAL_TAXA: 'Valor taxa',
    VAL_PRODUTOS: 'Valor produtos',
    VAL_TOTAL: 'Valor total',
    NUM_COBRANCAS: 'Qtd. de cobranças',
    STATUS: 'Status',
    CARENCIA_VALOR: "Valor Carência",

    NOME_PLANO: "Nome do plano",
    VALOR_PLANO: "Valor do plano",
    NUMERO_COBRANCA: "Qtd. de cobranças",
    FREQUENCIA: 'Frequência',
    ESTABELECIMENTO: "Estabelecimento",
    APLICAR_CARENCIA: 'Aplicar uma carência no plano',
    CARENCIA: 'Carência',
    APLICAR_TAXA: 'Aplicar uma taxa extra',
    DESCRICAO_TAXA: 'Descrição',
    VALOR_TAXA: "Valor da taxa",
    PARCELAS: 'Parcelas',
    INCLUIR_PRODUTOS: 'Incluir produtos no plano',
    NOME_PRODUTO: 'Nome do produto',
    QUANTIDADE_PRODUTO: 'Quantidade',
    VALOR_PRODUTO: 'Valor',
    ADICIONAR_SACOLA: "Adicionar",
    MESES_CARENCIA: "Meses de carência",
    VALOR_CARENCIA: "Valor da primeira parcela",
    TABELA_PRODUTO: {
        PRODUTO: "Produto",
        VALOR: "Valor",
        QUANTIDADE: "Quantidade",
        BOTAO_REMOVER: "Remover"
    },


    MENSAGEM_SUCESSO: "Plano salvo com sucesso",

    INATIVAR_SUCESSO: "Plano(s) inativado(s) com sucesso.",
    ATIVAR_SUCESSO: "Plano(s) ativado(s) com sucesso.",
    TITULO_INATIVAR: "Inativar plano(s)",
    TITULO_ATIVAR: "Ativar plano(s)",
    CONFIRMACAO_INATIVAR: "Deseja inativar plano(s) selecionado(s)?",
    CONFIRMACAO_ATIVAR: "Deseja ativar plano(s) selecionado(s)?",
    BOTAO_INATIVAR: "Inativar",
    BOTAO_ATIVAR: "Ativar",

    TITULO_SALVAR: "Salvar plano",

    PRODUTOS_OBRIGATORIO: "O plano deve possuir produtos!",
    VALOR_TOTAL: "Valor total"
}