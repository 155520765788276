import verificaAutenticacao from "@/common/middleware/verificaAutenticacao.js";
import verificarPermissao from "../common/middleware/verificarPermissao";

export default [
  {
    name: 'assinaturas',
    path: '/assinaturas',
    meta: {
        middleware: verificaAutenticacao,
    },
    beforeEnter: (to, from, next) => {
        verificarPermissao(next, "Assinatura");
    },
    component: () => import('@/views/assinaturas/Index')
  },
  {
    name: 'assinaturas-novo',
    path: '/assinaturas/novo',
    meta: {
        middleware: verificaAutenticacao,
    },
    beforeEnter: (to, from, next) => {
        verificarPermissao(next, "Assinatura");
    },
    component: () => import('@/views/assinaturas/Formulario')
  },
  {
    name: 'assinaturas-editar',
    path: '/assinaturas/:id?',
    meta: {
        middleware: verificaAutenticacao,
    },
    beforeEnter: (to, from, next) => {
        verificarPermissao(next, "Assinatura");
    },
    props: true,
    component: () => import('@/views/assinaturas/Formulario')
  }
]
