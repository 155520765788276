const USUARIO_TOKEN = "usuario_token";
const buscarToken = () => window.localStorage.getItem(USUARIO_TOKEN);
const salvarToken = (token) => window.localStorage.setItem(USUARIO_TOKEN, token);
const excluirToken = () => window.localStorage.removeItem(USUARIO_TOKEN);

const USUARIO_NOME = "usuario_nome";
const buscarNome = () => window.localStorage.getItem(USUARIO_NOME);
const salvarNome = (nome) => {
  window.localStorage.setItem(USUARIO_NOME, nome);
};
const excluirNome = () => window.localStorage.removeItem(USUARIO_NOME);

const USUARIO_SOBRENOME = "usuario_sobrenome";
const buscarSobrenome = () => window.localStorage.getItem(USUARIO_SOBRENOME);
const salvarSobrenome = (sobrenome) => window.localStorage.setItem(USUARIO_SOBRENOME, sobrenome);
const excluirSobrenome = () => window.localStorage.removeItem(USUARIO_SOBRENOME);

const USUARIO_EMAIL = "usuario_email";
const buscarEmail = () => window.localStorage.getItem(USUARIO_EMAIL);
const salvarEmail = (email) => window.localStorage.setItem(USUARIO_EMAIL, email);
const excluirEmail = () => window.localStorage.removeItem(USUARIO_EMAIL);

const USUARIO_ID = "usuario_id";
const buscarId = () => window.localStorage.getItem(USUARIO_ID);
const salvarId = (id) => window.localStorage.setItem(USUARIO_ID, id);
const excluirId = () => window.localStorage.removeItem(USUARIO_ID);

const USUARIO_TEMPO = "usuario_tempo";
const buscarTempo = () => window.localStorage.getItem(USUARIO_TEMPO);
const salvarTempo = (tempo) => window.localStorage.setItem(USUARIO_TEMPO, tempo);
const excluirTempo = () => window.localStorage.removeItem(USUARIO_TEMPO);

const USUARIO_PRIMEIRO_LOGIN = "usuario_primeiro_login";
const buscarPrimeiroLogin = () => window.localStorage.getItem(USUARIO_PRIMEIRO_LOGIN);
const salvarPrimeiroLogin = (priemiroLogin) => window.localStorage.setItem(USUARIO_PRIMEIRO_LOGIN, priemiroLogin);
const excluirPrimeiroLogin = () => window.localStorage.removeItem(USUARIO_PRIMEIRO_LOGIN);



const parseJwt = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));



  return JSON.parse(jsonPayload);
};

export default {
  buscarToken,
  salvarToken,
  excluirToken,
  parseJwt,
  buscarNome,
  salvarNome,
  excluirNome,
  buscarEmail,
  salvarEmail,
  excluirEmail,
  buscarSobrenome,
  salvarSobrenome,
  excluirSobrenome,
  buscarId,
  salvarId,
  excluirId,
  buscarTempo,
  salvarTempo,
  excluirTempo,
  buscarPrimeiroLogin,
  salvarPrimeiroLogin,
  excluirPrimeiroLogin
};
